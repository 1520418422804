import axios from 'axios'

export async function getEntrancesByUser(token, startDate, endDate, setState) {
  
    const data = await axios.get(`${process.env.REACT_APP_API_LOCAL}/entrance/user?startDate=${startDate}&finalDate=${endDate}`, {
      headers: {
        'x-api-key': process.env.REACT_APP_XAPIKEY,
        Authorization: 'Bearer ' + token,
      }
    })
    .then((res) => {
        setState(res.data.entrances)
        return res.data
    })
    .catch(err => {
        console.error('getEntranceByUser:', err.message)
        throw new Error("Erro ao listar entradas");
    })

    return data
}

export async function getEntranceOrganization(token, organizationId, startDate, endDate, setState, resume, page) {
  
  const data = await axios.get(`${process.env.REACT_APP_API_LOCAL}/entrance/organization/${organizationId}?startDate=${startDate}&finalDate=${endDate}&page=${page}`, {
    headers: {
      'x-api-key': process.env.REACT_APP_XAPIKEY,
      Authorization: 'Bearer ' + token,
    }
  })
  .then((res) => {
      setState(res.data.entrances)
      resume(res.data.resume)
      return res.data
  })
  .catch(err => {
      console.error('getCalendarOrganization:', err.message)
      throw new Error("Erro ao listar entradas");
  })

  return data
}