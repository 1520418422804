import { destroyCookie } from 'nookies'

export function Logout() {
    window.localStorage.setItem("cmenu", false)
    window.localStorage.setItem("plan", false)
    destroyCookie(null, 'token')
    window.location.href = '/'
}

export function redirectLogin() {
    window.location.href = '/'
    window.localStorage.setItem("cmenu", false)
    destroyCookie(null, 'token')
}

export async function setUser(user) {
    console.log('userrr: ', user)
    return user
}
