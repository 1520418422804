export const StepsCart = [
    {
        title: 'Adicione um procedimento',
        content: 'Escolha e adicione um ou mais procedimentos ao carrinho',
        target: '#btn-plus',
        placement: 'top-end',
    },
    {
        title: 'Taxas de Cartão de crédito',
        content: 'Adicione taxas de cartão para fazer um cálculo da venda mais preciso.',
        target: '#tax-sales',
        placement: 'bottom-end',
        stepIndex: 1
    },
    {
        title: 'Carrinho',
        content: (
            <div>
                <p>
                    Aqui é exibido a quantidade de produtos no carrinho, 
                    ao clicar ira abrir uma aba lateral com o resumo da venda.
                    <br/>
                    <br/>
                    Com o carrinho aberto, basta verificar se os procedimentos adicionados estão corretos
                    escolher um método de pagamento e finalizar a venda.
                    <br/>
                    <br/>
                    <b>Caso seja necessário</b> é possível incluir descontos e taxa de máquina de cartão
                </p>
            </div>
            
        ),
        target: '#cart-sales',
        placement: 'bottom-end',
        stepIndex: 2
    },
]

export const StepsCalendar = [
    {
        title: 'Adicione ou Atualize um agendamento',
        content: (
            <div>
                <p>
                    1º - Escolha os dias da semana que você irá atender<br/>
                    2º - Quantidade de meses que irá durar seu atendimento(1 mês, 2 meses ou 12 meses). Isso fará que sua agenda fique disponível pelo tempo escolhido<br/>
                    3º - Escolha a hora inicial e final que irá realizar os atendimentos<br/>
                    4º - Escolha o horário inicial e final do seu período de almoço<br/>
                    5º - Depois de finalizado irá aparecer um resumo com os dados do seu agendamento<br/>
                    6º - <b>Link para suas clientes: </b> <p style={{ color: '#2D1674' }}> Aqui é o link onde você irá disponibilizar para suas clientes. Você pode mandar diretamente para cada uma delas ou colocar
                    no <b>link da BIO do seu instagram</b>, por exemplo</p><br/>
                </p>
            </div>
            
        ),
        target: '#add-scheduling',
        placement: 'bottom-end',
    },
    {
        title: 'Bloqueie ou desbloqueie horários.',
        content: 'Escolha a opção de bloquear ou desbloquear horários. É possível bloquear ou desbloquear mais de um horário por vez ',
        target: '#config-scheduling',
        placement: 'bottom-end',
        stepIndex: 1
    },
    {
        title: 'Agendamento',
         content: (
            <div>
                <p>
                    Aqui será exibido sua agenda completa, informando os dados da cliente, dia e horario<br/><br/>
                    Além disso, se deseja excluir um agendamento, basta clicar em cima de um horário agendado que será possível deletar de sua agenda
                </p>
            </div>
            
        ),
        target: '.fc-list-empty',
        placement: 'top-end',
        stepIndex: 2
    },
]

export const StepsMenu = [
    {
        title: 'Cadastre seus procedimentos',
        content: (
            <div>
                <p>
                    Registre seus procedimentos inserindo o nome, valor, descrição e a duração de cada um deles. <br/>
                    Cada um desses procedimentos ficaram disponíveis na hora de registrar uma venda e na hora do agendamento para seus clientes.
                </p>
            </div>
            
        ),
        target: '#register',
        placement: 'bottom-end',
    },
    {
        title: 'Registre as vendas que você efetuou',
        content: (
            <div>
                <p>
                    Registre as vendas adicionando os procedimentos que foram vendidos e finalize a venda clicando no icone de carrinho
                </p>
            </div>
            
        ),
        target: '#cart',
        placement: 'bottom-end',
        stepIndex: 1
    },
    {
        title: 'Visualize todas as vendas',
         content: (
            <div>
                <p>
                    Veja a quantidade de vendas e total. Filtre por data para ver as vendas pelo período esolhido. <br />
                    Se for necessário, exclua uma venda feita por engano ou cancelada.
                </p>
            </div>
            
        ),
        target: '#sales',
        placement: 'bottom-end',
        stepIndex: 3
    },
    {
        title: 'Controle seu agendamento',
         content: (
            <div>
                <p>
                    Gerencie todos os seus agendamentos, registre os dias e horários de atendimento <br />
                    Exclua agendamentos cancelados, bloqueie/desbloqueie horários, disponibilize o link da sua agenda para seus clientes
                </p>
            </div>
            
        ),
        target: '#scheduling',
        placement: 'bottom-end',
        stepIndex: 4
    },
    {
        title: 'Obtenha análises financeiros',
         content: (
            <div>
                <p>
                    Veja tudo que entrou e saiu, detalhes das vendas por mês, procedimentos mais vendidos. <br />
                    Registre suas despesas no menu "Saída" para que você tenha um controle maior das finanças da sua empresa
                </p>
            </div>
            
        ),
        target: '#analysis',
        placement: 'bottom-end',
        stepIndex: 5
    },
]

export const StepsMenuPlanBasic = [
    {
        title: 'Cadastre seus procedimentos',
        content: (
            <div>
                <p>
                    Registre seus procedimentos inserindo o nome, valor, descrição e a duração de cada um deles. <br/>
                    Cada um desses procedimentos ficaram disponíveis na hora de registrar uma venda e na hora do agendamento para seus clientes.
                </p>
            </div>
            
        ),
        target: '#register',
        placement: 'bottom-end',
    },
    {
        title: 'Registre as vendas que você efetuou',
        content: (
            <div>
                <p>
                    Registre as vendas adicionando os procedimentos que foram vendidos e finalize a venda clicando no icone de carrinho
                </p>
            </div>
            
        ),
        target: '#cart',
        placement: 'bottom-end',
        stepIndex: 1
    },
    {
        title: 'Visualize todas as vendas',
         content: (
            <div>
                <p>
                    Veja a quantidade de vendas e total. Filtre por data para ver as vendas pelo período escolhido. <br />
                    Se for necessário, exclua uma venda feita por engano ou cancelada.
                </p>
            </div>
            
        ),
        target: '#sales',
        placement: 'bottom-end',
        stepIndex: 3
    },
    {
        title: 'Obtenha análises financeiros',
         content: (
            <div>
                <p>
                    Veja tudo que entrou e saiu, detalhes das vendas por mês, procedimentos mais vendidos. <br />
                    Registre suas despesas no menu "Saída" para que você tenha um controle maior das finanças da sua empresa
                </p>
            </div>
            
        ),
        target: '#analysis',
        placement: 'bottom-end',
        stepIndex: 5
    },
]