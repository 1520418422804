import './profile.css';
import React, { useEffect, useState } from 'react';
import { parseCookies } from 'nookies'
import toast, { Toaster } from 'react-hot-toast';
import Loader  from '../../Components/Loader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faDollarSign, faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope, faUser, faBuilding, faGem } from '@fortawesome/free-regular-svg-icons';

import { getProfile, updateProfile } from '../../Controllers/profile'


function Profile() {
    const [loading, setLoading] = useState(false)
    const [profile, setProfile] = useState(false)
    const [editOn, setEditOn] = useState(false)
    const [valueCompany, setValueCompany] = useState(false)
    const [company, setCompany] = useState('')


    const cookies = parseCookies()
    const token = cookies.token


    async function getProfiles() {
      const planId = window.localStorage.getItem('plan')
      const userId = window.localStorage.getItem('id')
      await getProfile(token, userId, planId)
      .then((res) => {
        setProfile(res)
        setLoading(false);
      })
      .catch((err) => {
        console.log('erro retornar organização', err)
      })
    }

    const editData = (value) => {
      setValueCompany(value)
      setEditOn(!editOn)
    }

    const update = async() => {
      const userId = window.localStorage.getItem('id')
      const body = {
        "companyData": [
          {
            "companyName": company
          }
        ]
      }

      await updateProfile(token, userId, body)
      .then(() => {
        toast.success('Atualização feita com sucesso')
        setEditOn(!editOn)
        getProfiles()
      })
      .catch(( ) => {
        toast.error('Erro ao atualizar nome da empresa ')
      })
    }

    useEffect(() => {
      setLoading(true)
      setTimeout(() => { getProfiles(); }, 5000)
    },[])

  return (
    <div className="container-plan">
      <Toaster position="bottom-center" />
      {loading ? (
        <div className='container-loading'>
          <Loader />
        </div>
      ):(
        <>
          <div className='card-profile'>
            <div className='container-image-profile'>
              <div className='image-profile'>
                {profile?.profile?.name.substring(0,1)} 
              </div>
            </div>
            <div className='container-content-profile'>
              <div className='container-icons-ilustration'>
                <span className='icons-ilustration'><FontAwesomeIcon icon={faUser}/></span>
                <span>{profile?.profile?.name}</span>
              </div>
              <div className='container-icons-ilustration'>
                <span className='icons-ilustration'><FontAwesomeIcon icon={faEnvelope} /></span>
                <span>{profile?.profile?.email}</span>
              </div>
              <div className='container-edit-company-name'>
                <div className='container-icons-ilustration'>
                  <span className='icons-ilustration'><FontAwesomeIcon icon={faBuilding} /></span>
                  {editOn ? (
                    <input 
                      className='input-style-edit-profile'
                      onChange={(e) => setCompany(e.target.value)}
                      defaultValue={valueCompany} 
                    />
                  ):(
                    <span>{profile?.profile?.company}</span>
                  )}
                </div>
                <div className='container-icon-edit'>
                  {editOn ? (
                    <div className='container-icons-edit'>
                      <FontAwesomeIcon 
                        className='style-icon-check-profile' 
                        onClick={update} icon={faCheck} />
                      <FontAwesomeIcon
                        className='style-icon-cancel-profile ' 
                        onClick={() => editData(profile?.profile?.company)} icon={faXmark} />
                    </div>
                  ):(
                    <FontAwesomeIcon onClick={() => editData(profile?.profile?.company)} icon={faPen} />
                  )}
                </div>
              </div>
            </div>
            <hr />
            <div className='container-content-profile'>
              <div className='container-icons-ilustration'>
                <span className='icons-ilustration'><FontAwesomeIcon icon={faGem} /></span>
                <span>{profile?.plan?.name}</span>
              </div>
              <div className='container-icons-ilustration'>
                <span className='icons-ilustration'><FontAwesomeIcon icon={faDollarSign} /></span>
                <span>{(profile?.plan?.value / 100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }</span>
              </div>
            </div>
          </div>
        </>
      )}
      
    </div>
  );
}

export default Profile;