import React, {useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { Logout } from '../../Functions';
import './userMenu.css'


function UserMenu() {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const nameUser = window.localStorage.getItem('name')

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
      const handleClose = () => {
        setAnchorEl(null);
      };

    const goToPlan = () => {
        window.location.href = '/plan'
    }

    const goToProfile = () => {
        window.location.href = '/profile'
    }

    const goToPersonalize = () => {
        window.location.href = '/personalize'
    }

  
    return (
        <>
            <div className=''>
                <IconButton 
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <Avatar 
                        className='configure-avatar'
                        sx={{ bgcolor: '#C9E265', width: 30, height: 30, fontSize: '15px', color: '#2D1674'}}
                    >
                        {nameUser.substring(0,1)}   
                    </Avatar>
                </IconButton>
               
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                >
                    <MenuItem onClick={goToProfile} disabled={false}>Perfil</MenuItem>
                    <MenuItem onClick={goToPersonalize} disabled={false}>Personalização</MenuItem>
                    <MenuItem onClick={goToPlan} disabled={false}>Gerenciar plano</MenuItem>
                    <Divider sx={{ my: 0.5 }} />
                    <MenuItem onClick={Logout} sx={{fontSize:'14px'}}>
                        <span ><FontAwesomeIcon icon={faPowerOff} /></span>
                        <span className='text-exit'>SAIR</span>
                    </MenuItem>
                </Menu>
            </div>
      </>
    );
  }
  
  export default UserMenu;