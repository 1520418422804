import './personalize.css';
import React, { useEffect, useState } from 'react';
import { parseCookies } from 'nookies'
import toast, { Toaster } from 'react-hot-toast';
import Loader  from '../../Components/Loader'
import { getPersonalize, createPersonalize, updatePersonalize } from '../../Controllers/personalize'


function Personalize() {
  const [loading, setLoading] = useState(false)
  const [customizer, setCustomizer] = useState([])
  const [mainColor, setMainColor] = useState(customizer?.main || '#000000')
  const [titleNameColor, setTitleNameColor] = useState('')
  const [durationColor, setDurationColor] = useState('')
  const [buttonColor, setButtonColor] = useState('')
  
  const organizationId = window.localStorage.getItem('organization')
  const cookies = parseCookies()
  const token = cookies.token

  const handleChange = (setState) => (event) => {
    setState(event.target.value)
  }

  async function personalize() {
    await getPersonalize(token, organizationId)
    .then((res) => {
      setCustomizer(res.customize[0] ?? '')
      setMainColor(res.customize[0].main ?? '#2d1674')
      setTitleNameColor(res.customize[0].title ?? '#ffffff')
      setDurationColor(res.customize[0].duration ?? '#2d1674')
      setButtonColor(res.customize[0].button ?? '#794cff')
    })
  }

  useEffect(() => {
    personalize()
  },[])

  async function create() {
    const data = {
      main: mainColor,
      title: titleNameColor,
      duration: durationColor,
      button: buttonColor,
      organizationId
    }
    console.log('passei no create: ', data)
    await createPersonalize(token, data)
    .then(() => {
      toast.success('Customização feita com sucesso')
    })
    .catch(() => {
      toast.error('Erro ao finalizar customização')
    })

  }

  async function update() {
    const data = {
      main: mainColor,
      title: titleNameColor,
      duration: durationColor,
      button: buttonColor,
      organizationId
    }
    const id = customizer._id

    await updatePersonalize(token, data, id)
    .then(() => {
      toast.success('Customização atualizada com sucesso')
    })
    .catch(() => {
      toast.error('Erro ao finalizar customização')
    })

  }
 
  useEffect(() => {
    // setLoading(true)
  },[])

  return (
    <div className="container-personalize">
      <Toaster position="bottom-center" />
      {loading ? (
        <div className='container-loading'>
          <Loader />
        </div>
      ):(
        <>
          <div className='container-title-personalize'>
            <h3>Personalização</h3>
            <p>Deixe sua agenda com a cara da sua marca💜. Escolha as cores que mais combine</p>
          </div>
          <div className='card-personalize'>
            <p>Escolha a cor principal </p>
            <span>
              <input 
                onChange={handleChange(setMainColor)} 
                value={mainColor}
                type='color' 
              />
            </span>
          </div>
          <div className='card-personalize'>
            <p>Escolha a cor do nome do seu salão </p>
            <span>
              <input 
                onChange={handleChange(setTitleNameColor)} 
                type='color' 
                value={titleNameColor}
              />
            </span>
          </div>
          <div className='card-personalize'>
            <p>Escolha a cor da duração de cada procedimento </p>
            <span>
              <input 
                onChange={handleChange(setDurationColor)} 
                type='color'
                value={durationColor} 
              />
            </span>
          </div>
          <div className='card-personalize'>
            <p>Escolha a cor dos botões</p>
            <span>
              <input 
                onChange={handleChange(setButtonColor)} 
                type='color' 
                value={buttonColor}
              />
            </span>
          </div>
          <div className='container-title-personalize'>
            <button onClick={customizer.length > 0 ? update : create} className='button-style-personalize'>{customizer.length > 0 ? 'Atualizar' : 'Salvar'}</button>
          </div>
        </>
      )}
      
    </div>
  );
}

export default Personalize;