import React, {useEffect, useState} from 'react'
import './productsAnalisys.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import { parseCookies } from 'nookies'
import { getSalesMonthOrganization } from '../../Controllers/sale'
import Loader  from '../../Components/Loader'
import { redirectLogin } from '../../Functions';
import moment from 'moment'
import { sum } from 'mathjs'

export function convertDateToISOFormat(date, hour) {
	const time = date + ' ' + hour;
	return new Date(time.replace(/\s/g, 'T') + 'Z').toISOString();
}

function SalesAnalysis() {
    const cookies = parseCookies()
    const token = cookies.token
    const [sales, setSales] = useState()
    const [loading, setLoading] = useState(true)
    const year = moment().format('YYYY')
    const organizationId = window.localStorage.getItem('organization')

    const array = sales?.filter((s) => s.totalSales)
    const totalYear = sum(array?.map((a) => a.totalSales))
    async function getSales() {
        await getSalesMonthOrganization(token, organizationId, year, setSales)
          .then((res) => {
            setLoading(false)
          })
          .catch((err) => {
            console.log('erro ao se registrar', err)
          })
    }

    async function getSalesFilter(data) {
        setLoading(true)
        await getSalesMonthOrganization(token, organizationId, data, setSales)
          .then((res) => {
            setLoading(false)
          })
          .catch((err) => {
            console.log('erro ao se registrar', err)
          })
    }

    useEffect(() => {
        if(token === undefined) {
            redirectLogin()
          }
          getSales()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

  return (
    <div className='container-register'>
        <div className='content-register'>
            <div className='titles'>
                <p>Vendas por mês</p>
            </div>

            <div className='container-inputs-analisys '>
                <select 
                    className='input-style-entrance'
                    onChange={(e) => getSalesFilter(e.target.value)}
                    defaultValue={2024}
                >
                    <option value={2024}>2024</option>
                    <option value={2023}>2023</option>
                </select>
            </div>

            {loading ? (
                <>
                    <div className='container-loading'>
                        <Loader />
                    </div>
                </>
            ) :(
                <>
                    {sales.length <= 0 ? (
                        <div className='container-not-sales'>
                            <p className='text-not-sales'>Não existe nenhuma venda registrada.</p>
                        </div>
                    ):(
                        <div className='table-container-analysis'>
                            <Table striped>
                                <thead>
                                    <tr>
                                        <th>Mês</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sales?.map((sale) => {
                                        return(
                                            <>
                                                <tr>
                                                    <td>
                                                        {moment(sale._id.month, 'M').format('MMMM')}
                                                    </td>
                                                    <td>
                                                        {sale.totalSales.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    })}
                                      <tr>
                                        <td><b>Total Ano:</b></td>
                                        <td><b>{totalYear?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    )}

                </>
            )}
           
        </div>
    </div>
  );
}

export default SalesAnalysis;