import React, {useState, useEffect} from 'react';
import './header.css'
import { getUser, getUserOrganization } from '../../Controllers/external'

function Header(props) {

    return (
      <div className="container-header">
        <p>{props.user?.companyData[0]?.companyName}</p>
      </div>
    );
  }
  
  export default Header;