/* eslint-disable jsx-a11y/alt-text */
import React, {useState, useEffect} from 'react';
import './payment.css';
import toast, { Toaster } from 'react-hot-toast';
import { Input, Radio, Select  } from 'antd';
import LogoIcone from '../../Assets/logo-icone.png'
import moment from 'moment'
import { createSubscription, getPlanId } from '../../Controllers/payment'
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import Spinner from 'react-bootstrap/Spinner';
import InputMask from 'react-input-mask';

function Payment() {
    const [typePerson, setTypePerson] = useState('fisica');
    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [cpf, setCpf] = useState()
    const [ddd, setDDD] = useState()
    const [cellPhone, setCellPhone] = useState()
    const [street, setStreet] = useState()
    const [streetNumber, setStreetNumber] = useState()
    const [cep, setCEP] = useState()
    const [complement, setComplement] = useState()
    const [neighborhood, setNeighborhood] = useState()
    const [city, setCity] = useState()
    const [uf, setUF] = useState()
    const [cardName, setCardName] = useState()
    const [cardNumber, setCardNumber] = useState()
    const [cardMonth, setCardMonth] = useState()
    const [cardYear, setCardYear] = useState()
    const [cardCVV, setCardCVV] = useState()
    const [congratulations, setCongratulations] = useState(false)
    const [plan, setPlan] = useState([])
    const [loader, setLoader] = useState(false)
    const [loaderButton, setLoaderButton] = useState(false)
    const [animationSuccess, setAnimationSuccess] = useState(false)
    // const [receipt, setReceipt] = useState(false)

    const receipt = window.localStorage.getItem('randomNumber')
    function generateRandomNumber() {
      if(!receipt) {
        const randomNumber = Math.floor(1000000000 + Math.random() * 9000000000).toString();
        localStorage.setItem('randomNumber', randomNumber);
        console.log('passei aqui')
        return randomNumber;
      }
    }

    function formatarValorParaMoeda(valor) {
      if (valor) {
        return (valor / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
      }
      return '';
    }

    const firstPayDayDate = moment().add(30, 'days')
    console.log('first: ', firstPayDayDate.format('YYYY-MM-DD'))
    const planId  = window.location.search.split("=")[1].split('&')[0]
    async function getPlan() {
      setLoader(true)
      await getPlanId(planId, 0, 10, setPlan)
      .then(() => {
        setLoader(false)
      })
      .catch(() => {
        console.log('erro ao carregar plano')
      })
    }

    useEffect(() => {
      window.localStorage.setItem("cmenu", false)
      generateRandomNumber()
      getPlan()
   },[])


  const data = {
    planMyId: planId,
    firstPayDayDate: firstPayDayDate.format('YYYY-MM-DD'),
    mainPaymentMethodId: "creditcard",
    Customer: {
      name: name,
      document: cpf,
      emails: [email],
      phones: [ddd + cellPhone],
      Address: {
        zipCode: String(cep),
        street: String(street),
        number: String(streetNumber),
        complement: String(complement),
        neighborhood: String(neighborhood),
        city: String(city),
        state: String(uf)
      }
    },
    PaymentMethodCreditCard: {
      Card: {
        number: String(cardNumber),
        holder: String(cardName),
        expiresAt: String(cardYear) + '-' + String(cardMonth),
        cvv: String(cardCVV)
      }
    }
  }

  const finishPayment = async() => {
    setLoaderButton(true)
    if(!name || !email || !cpf || !ddd || !cellPhone || 
      !street || !streetNumber || !cep || 
      !neighborhood || !city || !uf || !cardName || !cardMonth
      || !cardNumber || !cardYear || !cardCVV) { 
        toast.error('Preencha todos os campos obrigatórios')
        setLoaderButton(false)
      } else {
        const value = formatarValorParaMoeda(plan?.PlanPrices[0]?.value ?? null)
        await createSubscription(data, typePerson, plan.galaxPayId, receipt, value)
        .then(() => {
          setLoaderButton(false)
          setCongratulations(true)
          setAnimationSuccess(true)
          toast.success('Parabéns!!🎉🥳. Sua assinatura foi finalizada com sucesso. ')
          setTimeout(() => { setAnimationSuccess(false) }, 5000)
          setTimeout(() => { localStorage.removeItem('randomNumber') }, 20000)
        })
        .catch((err) => {
          if(err.response.status === 409){
            toast.error('E-mail ou CPF ja possuem cadastro!')
            setLoaderButton(false)
          } else {
            toast.error('Ops! Algo deu errado na hora de concluir sua assinatura 😔. Verifique se todos os dados estão corretos')
            setLoaderButton(false)
          }
        })
      }
  }


const handleChange = (setState) => (event) => {
  const value = event.target ? event.target.value : event;
  setState(value);
}

  const onChangeTypePerson = (e) => {
    setTypePerson(e.target.value);
  };

  return (
    <div className="container-payment">
      <Toaster position="bottom-center" />
      <div className='header-payment'>
          <img className="img" src={LogoIcone} />
      </div>
      {loader ? (
        <div className='container-loader'>
          <Player
            autoplay
            loop
            src="https://assets1.lottiefiles.com/packages/lf20_iS3ojumBx1.json"
            style={{ height: '200px', width: '200px' }}
          >
            <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
          </Player>
        </div>
      ):(
        <>
          {congratulations === false ? (
            <>
              <div className='container-secure-pay'>
                <p className='title-secure-pay'>Pagamento Seguro</p>
                <img 
                  src='https://cdn-icons-png.flaticon.com/512/3190/3190472.png' 
                  style={{ marginLeft: '10px' }} 
                  width='30px' 
                />
              </div>
              <div className='content-payment'>
                <div className='content-person'>
                  <div className='container-inputs-content'>
                  <p className='titles-payment'>Dados Pessoais</p>
                  <div className='container-type-person'>
                  <p className='text-type-person'>Tipo de pessoa*: </p>
                    <Radio.Group value={typePerson} onChange={onChangeTypePerson}>
                        <Radio value='fisica'><p className='content-type-person'>Fisíca</p></Radio>
                        <Radio value='juridica'><p className='content-type-person'>Juridica</p></Radio>
                    </Radio.Group>
                  </div>
                  <InputMask 
                    className='inputs-content' 
                    placeholder={typePerson === 'fisica' ? 'Nome Completo*' : 'Razão Social*'} 
                    onChange={handleChange(setName)}  
                    required
                  />
                  </div>
                  <div className='container-inputs-content'>
                    <input 
                        className='inputs-content' 
                        placeholder='E-mail*' 
                        onChange={handleChange(setEmail)} 
                        required
                    />
                  </div>

                  <div className='container-inputs-content'> 
                    <InputMask 
                        mask={typePerson === 'fisica' ? "999.999.999-99" : "99.999.999/9999-99"}
                        maskChar={null}
                        className='inputs-content' 
                        placeholder={typePerson === 'fisica' ? 'CPF*' : 'CNPJ*'}
                        onChange={handleChange(setCpf)}
                    />
                  </div>
            

                  <div className='container-phones'>
                    <div>
                      <Input 
                          className='input-ddd' 
                          placeholder='DDD*'
                          onChange={handleChange(setDDD)}
                      />
                    </div>

                    <div className='container-inputs-content'>
                      <InputMask 
                          maskChar={null}
                          mask="99999-9999"
                          className='inputs-content'
                          placeholder='Celular*'
                          onChange={handleChange(setCellPhone)} 
                      />
                    </div>
                  </div>

                  <div className='container-inputs-content'>
                    <input 
                        className='inputs-content' 
                        placeholder='Nome da rua*' 
                        onChange={handleChange(setStreet)}
                    />
                  </div>

                  <div className='container-phones'>
                    <div>
                      <Input 
                          className='input-ddd'
                          type='number'
                          placeholder='Número*'
                          onChange={handleChange(setStreetNumber)} 
                      />
                    </div>

                    <div>
                      <InputMask
                          maskChar={null}
                          mask="99999-999"
                          className='inputs-content' 
                          placeholder='CEP*'
                          onChange={handleChange(setCEP)} 
                      />
                    </div>
                  </div>

                  <div className='container-inputs-content'>
                    <input 
                        className='inputs-content' 
                        placeholder='Complemento'
                        onChange={handleChange(setComplement)}  
                    />
                  </div>

                  <div className='container-inputs-content'>
                    <input 
                      className='inputs-content' 
                      placeholder='Bairro*' 
                      onChange={handleChange(setNeighborhood)}
                    />
                  </div>

                  <div className='container-city'>
                    <div>
                      <Input 
                          className='input-city' 
                          placeholder='Cidade*'
                          onChange={handleChange(setCity)}
                      />
                    </div>

                    <div>
                      <Input 
                          className='inputs-content ' 
                          placeholder='UF*'
                          onChange={handleChange(setUF)}
                      />
                    </div>
                  </div>

                </div>

                <div className='content-payment-data'>
                  
                  <div>
                    <p className='title-payment-data'>Pagamento</p>
                    <div className='container-resume'>
                      <p><b>Plano:</b> {plan?.name}</p>
                      <p><b>Valor total:</b> {
                        // Object.keys(plan).length !== 0? (plan?.PlanPrices[0]?.value / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ''
                        "Grátis por 30 dias"
                      }</p>
                      {/* <p className='text-info-value'>
                        Após o período de teste sera cobrado o valor de: 
                        <b> {(plan?.PlanPrices[0]?.value / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</b>
                      </p> */}
                    </div>
                    <div className='container-radio-button'>
                      <Radio style={{ color: 'white', fontWeight: '600'}} checked={true}>Cartão de Crédito</Radio>
                    </div>
                    <Input 
                      style={{ border: '1px solid white'}}
                      className='inputs-payment-data' 
                      placeholder='Nome como no cartão *' 
                      onChange={handleChange(setCardName)}
                    />
                  </div>
                  
                  <div>
                    <InputMask
                        maskChar={null}
                        mask="9999 9999 9999 9999" 
                        style={{ border: '1px solid white'}}
                        className='inputs-payment-data' 
                        placeholder='Número do cartão*' 
                        onChange={handleChange(setCardNumber)}    
                    />
                  </div>

                  <div className='container-expiration-date'>
                    <div>
                    <Select
                      className='select-payment-data'
                      defaultValue="" 
                      onChange={handleChange(setCardMonth)}
                      options={[
                        { value: "", label: 'Mês' },
                        { value: "01", label: '01' },
                        { value: "02", label: '02' },
                        { value: "03", label: '03' },
                        { value: "04", label: '04' },
                        { value: "05", label: '05' },
                        { value: "06", label: '06' },
                        { value: "07", label: '07' },
                        { value: "08", label: '08' },
                        { value: "09", label: '09' },
                        { value: "10", label: '10' },
                        { value: "11", label: '11' },
                        { value: "12", label: '12' },
                        
                      ]}
                    />
                      {/* <Input 
                          style={{ border: '1px solid white'}}
                          className='inputs-payment-data-cvv' 
                          type='number'
                          placeholder='Mês*' 
                          onChange={handleChange(setCardMonth)}  
                        /> */}
                    </div>

                    <div>
                    <Select
                      className='select-payment-data'
                      defaultValue="" 
                      onChange={handleChange(setCardYear)}
                      options={[
                        { value: "", label: 'Ano' },
                        { value: "2024", label: '2024' },
                        { value: "2025", label: '2025' },
                        { value: "2026", label: '2026' },
                        { value: "2027", label: '2027' },
                        { value: "2028", label: '2028' },
                        { value: "2029", label: '2029' },
                        { value: "2030", label: '2030' },
                        { value: "2031", label: '2031' },
                        { value: "2032", label: '2032' },
                        { value: "2033", label: '2033' },
                        { value: "2034", label: '2034' },
                        { value: "2035", label: '2035' },
                        { value: "2036", label: '2036' },
                        { value: "2037", label: '2037' },
                        { value: "2038", label: '2038' },
                        { value: "2039", label: '2039' },
                        { value: "2040", label: '2040' },
                        { value: "2041", label: '2041' },
                        { value: "2042", label: '2042' },
                        { value: "2043", label: '2043' },
                        { value: "2044", label: '2044' },
                        { value: "2045", label: '2045' },
                        { value: "2046", label: '2046' },
                        { value: "2047", label: '2047' },
                        { value: "2048", label: '2048' },
                      ]}
                    />
                      {/* <Input 
                          style={{ border: '1px solid white'}}
                          className='inputs-payment-data-cvv' 
                          type='number'
                          placeholder='Ano*' 
                          onChange={handleChange(setCardYear)}
                        /> */}
                    </div>

                    <div>
                      <Input 
                          style={{ border: '1px solid white'}}
                          className='inputs-payment-data-cvv' 
                          type='number'
                          placeholder='CVV*' 
                          onChange={handleChange(setCardCVV)}
                      />
                    </div>
                  </div>
                  <div className='payment-button-container'>
                    <button className='payment-button' onClick={finishPayment}>
                      {loaderButton ? (
                        <>
                          <div className='container-loader-butto'>
                            <Spinner size='sm' />
                          </div>
                        </>
                      ):(
                        <>
                          Finalizar
                          {/* {`Pagar ${
                            Object.keys(plan).length !== 0 ? (plan?.PlanPrices[0]?.value / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ''
                          }`} */}
                        </>
                      )}
                    </button>
                  </div>

                </div>
              </div>
              <div className='container-data-enterprise'>
                <span className='text-developed-payment'>Desenvolvido por</span>
                <span className='text-name-company'>Gerenciei</span>
              </div> 
            </>
          ):(
            <>
              {animationSuccess ? (
                <>
                  <div className='container-loader'>
                    <Player
                      autoplay
                      loop
                      src="https://assets2.lottiefiles.com/packages/lf20_vuliyhde.json"
                      style={{ height: '200px', width: '200px' }}
                    >
                      <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
                    </Player>
                  </div>
                </>
              ):(
                <div className='container-finish-payment '> 
                  <p className='title-finish-payment'>Parabéns pela sua assinatura! 🎉</p>
                  <p>
                    Agora você faz parte da nossa incrível plataforma que vai <br />  descomplicar a 
                    maneira como você gerencia seu negócio 🌟
                  </p>
                  <br />
                  <p>
                    Aqui está o recibo do seu pedido nº {receipt}, que em breve chegará <br />  
                    ao seu e-mail para garantir que tudo esteja em perfeita ordem. <br />
                    Fique tranquilo(a), o pagamento só será processado após o término do seu <br />
                    período de teste gratuito, em <span className='payment-date'>{firstPayDayDate.format("D [de] MMMM [de] YYYY")}</span>, no valor de 
                    <span className='payment-date'> {(plan?.PlanPrices[0]?.value / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>. 
                    <br />
                    <br />
                    Até lá, aproveite ao máximo todos os benefícios que temos a oferecer! 💰💼<br />

                    No seu <b>e-mail</b> também irá chegar uma <b>senha temporaria</b> para que  <br /> você tenha  <b>acesso a nossa plataforma.</b> 
                    <br />
                    Aproveite ao máximo essa experiência
                    <br />
                    <br />
                    Equipe Gerenciei 💜
                  </p>
                </div>
              )}
            
            </>
          )}
        </>
      )}
 

 
    </div>
  );
}

export default Payment;
