import React, { useEffect, useState } from 'react';
import './menu.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faBars, 
    faPlus, 
    faCirclePlus, 
    faSackDollar,
    faBagShopping, 
    faChartSimple, 
    faMinus,
    faChartPie,
    faChartLine,
    faCalendarDays,
    faMoneyBillTrendUp,
    faDiagramProject
} from '@fortawesome/free-solid-svg-icons';
import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar } from 'react-pro-sidebar';
import { useOrganizationData } from '../../Hook/hook'

function MenuBar() {
    const { toggleSidebar, broken }  = useProSidebar();
    const companyName = window.localStorage.getItem('companyName')
    const organizationId = window.localStorage.getItem('organization')
    const organization = useOrganizationData(organizationId)

    const verifyPlan = {
        financas: process.env.REACT_APP_PLAN_FINANCE,
        controleTotal: process.env.REACT_APP_PLAN_CONTROL,
        business: process.env.REACT_APP_PLAN_BUSINESS
    }

    if(organization){
        window.localStorage.setItem("plan", organization?.plan?.id)
    } else {
        window.localStorage.setItem("plan", false)
    }

    const goToRegister = () => {
        window.location.href = '/register'
    }


    return (
      <>
        <div  className='container-menu'>
            <Sidebar backgroundColor='#2D1674' customBreakPoint="800px" style={{ height: '800px' }}  >
                <div className='container-logo' onClick={() => window.location.href = '/home'}>
                    {companyName}   
                </div>
                <Menu  
                    style={{ 
                        background: '#2D1674', 
                    }}
                >
                    <MenuItem 
                        className='container-menu-items' 
                        id='register'
                        style={{ 
                            color: 'white', 
                            backgroundColor: '#2D1674' 
                        }} 
                        onClick={goToRegister}
                    > 
                        <span className='style-icons'><FontAwesomeIcon icon={faCirclePlus} /></span>
                        <span>Cadastro de Procedimento</span> 
                    </MenuItem>
                    
                    <MenuItem 
                        className='container-menu-items'
                        id='cart'
                        style={{ 
                            color: 'white', 
                            backgroundColor: '#2D1674' 
                        }} 
                        onClick={() => window.location.href = '/cart'}
                    > 
                        <span className='style-icons'><FontAwesomeIcon icon={faBagShopping} /></span>
                        <span>Realizar Venda</span> 
                    </MenuItem>
                    <MenuItem 
                        className='container-menu-items' 
                        id='sales'
                        style={{ 
                            color: 'white', 
                            backgroundColor: '#2D1674' 
                        }} 
                        onClick={() => window.location.href = '/sales'}
                    > 
                        <span className='style-icons'><FontAwesomeIcon icon={faSackDollar} /></span>
                        <span>Vendas</span> 
                    </MenuItem>
                    {organization?.plan?.id !== verifyPlan.financas && (
                        <MenuItem 
                            className='container-menu-items' 
                            id='scheduling'
                            style={{ 
                                color: 'white', 
                                backgroundColor: '#2D1674' 
                            }} 
                            onClick={() => window.location.href = '/calendar'}
                        > 
                            <span className='style-icons'><FontAwesomeIcon icon={faCalendarDays} /></span>
                            <span>Agenda</span> 
                        </MenuItem>
                    )}
                   
                    <SubMenu 
                        icon={<FontAwesomeIcon icon={faChartLine} />}
                        id='analysis'
                        style={{ marginLeft: '-3.5%', color: 'white', backgroundColor: '#2D1674'}} 
                        label="Análises"
                    >
                        <MenuItem 
                            className='container-menu-items' 
                            style={{ 
                                color: 'white', 
                                backgroundColor: '#2D1674' 
                            }} 
                            onClick={() => window.location.href = '/entrance'}
                        > 
                            <span className='style-icons'><FontAwesomeIcon icon={faPlus} /></span>
                            <span>Entrada </span> 
                        </MenuItem>

                        <MenuItem 
                            className='container-menu-items' 
                            style={{ 
                                color: 'white', 
                                backgroundColor: '#2D1674' 
                            }} 
                            onClick={() => window.location.href = '/exits'}
                        > 
                            <span className='style-icons'><FontAwesomeIcon icon={faMinus} /></span>
                            <span>Saída</span> 
                        </MenuItem>

                        <MenuItem 
                            className='container-menu-items' 
                            style={{ 
                                color: 'white', 
                                backgroundColor: '#2D1674' 
                            }} 
                            onClick={() => window.location.href = '/details'}
                        > 
                            <span className='style-icons'><FontAwesomeIcon icon={faChartSimple} /></span>
                            <span>Detalhes</span> 
                        </MenuItem>

                        <MenuItem 
                            className='container-menu-items' 
                            style={{ 
                                color: 'white', 
                                backgroundColor: '#2D1674' 
                            }} 
                            onClick={() => window.location.href = '/analysis'}
                        > 
                            <span className='style-icons'><FontAwesomeIcon icon={faChartPie} /></span>
                            <span>Mais vendidos</span> 
                        </MenuItem>

                        <MenuItem 
                            className='container-menu-items' 
                            style={{ 
                                color: 'white', 
                                backgroundColor: '#2D1674' 
                            }} 
                            onClick={() => window.location.href = '/analysis-month'}
                        > 
                            <span className='style-icons'><FontAwesomeIcon icon={faMoneyBillTrendUp} /></span>
                            <span>Vendas (Mês)</span> 
                        </MenuItem>
                        
                    </SubMenu>

                    <SubMenu
                        icon={<FontAwesomeIcon icon={faChartLine} />}
                        id='gestao'
                        style={{ marginLeft: '-3.5%', color: 'white', backgroundColor: '#2D1674'}} 
                        label="Gestão"
                    >
                        <div>
                            {organization?.plan?.id !== verifyPlan.financas && (
                                <>
                                    <MenuItem 
                                        className='container-menu-items' 
                                        style={{ 
                                            color: '#7c5ed6',
                                            fontSize: '10px', 
                                            backgroundColor: '#2D1674',
                                            fontWeight: 'bold' 
                                        }} 
                                        // onClick={goToControllers}
                                    > 
                                        <span>Planejar</span>  
                                        <hr style={{ margin: '10px 0px' }} /> 
                                    </MenuItem>
                                    {/* ------------------------------------------------------------------ */}
                                   

                                    <MenuItem 
                                        className='container-menu-items' 
                                        style={{ 
                                            color: 'white', 
                                            backgroundColor: '#2D1674' 
                                        }} 
                                        onClick={() => window.location.href = '/marketing'}
                                    > 
                                        <span className='style-icons'><FontAwesomeIcon icon={faDiagramProject} /></span>
                                        <span>Marketing</span> 
                                    </MenuItem>

                                    <MenuItem 
                                        className='container-menu-items' 
                                        style={{ 
                                            color: '#7e7f80', 
                                            backgroundColor: '#2D1674',
                                            fontWeight: 'bold' 
                                        }} 
                                        // onClick={goToControllers}
                                    > 
                                        <span className='style-icons'><FontAwesomeIcon icon={faDiagramProject} /></span>
                                        <span>Gestão</span> 
                                        <span
                                            style={{ 
                                                color: 'white',
                                                fontSize: '10px', 
                                                backgroundColor: '#4cb582',
                                                padding: ' 1px 5px 2px 5px',
                                                fontWeight: 'bold',
                                                borderRadius: '10px',
                                                marginLeft: '5%'
                                            }}
                                        >
                                            Em Breve
                                        </span>
                                    </MenuItem>
                                    {/*-------------------------------------------------------------- */}
                                    {/* <MenuItem 
                                        className='container-menu-items' 
                                        style={{ 
                                            color: '#7c5ed6',
                                            fontSize: '10px', 
                                            backgroundColor: '#2D1674',
                                            fontWeight: 'bold' 
                                        }} 
                                        // onClick={goToControllers}
                                    > 
                                        <span>Escalar</span>  
                                        <hr style={{ margin: '10px 0px' }} /> 
                                    </MenuItem>
                                    
                                    <MenuItem 
                                        className='container-menu-items' 
                                        style={{ 
                                            color: '#7e7f80', 
                                            backgroundColor: '#2D1674',
                                            fontWeight: 'bold' 
                                        }} 
                                        // onClick={goToControllers}
                                    > 
                                        <span className='style-icons'><FontAwesomeIcon icon={faDiagramProject} /></span>
                                        <span>Gestão</span> 
                                        <span
                                            style={{ 
                                                color: 'white',
                                                fontSize: '10px', 
                                                backgroundColor: '#4cb582',
                                                padding: ' 1px 5px 2px 5px',
                                                fontWeight: 'bold',
                                                borderRadius: '10px',
                                                marginLeft: '5%'
                                            }}
                                        >
                                            Em Breve
                                        </span>
                                    </MenuItem>

                                    <MenuItem 
                                        className='container-menu-items' 
                                        style={{ 
                                            color: '#7e7f80', 
                                            backgroundColor: '#2D1674',
                                            fontWeight: 'bold' 
                                        }} 
                                        // onClick={goToControllers}
                                    > 
                                        <span className='style-icons'><FontAwesomeIcon icon={faDiagramProject} /></span>
                                        <span>Marketing</span> 
                                        <span
                                            style={{ 
                                                color: 'white',
                                                fontSize: '10px', 
                                                backgroundColor: '#4cb582',
                                                padding: ' 1px 5px 2px 5px',
                                                fontWeight: 'bold',
                                                borderRadius: '10px',
                                                marginLeft: '5%'
                                            }}
                                        >
                                            Em Breve
                                        </span>
                                    </MenuItem>

                                    <MenuItem 
                                        className='container-menu-items' 
                                        style={{ 
                                            color: '#7e7f80', 
                                            backgroundColor: '#2D1674',
                                            fontWeight: 'bold' 
                                        }} 
                                        // onClick={goToControllers}
                                    > 
                                        <span className='style-icons'><FontAwesomeIcon icon={faDiagramProject} /></span>
                                        <span>Marketing</span> 
                                        <span
                                            style={{ 
                                                color: 'white',
                                                fontSize: '10px', 
                                                backgroundColor: '#4cb582',
                                                padding: ' 1px 5px 2px 5px',
                                                fontWeight: 'bold',
                                                borderRadius: '10px',
                                                marginLeft: '5%'
                                            }}
                                        >
                                            Em Breve
                                        </span>
                                    </MenuItem>

                                    <MenuItem 
                                        className='container-menu-items' 
                                        style={{ 
                                            color: '#7e7f80', 
                                            backgroundColor: '#2D1674',
                                            fontWeight: 'bold' 
                                        }} 
                                        // onClick={goToControllers}
                                    > 
                                        <span className='style-icons'><FontAwesomeIcon icon={faDiagramProject} /></span>
                                        <span>Marketing</span> 
                                        <span
                                            style={{ 
                                                color: 'white',
                                                fontSize: '10px', 
                                                backgroundColor: '#4cb582',
                                                padding: ' 1px 5px 2px 5px',
                                                fontWeight: 'bold',
                                                borderRadius: '10px',
                                                marginLeft: '5%'
                                            }}
                                        >
                                            Em Breve
                                        </span>
                                    </MenuItem> */}
                                    {/*-------------------------------------------------------------- */}

                                </>
                            )}

                        </div>
                    </SubMenu>
                </Menu>
            </Sidebar>
        </div>
        <div className='container-hamburguer'>
            {broken && (
                <FontAwesomeIcon onClick={() => toggleSidebar()} icon={faBars} style={{ color: '#2D1674' }} />
            )}
        </div>
      </>
    );
  }
  
  export default MenuBar;