import React from 'react';
import './buttonMore.css'

function ButtonMore({ onClick, title }) {

  
    return (
      <div className="container-button-more" onClick={onClick}>
        <button className='button-load-more'>{title}</button>
      </div>
    );
  }
  
  export default ButtonMore;