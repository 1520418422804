import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import Joyride from 'react-joyride';
import { StepsMenu, StepsMenuPlanBasic } from '../../Steps'
import './tutorial.css'



function Tutorial() {
    const [playTutorial, setPlayTutorial] = useState(false)
    const [step, setStep] = useState(0)
    const [isZero, setIsZero] = useState(true)
    const planController = window.localStorage.getItem('plan')
    console.log('planController: ', planController)

        const nextStep = () => {
            if(planController === 'pay-391673aee12417.57579927') {
                if(isZero) {
                    setIsZero(false)
                } else {
                    setStep(step + 1)
                }
                if(step === 3){ 
                    setPlayTutorial(false) 
                    setStep(0)
                    setIsZero(true)
                }
            } else {
                if(isZero) {
                    setIsZero(false)
                } else {
                    setStep(step + 1)
                }
                if(step === 4){ 
                    setPlayTutorial(false) 
                    setStep(0)
                    setIsZero(true)
                }
            }
        }
  
  
    return (
        <div className='container-tutorial'>
                <span onClick={nextStep}>
                    <Joyride
                        steps={planController === 'pay-391673aee12417.57579927' ? StepsMenuPlanBasic  : StepsMenu}
                        continuous
                        run={playTutorial}
                        stepIndex={step}
                        showProgress
                        locale={{next: 'Próximo', back: '', last: 'Final'}}
                        debug={true}
                    />
                </span>
            <div 
                className={playTutorial ? 'container-tutorial-content-play' : 'container-tutorial-content-pause'} 
                onClick={() => setPlayTutorial(!playTutorial)}
            >
                <div className={playTutorial ? 'box-pause' : 'box-play'}>
                    <FontAwesomeIcon icon={playTutorial ? faPause : faPlay} />
                </div>
                <div>
                    <span className='title-tutorial '>Tutorial</span>
                </div>
            </div>
        </div>
    );
  }
  
  export default Tutorial;