import axios from 'axios'

export async function createFee(body, token) {
  
    const data = await axios.post(`${process.env.REACT_APP_API_LOCAL}/fee`, body, {
      headers: {
        'x-api-key': process.env.REACT_APP_XAPIKEY,
        Authorization: 'Bearer ' + token,
        'content-Type': 'application/json'
      }
    })
    .catch(err => {
        console.error('createFee:', err.message)
        throw new Error("Erro ao criar Taxa");
    })

    return data
}

export async function getFeeByUser(token, setState) {
  
    const data = await axios.get(`${process.env.REACT_APP_API_LOCAL}/fee/user`, {
      headers: {
        'x-api-key': process.env.REACT_APP_XAPIKEY,
        Authorization: 'Bearer ' + token,
      }
    })
    .then((res) => {
        setState(res.data.fees)
        return res.data
    })
    .catch(err => {
        console.error('getFeeByUser:', err.message)
        throw new Error("Erro ao listar Taxas");
    })

    return data
}

export async function getFeeOrganization(token, organizationId, setState) {
  
  const data = await axios.get(`${process.env.REACT_APP_API_LOCAL}/fee/organization/${organizationId}`, {
    headers: {
      'x-api-key': process.env.REACT_APP_XAPIKEY,
      Authorization: 'Bearer ' + token,
    }
  })
  .then((res) => {
      setState(res.data.fees)
      return res.data
  })
  .catch(err => {
      console.error('getFeeOrganization:', err.message)
      throw new Error("Erro ao listar Taxas");
  })

  return data
}

export async function deleteFee(token, feeId) {
  
    const data = await axios.delete(`${process.env.REACT_APP_API_LOCAL}/fee/${feeId}`, {
      headers: {
        'x-api-key': process.env.REACT_APP_XAPIKEY,
        Authorization: 'Bearer ' + token,
        'content-Type': 'application/json'
      }
    })
    .catch(err => {
        console.error('deleteFee:', err.message)
        throw new Error("Erro ao editar saída");
    })

    return data
}

export async function updateFee(token, feeId, body) {
  
    const data = await axios.put(`${process.env.REACT_APP_API_LOCAL}/fee/${feeId}`, body, {
      headers: {
        'x-api-key': process.env.REACT_APP_XAPIKEY,
        Authorization: 'Bearer ' + token,
        'content-Type': 'application/json'
      }
    })
    .catch(err => {
        console.error('updateFee:', err.message)
        throw new Error("Erro ao atualizar Fee");
    })

    return data
}