import axios from 'axios'

export async function createSale(body, token) {
  
    const data = await axios.post(`${process.env.REACT_APP_API_LOCAL}/sale`, body, {
      headers: {
        'x-api-key': process.env.REACT_APP_XAPIKEY,
        Authorization: 'Bearer ' + token,
        'content-Type': 'application/json'
      }
    })
    .catch(err => {
        console.error('createSale:', err.message)
        throw new Error("Erro ao criar venda");
    })

    return data
}