import axios from 'axios'

export async function getSalesByUser(token, startDate, endDate, setState) {
  
    const data = await axios.get(`${process.env.REACT_APP_API_LOCAL}/sale/user?startDate=${startDate}&finalDate=${endDate}`, {
      headers: {
        'x-api-key': process.env.REACT_APP_XAPIKEY,
        Authorization: 'Bearer ' + token,
      }
    })
    .then((res) => {
        setState(res.data.sales)
        return res.data
    })
    .catch(err => {
        console.error('getSalesByUser:', err.message)
        throw new Error("Erro ao listar vendas");
    })

    return data
}

export async function getSalesByOrganization(token, organizationId, startDate, endDate, setState, resume, page) {
  
  const data = await axios.get(`${process.env.REACT_APP_API_LOCAL}/sale/organization/${organizationId}?startDate=${startDate}&finalDate=${endDate}&page=${page}`, {
    headers: {
      'x-api-key': process.env.REACT_APP_XAPIKEY,
      Authorization: 'Bearer ' + token,
    }
  })
  .then((res) => {
      setState(res.data.sales)
      resume(res.data.resume)
      return res.data
  })
  .catch(err => {
      console.error('getSalesByUser:', err.message)
      throw new Error("Erro ao listar vendas");
  })

  return data
}

export async function getSalesQuantity(token, startDate, endDate, setState) {
  
  const data = await axios.get(`${process.env.REACT_APP_API_LOCAL}/sale/user/products?startDate=${startDate}&finalDate=${endDate}`, {
    headers: {
      'x-api-key': process.env.REACT_APP_XAPIKEY,
      Authorization: 'Bearer ' + token,
    }
  })
  .then((res) => {
      setState(res.data.count)
      return res.data
  })
  .catch(err => {
      console.error('getSalesByUser:', err.message)
      throw new Error("Erro ao listar vendas");
  })

  return data
}

export async function getSalesOrganizationQuantity(token, organizationId, year, startDate, endDate, setState) {
  
  const data = await axios.get(`${process.env.REACT_APP_API_LOCAL}/sale/organization/${organizationId}/products/${year}?startDate=${startDate}&finalDate=${endDate}`, {
    headers: {
      'x-api-key': process.env.REACT_APP_XAPIKEY,
      Authorization: 'Bearer ' + token,
    }
  })
  .then((res) => {
      setState(res.data.count)
      return res.data
  })
  .catch(err => {
      console.error('getSalesByUser:', err.message)
      throw new Error("Erro ao listar vendas");
  })

  return data
}

export async function getSalesMonth(token, setState) {
  
  const data = await axios.get(`${process.env.REACT_APP_API_LOCAL}/sale/user/month`, {
    headers: {
      'x-api-key': process.env.REACT_APP_XAPIKEY,
      Authorization: 'Bearer ' + token,
    }
  })
  .then((res) => {
      setState(res.data.count)
      return res.data
  })
  .catch(err => {
      console.error('getSalesMonth:', err.message)
      throw new Error("Erro ao listar vendas por mês");
  })

  return data
}

export async function getSalesMonthOrganization(token, organizationId, year, setState) {
  
  const data = await axios.get(`${process.env.REACT_APP_API_LOCAL}/sale/organization/${organizationId}/month/${year}`, {
    headers: {
      'x-api-key': process.env.REACT_APP_XAPIKEY,
      Authorization: 'Bearer ' + token,
    }
  })
  .then((res) => {
      setState(res.data.count)
      return res.data
  })
  .catch(err => {
      console.error('getSalesMonth:', err.message)
      throw new Error("Erro ao listar vendas por mês");
  })

  return data
}

export async function deleteSale(token, saleId) {
  
  const data = await axios.delete(`${process.env.REACT_APP_API_LOCAL}/sale/${saleId}`, {
    headers: {
      'x-api-key': process.env.REACT_APP_XAPIKEY,
      Authorization: 'Bearer ' + token,
    }
  })
  .catch(err => {
      console.error('deleteSale:', err.message)
      throw new Error("Erro ao deletar venda");
  })

  return data
}