import axios from 'axios'

export async function createSubscription(body, person, planGalaxPayId, receipt, value) {
  console.log('person: ', person)
  try{
    const data = await axios.post(`${process.env.REACT_APP_API_LOCAL}/organization/subscriptions?typePerson=${person}&planGalaxPayId=${planGalaxPayId}&receipt=${receipt}&value=${value}`, body, {
      headers: {
        'x-api-key': process.env.REACT_APP_XAPIKEY,
        'content-Type': 'application/json'
      }
    })

    return data
  } catch (error) {
    console.error('Erro ao criar assinatura:', error);
    throw error;
  }
}

export async function getPlan(setState) {
  
  const data = await axios.get(`${process.env.REACT_APP_API_LOCAL}/organization/plans`,{
    headers: {
      'x-api-key': process.env.REACT_APP_XAPIKEY,
      'content-Type': 'application/json'
    }
  })
  .then((res) => {
    setState(res.data.Plans)
    return res.data
})
  .catch(err => {
      console.error('createSubscription:', err.message)
      throw new Error("Erro ao criar agendamento");
  })

  return data
}

export async function getPlanId(planId, startAt, limit, setState) {
  
  const data = await axios.get(`${process.env.REACT_APP_API_LOCAL}/organization/plans/${planId}/${startAt}/${limit}`,{
    headers: {
      'x-api-key': process.env.REACT_APP_XAPIKEY,
      'content-Type': 'application/json'
    }
  })
  .then((res) => {
    setState(res.data.Plans[0])
    return res.data
})
  .catch(err => {
      console.error('createSubscription:', err.message)
      throw new Error("Erro ao criar agendamento");
  })

  return data
}

export async function updatePayment(body, subscriptionId, typeId) {
  
  const data = await axios.post(`${process.env.REACT_APP_API_LOCAL}/organization/payment?subscriptionId=${subscriptionId}&typeId=${typeId}`, body, {
    headers: {
      'x-api-key': process.env.REACT_APP_XAPIKEY,
      'content-Type': 'application/json'
    }
  })
  .catch(err => {
      console.error('createSubscription:', err.message)
      throw new Error("Erro ao criar agendamento");
  })

  return data
}

export async function updateSginature(body, subscriptionId, typeId) {
  
  const data = await axios.post(`${process.env.REACT_APP_API_LOCAL}/organization/singnature?subscriptionId=${subscriptionId}&typeId=${typeId}`, body, {
    headers: {
      'x-api-key': process.env.REACT_APP_XAPIKEY,
      'content-Type': 'application/json'
    }
  })
  .catch(err => {
      console.error('createSubscription:', err.message)
      throw new Error("Erro ao criar agendamento");
  })

  return data
}

export async function cancelSginature(body) {
  
  const data = await axios.post(`${process.env.REACT_APP_API_LOCAL}/organization/signature/cancel`, body, {
    headers: {
      'x-api-key': process.env.REACT_APP_XAPIKEY,
      'content-Type': 'application/json'
    }
  })
  .catch(err => {
      console.error('createSubscription:', err.message)
      throw new Error("Erro ao criar agendamento");
  })

  return data
}

export async function getSubscription(id) {
  
  const data = await axios.get(`${process.env.REACT_APP_API_LOCAL}/organization/subscriptions/${id}`,{
    headers: {
      'x-api-key': process.env.REACT_APP_XAPIKEY,
      'content-Type': 'application/json'
    }
  })
  .then((res) => {
    // setState(res.data)
    return res.data
})
  .catch(err => {
      console.error('createSubscription:', err.message)
      throw new Error("Erro ao criar agendamento");
  })

  return data
}

export async function getCustomer(id) {
  
  const data = await axios.get(`${process.env.REACT_APP_API_LOCAL}/organization/customer/${id}`,{
    headers: {
      'x-api-key': process.env.REACT_APP_XAPIKEY,
      'content-Type': 'application/json'
    }
  })
  .then((res) => {
    return res.data
})
  .catch(err => {
      console.error('getCustomer:', err.message)
      throw new Error("Erro ao retornar cliente");
  })

  return data
}

export async function getVerify(id) {
  
  const data = await axios.get(`${process.env.REACT_APP_API_LOCAL}/organization/verify/${id}`,{
    headers: {
      'x-api-key': process.env.REACT_APP_XAPIKEY,
      'content-Type': 'application/json'
    }
  })
  .then((res) => {
    return res.data
})
  .catch(err => {
      console.error('getVerify:', err.message)
      throw new Error("Erro ao verificar conta");
  })

  return data
}