import React, { useState, useEffect } from 'react'
import './cart.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faCartShopping, faXmark, faPercent, faCirclePlay, faPause } from '@fortawesome/free-solid-svg-icons';
import Badge from 'react-bootstrap/Badge';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { getProductOrganization } from '../../Controllers/product'
import { createFee, getFeeOrganization, deleteFee } from '../../Controllers/fee'
import { createSale } from '../../Controllers/cart'
import { parseCookies } from 'nookies'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { sum } from 'mathjs'
import toast, { Toaster } from 'react-hot-toast';
import { redirectLogin } from '../../Functions';
import Loader  from '../../Components/Loader'
import Modal from 'react-bootstrap/Modal';
import Joyride from 'react-joyride';
import { StepsCart } from '../../Steps'

function Cart() {
    const [show, setShow] = useState(false);
    let [products, setProducts] = useState([])
    const [fee, setFee] = useState([])
    const [nameFee, setNameFee] = useState([])
    const [valueFee, setValueFee] = useState([])
    const [selectedFee, setSelectedFee] = useState(0)
    const [paymentMethod, setPaymentMethod] = useState('')
    const [arrayProducts, setArrayProducts] = useState([])
    const [stateChange, setStateChange] = useState('');
    const [descount, setDescount] = useState(0);
    const [loading, setLoading] = useState(true)
    const [modalShow, setModalShow] = useState(false)
    const [step, setStep] = useState(0)
    const [isZero, setIsZero] = useState(true)
    const [startTutoria, setStartTutorial] = useState(false)

    const nextStep = () => {
        if(isZero) {
            setIsZero(false)
        } else {
            setStep(step + 1)
        }
        if(step === 2){ 
            setStartTutorial(false) 
            setStep(0)
            setIsZero(true)
        }
    }

    const cookies = parseCookies()
    const token = cookies.token
    const organizationId = window.localStorage.getItem('organization')

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const totalSale = arrayProducts.map((sale) => sale.salePrice)
    const percentFee = sum(totalSale)/100 * selectedFee
    let totalSaleAndDescount = sum(totalSale) - descount - percentFee 

    products = products?.filter(
		(f) => f.name.toLowerCase().indexOf(stateChange.toLowerCase()) > -1
	);

    async function search(event) {
		setStateChange(event.target.value);
    }

    async function getProducts() {
        await getProductOrganization(token, organizationId, setProducts)
          .then((res) => {
            setLoading(false)
          })
          .catch((err) => {
            console.log('erro ao se registrar', err)
          })
    }

    async function getFee() {
        await getFeeOrganization(token, organizationId, setFee)
          .then((res) => {
            setLoading(false)
          })
          .catch((err) => {
            console.log('erro ao se registrar', err)
          })
    }

    useEffect(() => {
        if(token === undefined){
            redirectLogin()
        }
        getProducts()
        getFee()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    async function registerSale() {
        const body = {
            products: arrayProducts,
            descount: descount,
            fee: selectedFee,
            paymentMethod: paymentMethod,
            total: totalSaleAndDescount,
            organizationId: organizationId
        }

        

        await createSale(body, token)
            .then((res) => {
                handleClose()
                setArrayProducts([])
                toast.success('Venda realizada com sucesso ', {icon: '💸'})
            })
            .catch((err) => {
                console.log('erro ao registrar procedimento: ', err)
                if(totalSaleAndDescount <= 0){
                    toast.error('Opss!! é preciso adicionar procedimentos ao carrinho')

                } else {
                    toast.error('Erro ao realizar venda ')
                }
            })
    }

    async function registerFee() {
        const body = {
            name: nameFee,
            value: valueFee,
            organizationId: organizationId
        }

        await createFee(body, token)
            .then((res) => {
                toast.success('Taxa registrada com sucesso ')
                setNameFee('')
                setValueFee('')
                getFee()
            })
            .catch((err) => {
                console.log('erro ao registrar procedimento: ', err)
                toast.error('Erro registrar taxa ')
            })
    }

    async function feeDelete(feeId) {
        await deleteFee(token, feeId)
            .then(() => {
                toast.success('Taxa excluída com sucesso ')
                getFee()
            })
            .catch((err) => {
                console.log('erro ao exluir taxa: ', err)
                toast.error('Erro excluir taxa ')
            })
    }

    const addSales = (sale) => {
        arrayProducts.push(sale)
        setArrayProducts([...arrayProducts])
        console.log(arrayProducts)
    }

    const saleCancel = () => {
        handleClose()
        setArrayProducts([])
        setSelectedFee(0)
        setPaymentMethod('')
        setDescount(0)
        totalSaleAndDescount = 0
    }

    const deleteItemCart = (index) => {
        arrayProducts.splice(index, 1)
        setArrayProducts([...arrayProducts])
    }

    const changeOptionFee = (value) => {
        setSelectedFee(value)
    }

    const goToProducts = () => {
        window.location.href = '/register'
    }

  return (
    <>
    <span onClick={nextStep}>
        <Joyride
            steps={StepsCart}
            continuous
            run={startTutoria}
            stepIndex={step}
            showProgress
            locale={{next: 'Próximo', back: '', last: 'Final'}}
            debug={true}
        />
    </span>
 
    <div className='container-cart'> 
        <Toaster position="bottom-center" />
        <div className='content-cart'>
            <div className='container-total-cart'>
                <p className='titles' style={{ marginBottom: '0rem'}}>Frente de caixa</p>
                <div className='container-badge' onClick={() => setModalShow(true)}>
                    <OverlayTrigger placement='bottom' overlay={<Tooltip id="">Cadastre as taxas da máquina de cartão</Tooltip>}>
                        <FontAwesomeIcon id='tax-sales' icon={faPercent} />
                    </OverlayTrigger>
                </div>
                <div className='container-badge' onClick={handleShow}>
                    <FontAwesomeIcon icon={faCartShopping} />
                    <Badge id='cart-sales' bg="success">{arrayProducts.length <= 0 ? 0 : arrayProducts.length}</Badge>
                </div>
                <div>
                    {!startTutoria ? (
                        <OverlayTrigger placement='bottom' overlay={<Tooltip id="">Começar tutorial</Tooltip>}>
                            <FontAwesomeIcon 
                                style={{ 
                                    color: 'green',
                                    fontSize: '20px', 
                                    marginRight: '10px',
                                    cursor: 'pointer',
                                    marginTop: '5px'
                                }}  
                                onClick={() => setStartTutorial(true)} 
                                id='' 
                                icon={faCirclePlay} 
                            />
                        </OverlayTrigger>
                    ):(
                        <OverlayTrigger placement='bottom' overlay={<Tooltip id="">Pausar tutorial</Tooltip>}>
                            <FontAwesomeIcon 
                                style={{ 
                                    color: 'red',
                                    fontSize: '20px', 
                                    marginRight: '10px',
                                    cursor: 'pointer',
                                    marginTop: '5px' 
                                }} 
                                onClick={() => setStartTutorial(false)} 
                                id=''
                                icon={faPause} 
                            />
                        </OverlayTrigger>
                    )}
                </div>
            </div>
            <div className='container-search-cart'>
                <input onChange={(e) => search(e)} type='text' placeholder='Pesquisar nome do procedimento' className='input-style-register' />
            </div> 

            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modalShow}
                onHide={() => setModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Taxas da Máquina de Cartão
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p className='titles'>Cadastrar taxa</p>
                    </div>
                    <div className='container-inputs-fee'>
                        <div>
                            <input 
                                className='input-style-register' 
                                placeholder='Tipo de taxa (ex: débito, crédito)' 
                                onChange={(e) => setNameFee(e.target.value)}
                                value={nameFee}
                            />
                        </div>

                        <div>
                            <input 
                                type='number' 
                                className='input-style-register' 
                                placeholder='Percentual (ex: 1.80)' 
                                onChange={(e) => setValueFee(e.target.value)}
                                value={valueFee}
                            />
                        </div>
                        <div>
                            <button onClick={registerFee} className='button-style-fee'>Cadastrar</button>
                        </div>
                    </div>
                    <div>
                        <p className='titles'>Taxas</p>
                    </div>
                    {fee.map((f) => {
                        return(
                            <>
                                <div className='container-list-fees'>
                                    <div>
                                        <span><b>{f.name}:</b></span>
                                        <span className='value-fee'>{f.value}%</span>
                                    </div>
                                    
                                    <span onClick={() => feeDelete(f._id)} >
                                        <FontAwesomeIcon 
                                            icon={faXmark} 
                                            style={{ 
                                                color: 'rgb(255, 70, 67)', 
                                                cursor: 'pointer' }}
                                            />
                                    </span>
                                    <hr className='style-border-fee' />
                                </div>
                            </>
                           
                        )
                    })}
                 
                </Modal.Body>
                
            </Modal>

            {loading ? (
                <>
                    <div className='container-loading-sale'>
                        <Loader />
                    </div>
                </>
            ) : (
                <>
                    {products.length <= 0 ? (
                        <div className='container-not-sales'>
                            <p className='text-not-sales'>Ainda não existem procedimentos registrado 😔. <br/>
                                Aproveite e registre clicando <span onClick={goToProducts} className='text-link-cart'>aqui</span>
                            </p>
                        </div>
                    ):(
                        <>
                            {products.map((product) => {
                                return(
                                    <div className='container-list-product'>
                                        <div className='container-product-cart'>
                                            <div className='content-product'>
                                                <span>{product.name}</span>
                                            </div>
                        
                                            <div className='content-product'>
                                                <span>{product.salePrice.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
                                            </div >
                        
                                            <div className='content-product' onClick={() => addSales(product)}>
                                                <FontAwesomeIcon  id='btn-plus' icon={faPlus} style={{ color: '#198754' }} />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </>
                    )}
                   
                </>
            )}
           
        </div>

        <Offcanvas show={show} placement='end' onHide={handleClose}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Carrinho</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                {arrayProducts.map((sale, index) => {
                    return(
                        <>
                            <div id='container-list-products' className='content-cart-finish'>
                                <span>{sale.name}</span>
                                <span>{sale.salePrice.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
                                <span>
                                    <FontAwesomeIcon 
                                        onClick={() => deleteItemCart(index)} 
                                        icon={faXmark} 
                                        style={{ 
                                            color: 'rgb(255, 70, 67)', 
                                            cursor: 'pointer' }}
                                        />
                                </span>
                            </div>
                            <hr />
                         </>
                    )
                })}
            </Offcanvas.Body>
            <div className='container-descount'>
                <span>Desconto:</span>
                <input  
                    onChange={(e) => setDescount(e.target.value)} 
                    type='number' 
                    placeholder='inseria o valor' 
                    className='input-style-descount' 
                    disabled={arrayProducts.length === 0 ? true : false}
                />
            </div>
            <div className='container-descount'>
                <span>Taxa da máquina de cartão:</span>
                <select 
                    defaultValue={selectedFee} 
                    className='select-method' 
                    onClick={(e) => changeOptionFee(e.target.value)}
                    disabled={totalSaleAndDescount <= 0 ? true : false}
                >
                    <option  value=''> Escolha uma taxa </option>
                    {fee.map((f, index) => {
                        return(
                            <>
                                <option  value={f.value}> {f.name} - {f.value} </option>
                            </>
                        )
                    })}
                   
                </select>
            </div>
            <div className='container-descount'>
                <span>Método de pagamento:</span>
                <select 
                    defaultValue={paymentMethod}  
                    className='select-method' 
                    onChange={(e) => setPaymentMethod(e.target.value)}
                    disabled={totalSaleAndDescount <= 0 ? true : false}
                >
                    <option value="" >Escolha um método</option>
                    <option value="débito" >Débito</option>
                    <option value="crédito">Crédito</option>
                    <option value="dinheiro">Dinheiro</option>
                    <option value="pix">Pix</option>
                    <option value="Link de pagamento">Link de pagamento</option>
                </select>
            </div>
            <div className='content-cart-total'>
                <span className='style-total-title'>TOTAL: </span>
                <span className='style-value-total'>{totalSaleAndDescount.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
            </div>
            <div className='container-button-cart'>
                <button onClick={registerSale} className='button-style-cart'>Finalizar</button>
            </div>
            <div className='container-button-cart' >
                <button onClick={saleCancel}  className='button-cart-cancel'>Cancelar</button>
            </div>
        </Offcanvas>
    </div>
    </>
  );
}

export default Cart;