import React, { useState, useEffect } from 'react'
import './entrance.css'
import { Table } from 'react-bootstrap';
import { parseCookies } from 'nookies'
import { getEntrancesByUser, getEntranceOrganization } from '../../Controllers/entrance'
import Moment from 'moment'
import { sum } from 'mathjs'
import { redirectLogin } from '../../Functions';
import Loader  from '../../Components/Loader'
import ButtonMore  from '../../Components/ButtonMore'

export function convertDateToISOFormat(date, hour) {
	const time = date + ' ' + hour;
	return new Date(time.replace(/\s/g, 'T') + 'Z').toISOString();
}

function Entrance() {
    const [entrances, setEntrances] = useState([])
    const [startDate, setStartDate] = useState(Moment().subtract(7, 'days').format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(Moment().format('YYYY-MM-DD'))
    const initialTime = '00:00'
	const finalTime = '23:59'
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(true)
    const [loadingButton, setLoadingButton] = useState(false)
    const [resume, setResume] = useState()

    const cookies = parseCookies()
    const token = cookies.token
    const organizationId = window.localStorage.getItem('organization')

    async function getEntrances() {
        const start = convertDateToISOFormat(startDate, initialTime)
        const end = convertDateToISOFormat(endDate, finalTime)
        const data = await getEntranceOrganization(token, organizationId, start, end, setEntrances, setResume, 0)
          .then((res) => {
            setLoading(false)
          })
          .catch((err) => {
            console.log('erro ao se registrar', err)
          })
        
          if(!data) {

          } else {
            setEntrances([...data.entraces])
          }
    }

    async function getMoreEntrances() {
        setLoadingButton(true)
        const start = convertDateToISOFormat(startDate, initialTime)
        const end = convertDateToISOFormat(endDate, finalTime)
        const data = await getEntranceOrganization(token, organizationId, start, end, () => { }, () => { }, page)
          .catch((err) => {
            console.log('erro ao se registrar', err)
          })
        
        if(!data) {
        } else {
            setEntrances([...entrances, ...data.entrances])
            setPage(page + 1)
            setLoadingButton(false)
        }
    }

    async function getEntranceFilter() {
        const start = convertDateToISOFormat(startDate, initialTime)
        const end = convertDateToISOFormat(endDate, finalTime)
        setLoading(true)
        await getEntranceOrganization(token, organizationId, start, end, setEntrances, setResume, 0)
            .then((res) => {
                setLoading(false) 
            })
            .catch((err) => {
                console.log('erro ao se registrar', err)
            })
    }

    async function getSalesFilterDate(day) {

        const startFilterSelect = Moment().subtract(day, 'days').format('YYYY-MM-DD')
        const endFilterSelect = Moment().format('YYYY-MM-DD')
        setStartDate(startFilterSelect)
        setEndDate(endFilterSelect)
        const start = convertDateToISOFormat(startFilterSelect, initialTime)
        const end = convertDateToISOFormat(endFilterSelect, finalTime)
        setLoading(true)
        await getEntranceOrganization(token, organizationId, start, end, setEntrances, setResume, 0)
          .then((res) => {
            setLoading(false)
          })
          .catch((err) => {
            console.log('erro ao se registrar', err)
          })
    }

    useEffect(() => {
        if(token === undefined){
            redirectLogin()
        }
        getEntrances()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

  return (
    <div className='container-entrance'>
        <div className='content-entrance'>
            <div className='container-total-value-entrance'>
                <p className='titles'>Entradas</p>
                <div className='container-total-value-sales'>
                    <div>
                        <p className='total-sales-entrance'>Vendas: {resume?.total}</p>
                    </div>
                    <div>
                        <p className='total-values-entrance'>Total: {sum(resume?.totalValue).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                    </div>
                </div>
            </div>

            <div className='container-search-entrance'>
                <div className='container-inputs-entrance'>
                    <select 
                        className='input-style-entrance'
                        onChange={(e) => getSalesFilterDate(e.target.value)}
                        defaultValue='7'
                    >
                        <option value='7'>1 semana</option>
                        <option value='30'>30 dias</option>
                        <option value='60'>60 dias</option>
                    </select>
                </div>
                <div className='container-inputs-entrance'>
                    <input 
                        type='date' 
                        placeholder='Ex: Design com Henna' 
                        className='input-style-entrance' 
                        onChange={(e) => setStartDate(e.target.value)}
                        max={Moment().format('YYYY-MM-DD')}
                        min={Moment().subtract(90, 'days').format('YYYY-MM-DD')}
                    />
                </div>

                <div className='container-inputs-entrance'>
                    <input 
                        type='date' 
                        placeholder='Ex: Design com Henna' 
                        className='input-style-entrance' 
                        onChange={(e) => setEndDate(e.target.value)}
                        max={Moment().format('YYYY-MM-DD')}
                        min={Moment().subtract(90, 'days').format('YYYY-MM-DD')}
                    />
                </div>

                <div className='container-button-entrance'>
                    <button onClick={getEntranceFilter} className='button-style-entrance'>Pesquisar</button>
                </div>
            </div>

            {loading ? (
                <> 
                    <div className='container-loading-sale'>
                        <Loader />
                    </div>
                </>
            ):(
                <>
                    {entrances.length <= 0 ? (
                        <div className='container-not-sales'>
                            <p className='text-not-sales'>Não existe nenhuma venda registrada nesse período. <br/>
                            Para visualizar, registre uma venda
                            </p>
                        </div>
                    ):(
                        <div className='container-table-entrance'>
                            <Table striped>
                                <thead>
                                    <tr>
                                        <th>Entrada</th>
                                        <th>Valor</th>
                                        <th>Data</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {entrances.map((entrance) => {
                                        return(
                                            <>
                                                <tr>
                                                    <td>Venda</td>
                                                    <td>{entrance.value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                                                    <td>{Moment(entrance?.createdAt).format('DD/MM/YY - HH:mm')}</td>
                                                </tr>
                                            </>
                                        )
                                    })}
                                </tbody>
                            </Table>
                            {page < resume?.maxpage && (
                                <>
                                    <ButtonMore 
                                        onClick={getMoreEntrances} 
                                        title={loadingButton ? <Loader size='sm' /> : 'Carregar Mais +'}
                                    />
                                </>
                            )}
                        </div>
                    )}
                </>
            )}
        </div>
    </div>
  );
}

export default Entrance;