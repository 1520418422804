import axios from 'axios'

  export async function createUser(body) {
  
    const data = await axios.post(`${process.env.REACT_APP_API_LOCAL}/auth/register`, body, {
      headers: {
        'x-api-key': process.env.REACT_APP_XAPIKEY,
        // Authorization: 'Bearer ' + token,
        'content-Type': 'application/json'
      }
    })
    .catch(err => {
        console.error('createUser:', err.message)
        throw new Error("Não Autorizado");
    })

    return data
  }

  export async function authenticate(body) {
  
    const data = await axios.post(`${process.env.REACT_APP_API_LOCAL}/auth/authenticate`, body, {
      headers: {
        'x-api-key': process.env.REACT_APP_XAPIKEY,
        // Authorization: 'Bearer ' + token,
        'content-Type': 'application/json'
      }
    })
    .catch(err => {
        console.error('Authenticate:', err.message)
        throw new Error("Não Autorizado, erro ao logar");
    })

    return data
  }


  export async function sendEmail(email) {
  
    const data = await axios.post(`${process.env.REACT_APP_API_LOCAL}/auth/forgot`, email, {
      headers: {
        'x-api-key': process.env.REACT_APP_XAPIKEY,
        'content-Type': 'application/json'
      }
    })
    .catch(err => {
        console.error('Authenticate:', err.message)
        throw new Error("Não Autorizado, erro ao logar");
    })

    return data
  }

  export async function resetPass(body) {
  
    const data = await axios.post(`${process.env.REACT_APP_API_LOCAL}/auth/reset`, body, {
      headers: {
        'x-api-key': process.env.REACT_APP_XAPIKEY,
        'content-Type': 'application/json'
      }
    })
    .catch(err => {
        console.error('Authenticate:', err.message)
        throw new Error("Não Autorizado, erro ao logar");
    })

    return data
  }