import axios from 'axios'

export async function createCalendar(body, token) {
  
    const data = await axios.post(`${process.env.REACT_APP_API_LOCAL}/calendar`, body, {
      headers: {
        'x-api-key': process.env.REACT_APP_XAPIKEY,
        Authorization: 'Bearer ' + token,
        'content-Type': 'application/json'
      }
    })
    .catch(err => {
        console.error('createCalendar:', err.message)
        throw new Error("Erro ao criar agendamento");
    })

    return data
}

export async function getCalendar(token, setState) {
  
    const data = await axios.get(`${process.env.REACT_APP_API_LOCAL}/calendar/user`, {
      headers: {
        'x-api-key': process.env.REACT_APP_XAPIKEY,
        Authorization: 'Bearer ' + token,
      }
    })
    .then((res) => {
      setState(res.data.calendar)
      return res.data
    })    
    .catch(err => {
        console.error('createScheduling:', err.message)
        throw new Error("Erro ao criar Agendamento");
    })
  
    return data
  }

  export async function getCalendarOrganization(token, organizationId,setState) {
  
    const data = await axios.get(`${process.env.REACT_APP_API_LOCAL}/calendar/organization/${organizationId}`, {
      headers: {
        'x-api-key': process.env.REACT_APP_XAPIKEY,
        Authorization: 'Bearer ' + token,
      }
    })
    .then((res) => {
        setState(res.data.calendar)
        return res.data
    })
    .catch(err => {
        console.error('getCalendarOrganization:', err.message)
        throw new Error("Erro ao listar Agendamento");
    })
  
    return data
  }

  export async function deleteEvents(token, calendarId) {
  
    const data = await axios.delete(`${process.env.REACT_APP_API_LOCAL}/calendar/${calendarId}`, {
      headers: {
        'x-api-key': process.env.REACT_APP_XAPIKEY,
        Authorization: 'Bearer ' + token,
        'content-Type': 'application/json'
      }
    })
    .catch(err => {
        console.error('deleteExit:', err.message)
        throw new Error("Erro ao editar saída");
    })

    return data
}

  