import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/free-regular-svg-icons';
import './notification.css'
import Popper from '@mui/material/Popper';
import Divider from '@mui/material/Divider';
import Badge from '@mui/material/Badge';
import { getNotificationOrganization, updateNotification } from '../../Controllers/notification'
import { parseCookies } from 'nookies'
import moment from 'moment'

function Notification() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
    const [notification, setNotification] = useState([]);

    const organizationId = window.localStorage.getItem('organization')
    const cookies = parseCookies()
    const token = cookies.token

    const readerTrue = notification?.some((n) => n.reader === false)
    async function getNotification() {
        await getNotificationOrganization(token, organizationId, setNotification)
        .catch((err) => {
            console.log('erro ao retornar notificações: ', err)
        })
    }

    useEffect(() => {
        getNotification()
    },[])

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };


    const updateReader = async(data) => {
        data.reader = true
        await updateNotification(data, data._id, token)
        .then(() => {
            getNotification()
        })
        .catch((err) => {
            console.log('erro ao atualizar notificação: ', err)
        })
    }
    
    return (
        <>
            <div className='container-notification'>
                <div onClick={handleClick} className='container-icon-notification'>
                    {readerTrue && (
                        <div className='container-notification-badge'>
                            <Badge 
                                badgeContent={2} 
                                color="success"
                                variant='dot'
                            />
                        </div>
                    )}
                    <FontAwesomeIcon style={{ color: '#3d3d3d', fontSize: '19px' }} icon={faBell} />
                </div>
                <Popper 
                    id={id} 
                    open={open} 
                    anchorEl={anchorEl}
                    className='style-popper'
                >
                    <div className='style-notification'>
                        {notification.length === 0 ? (
                            <div className='container-not-notification'>
                                <p className='title-not-notification'>Não há notificações</p>
                            </div>
                        ):(
                            <>
                                {notification.slice(0, 20).map((noti) => (
                                    <>
                                        <div 
                                            className='container-notification-not-reader'
                                            onClick={() => noti.reader === false && updateReader(noti)}
                                        >
                                            <p className={noti.reader ? 'title-notification' : 'title-notification-not-reader'}>
                                                {noti.title}
                                            </p>
                                            <div className={noti.reader ? '' : 'container-message-not-reader'}>
                                                <span className={noti.reader ? 'message-notification' : 'message-notification-not-reader'}>
                                                    {noti.message}
                                                </span>
                                                <p className='title-different-days'>
                                                    há {' '}
                                                    {moment().diff(moment(noti?.createdAt), 'days')}{' '}
                                                    {moment().diff(moment(noti?.createdAt), 'days') > 1 ? 'dias' : 'dia'}
                                                </p>
                                            </div>
                                            <Divider sx={{ marginTop: '10px'}} />
                                        </div>
                                    </>
                                ))}
                            </>
                        )}
                    </div>
                </Popper>
            </div>
      </>
    );
  }
  
  export default Notification;