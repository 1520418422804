import React, { useState, useEffect } from 'react'
import './exits.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTrash, faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import { createExits, getExitsOrganization, deleteExit, updateExit } from '../../Controllers/exits'
import { parseCookies } from 'nookies'
import Moment from 'moment'
import toast, { Toaster } from 'react-hot-toast';
import { redirectLogin } from '../../Functions';
import Loader  from '../../Components/Loader'
import ButtonMore  from '../../Components/ButtonMore'
import Offcanvas from 'react-bootstrap/Offcanvas';
import { sum } from 'mathjs'

export function convertDateToISOFormat(date, hour) {
	const time = date + ' ' + hour;
	return new Date(time.replace(/\s/g, 'T') + 'Z').toISOString();
}

function Exits() {
    const [exits, setExits] = useState([])
    const [allExits, setAllExits] = useState([])
    const [description, setDescription] = useState('')
    const [value, setValue] = useState()
    const [date, setDate] = useState()
    const [editOn, setEditOn] = useState(false);
    const [id, setId] = useState();
    const [loading, setLoading] = useState(true)
    const [startDate, setStartDate] = useState(Moment().subtract(7, 'days').format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(Moment().format('YYYY-MM-DD'))
    const initialTime = '00:00';
	const finalTime = '23:59';
    const [page, setPage] = useState(1)
    const [loadingButton, setLoadingButton] = useState(false)
    const [resume, setResume] = useState()
    const [show, setShow] = useState(false);

    const cookies = parseCookies()
    const token = cookies.token
    const organizationId = window.localStorage.getItem('organization')

    async function getExits() {
        const start = convertDateToISOFormat(startDate, initialTime)
        const end = convertDateToISOFormat(endDate, finalTime)
        await getExitsOrganization(token, organizationId, start, end, setExits, setResume, 0)
          .then((res) => {
            setLoading(false)
          })
          .catch((err) => {
            console.log('erro ao se registrar', err)
          })
    }

    async function getMoreExitis() {
        setLoadingButton(true)
        const data = await getExitsOrganization(token, organizationId, '', '', () => { }, () => { }, page)
          .catch((err) => {
            console.log('erro ao se registrar', err)
          })
        
        if(!data) {
        } else {
            setExits([...exits, ...data.exits])
            setPage(page + 1)
            setLoadingButton(false)
        }
    }

    async function getExitsFilter() {
        const start = convertDateToISOFormat(startDate, initialTime)
        const end = convertDateToISOFormat(endDate, finalTime)
        setLoading(true)
        await getExitsOrganization(token, organizationId, start, end, setExits, setResume, 0)
            .then((res) => {
                setLoading(false) 
            })
            .catch((err) => {
                console.log('erro ao se registrar', err)
            })
    }

    async function getSalesFilterDate(day) {

        const startFilterSelect = Moment().subtract(day, 'days').format('YYYY-MM-DD')
        const endFilterSelect = Moment().format('YYYY-MM-DD')

        const start = convertDateToISOFormat(startFilterSelect, initialTime)
        const end = convertDateToISOFormat(endFilterSelect, finalTime)
        setLoading(true)
        await getExitsOrganization(token, organizationId, start, end, setExits, setResume, 0)
          .then((res) => {
            setLoading(false)
          })
          .catch((err) => {
            console.log('erro ao se registrar', err)
          })
    }

    // async function downloadHistoryExits() {
    //     await getExitsOrganization(token, organizationId, '', '', setExits)
    //       .then((res) => {
            
    //       })
    //       .catch((err) => {
    //         console.log('erro ao se registrar', err)
    //       })
    // }

  
    useEffect(() => {
        if(token === undefined){
            redirectLogin()
        }
        getExits()
        // downloadHistoryExits()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    async function registerExit() {
        const body = {
            description: description,
            value: value,
            date: Moment(date),
            organizationId: organizationId
        }

        await createExits(body, token)
            .then((res) => {
                getExits()
                setDescription('')
                setValue('')
                setDate('')
                toast.success('Saída cadastrada com sucesso ')
            })
            .catch((err) => {
                console.log('erro ao registrar saída: ', err)
                toast.error('Erro ao registrar Saída ')
            })
    }

    
    async function exitDelete(exitId) {

        await deleteExit(token, exitId)
            .then((res) => {
                getExits()
                toast.success('Saída excluída com sucesso ')
            })
            .catch((err) => {
                console.log('erro ao registrar procedimento: ', err)
                toast.error('Erro ao deletar Saída ')
            })
    }

    async function exitUpdate(productId) {
        const body = {
            description: description,
            value: value,
            date: Moment(date),
        }

        await updateExit(token, productId, body)
            .then((res) => {
                getExits()
                setDescription('')
                setValue('')
                setDate('')
                setEditOn(false)
                toast.success('Saída atualizada com sucesso ')
            })
            .catch((err) => {
                console.log('erro ao registrar procedimento: ', err)
                toast.error('Erro ao atualizar Saída ')
            })

    }

    const cancelUpdate = () => {
        setEditOn(false)
        setDescription('')
        setValue('')
        setDate('')
    }

    async function updateInputs(exit) {
        setDescription(exit.description)
        setValue(exit.value)
        setDate(exit.date)
        setEditOn(true)
        setId(exit._id)
    } 

    const dataExits = allExits?.map((e) => { 
        return(
            {
                date: Moment(e.date).format('DD/MM/YY'),
                description: e.description,
                // products: e.products.map((e) =>  e.name.toString()),
                value: e.value
            }
        )
    })

    const headersExits = [
        { label: 'Data Cadastrada', key: 'date' },
        { label: 'Descrição', key: 'description' },
        { label: 'Total', key: 'value' }
    ]

    async function clearInputs() {
        setShow(false)
    } 

  return (
    <div className='container-exits'>
        <Toaster position="bottom-center" />
        <div className='content-exits'>
            <div className='container-total-value-entrance'>
            <p className='titles'>Saídas</p>
                <div className='container-total-value-sales'>
                    <div>
                        <p className='total-sales-entrance'>Saídas: {resume?.total}</p>
                    </div>
                    <div>
                        <p className='total-values-entrance'>Total: {sum(resume?.totalValue).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                    </div>
                </div>
            </div>
            <div className='container-button-show'>
                <button 
                    onClick={() => setShow(true)} 
                    className='button-show-style-register'
                    id='add-scheduling'
                > 
                    + Cadastrar Saídas
                </button>
            </div>
          

            <div className='container-download-exits'>
                <p className='titles'>Lista de Saídas</p>
                {/* <div onClick={downloadHistoryExits}>
                    <OverlayTrigger placement='bottom' overlay={<Tooltip id="">Clique para baixar todo histórico de saídas</Tooltip>}>
                        <CSVLink 
                            data={dataExits ? dataExits : ''} 
                            filename={'saídas.csv'} 
                            headers={headersExits} 
                            separator={';'}
                        >
                            <div className='container-icon-download-exits'>
                                <FontAwesomeIcon style={{ color: 'white', fontSize: '13px'}} icon={faFileArrowDown} />
                            </div>
                        </CSVLink>
                    </OverlayTrigger>
                </div> */}
            </div>
            <div className='container-search-exits'>
                <div className='container-inputs-exits'>
                    <select 
                        className='input-style-entrance'
                        onChange={(e) => getSalesFilterDate(e.target.value)}
                        defaultValue='7'
                    >
                        <option value='7'>1 semana</option>
                        <option value='30'>30 dias</option>
                        <option value='60'>60 dias</option>
                    </select>
                </div>
                <div className='container-inputs-exits'>
                    <input 
                        type='date' 
                        placeholder='Ex: Design com Henna' 
                        className='input-style-sales' 
                        onChange={(e) => setStartDate(e.target.value)}
                        max={Moment().format('YYYY-MM-DD')}
                        min={Moment().subtract(90, 'days').format('YYYY-MM-DD')}
                    />
                </div>

                <div className='container-inputs-exits'>
                    <input 
                        type='date' 
                        placeholder='Ex: Design com Henna' 
                        className='input-style-sales' 
                        onChange={(e) => setEndDate(e.target.value)}
                        max={Moment().format('YYYY-MM-DD')}
                        min={Moment().subtract(90, 'days').format('YYYY-MM-DD')}
                    />
                </div>

                <div className='container-button-exits'>
                    <button onClick={getExitsFilter} className='button-style-exits'>Pesquisar</button>
                </div>
            </div>

            {loading ? (
                <>
                    <div className='container-loading-sale'>
                        <Loader />
                    </div>
                </>
            ):(
                <>
                    {exits.length <= 0 ? (
                        <div className='container-not-sales'>
                          <p className='text-not-sales'>Não existe nenhuma despesa registrada.</p>
                        </div>
                    ):(
                        <div className='container-table-exits'>
                            <Table striped>
                                <thead>
                                    <tr>
                                        <th>Descrição</th>
                                        <th>Valor</th>
                                        <th>Data</th>
                                        <th>Ação</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {exits.map((exit) => {
                                        return(
                                            <>
                                                <tr className={editOn && exit._id === id ? 'container-inputs-table-responsive' : ''}>
                                                    <td>
                                                        { 
                                                            editOn && exit._id === id ? 
                                                            <input 
                                                                onChange={(e) => setDescription(e.target.value)} 
                                                                value={description}
                                                                type='text' 
                                                                placeholder='Ex: compra de materiais' 
                                                                className='input-style-register' 
                                                            /> : 
                                                            exit.description
                                                        }
                                                    </td>
                                                    <td className='container-input-edit-value'>
                                                        {
                                                        editOn && exit._id === id ? 
                                                        <input 
                                                                onChange={(e) => setValue(e.target.value)} 
                                                                value={value}
                                                                type='number' 
                                                                placeholder='Ex: 200,00' 
                                                                className='input-style-edit-exit-value' 
                                                            /> : 
                                                        exit.value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                                                        }
                                                    </td>
                                                    <td>
                                                        <div className='container-update-items'>
                                                            {
                                                            editOn && exit._id === id ? 
                                                            <input 
                                                                onChange={(e) => setDate(e.target.value)} 
                                                                value={date}
                                                                type='date' 
                                                                placeholder='Ex: 200,00' 
                                                                className='input-style-edit-exit-date' 
                                                            /> : 
                                                            Moment(exit?.date).format('DD/MM/YY')
                                                            }

                                                            {
                                                                editOn && exit._id === id ? 
                                                                    <div className='container-icons-edit-exits'>
                                                                        <FontAwesomeIcon 
                                                                            icon={faCircleCheck} 
                                                                            onClick={() => exitUpdate(exit._id)}
                                                                            style={{ 
                                                                                color: '#198754', 
                                                                                cursor: 'pointer',
                                                                                fontSize: '20px',
                                                                            }} />
                                                                        <FontAwesomeIcon 
                                                                            icon={faCircleXmark} 
                                                                            onClick={cancelUpdate}
                                                                            style={{ 
                                                                                color: 'rgb(255, 70, 67)',
                                                                                cursor: 'pointer',
                                                                                marginLeft: '5%',
                                                                                fontSize: '20px'
                                                                            }} />
                                                                    </div> : ''
                                                            }
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='container-icons-table-exits'>
                                                            <div >
                                                                <FontAwesomeIcon
                                                                    onClick={() => updateInputs(exit)} 
                                                                    icon={faPenToSquare}
                                                                    style={{
                                                                        color: 'rgba(249,141,1, 0.9)',
                                                                        display: editOn && exit._id === id ? 'none': ''
                                                                    }} />
                                                            </div>
                                                            <div onClick={() => exitDelete(exit._id)}>
                                                                <FontAwesomeIcon
                                                                    icon={faTrash}
                                                                    style={{
                                                                        color: '#f76459',
                                                                        display: editOn && exit._id === id ? 'none': ''
                                                                    }} />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    })}

                                </tbody>
                            </Table>
                            {page < resume?.maxpage && (
                                <>
                                    <ButtonMore 
                                        onClick={getMoreExitis} 
                                        title={loadingButton ? <Loader size='sm' /> : 'Carregar Mais +'}
                                    />
                                </>
                            )}
                        </div>
                    )}
                    
                </>
            )}
            <Offcanvas show={show} placement='end' onHide={() =>  clearInputs()}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Registrar Procedimento</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className='container-content-exits'>
                        {/* <div className='container-inputs-content-exits'> */}
                            <p className='title-inputs-exits'>Descrição da despesa*</p>
                            <input 
                                onChange={(e) => setDescription(e.target.value)} 
                                value={editOn ? '' :  description}
                                type='text' 
                                placeholder='Ex: compra de materiais' 
                                className='input-style-register' 
                            />
                        {/* </div> */}
                        {/* <div className='container-inputs-content-exits'> */}
                            <p className='title-inputs-exits'>Valor de despesa*</p>
                            <input 
                                onChange={(e) => setValue(e.target.value)} 
                                value={editOn ? '' : value}
                                type='text' 
                                placeholder='Ex: 200,00' 
                                className='input-style-register' 
                            />
                        {/* </div> */}
                        {/* <div className='container-inputs-content-exits'> */}
                            <p className='title-inputs-exits'>Data*</p>
                            <input 
                                onChange={(e) => setDate(e.target.value)} 
                                value={editOn ? '' : date}
                                type='date' 
                                placeholder='Ex: 200,00' 
                                className='input-style-register' 
                            />
                        {/* </div> */}
                        <div className='container-button-exits-register'>
                            <button onClick={registerExit} className='button-style-exits'>Cadastrar</button>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    </div>
  );
}

export default Exits;