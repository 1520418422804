import React, {useEffect, useState} from 'react'
import './product.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTrash, faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import { parseCookies } from 'nookies'
import { createProduct, getProductOrganization, deleteProduct, updateProduct } from '../../Controllers/product'
import toast, { Toaster } from 'react-hot-toast';
import Loader  from '../../Components/Loader'
import { redirectLogin } from '../../Functions';
import moment from 'moment'
import Offcanvas from 'react-bootstrap/Offcanvas';

function Product() {
    const cookies = parseCookies()
    const token = cookies.token
    let [products, setProducts] = useState([])
    const [nameProduct, setNameProduct] = useState()
    const [valueProduct, setValueProduct] = useState()
    const [descriptionProduct, setDescriptionProduct] = useState()
    const [durationProduct, setDurationProduct] = useState()
    const [durationProductMinutes, setDurationProductMinutes] = useState()
    const [stateChange, setStateChange] = useState('');
    const [editOn, setEditOn] = useState(false);
    const [id, setId] = useState();
    const [loading, setLoading] = useState(true)
    const [fileName, setFileName] = useState('Nenhum arquivo selecionado');
    const [show, setShow] = useState(false);
    const [time, setTime] = useState();
    const organizationId = window.localStorage.getItem('organization')

    products = products?.filter(
		(f) => f.name.toLowerCase().indexOf(stateChange.toLowerCase()) > -1
	);

    async function search(event) {
		setStateChange(event.target.value);
	}

    async function clearInputs(product) {
        setNameProduct('')
        setDescriptionProduct('')
        setDurationProductMinutes('')
        setValueProduct('')
        setDurationProduct('')
        setEditOn('')
        setId('')
        // setShow(true)
        setShow(false)
        setEditOn(false)
    } 

    async function getProductsOrganization() {
        await getProductOrganization(token, organizationId, setProducts)
          .then((res) => {
            setLoading(false)
          })
          .catch((err) => {
            console.log('erro ao se registrar', err)
          })
    }

    useEffect(() => {
        if(token === undefined) {
            redirectLogin()
          }
        getProductsOrganization()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    function getExtensionFromFileName(fileName) {
        return '.' + fileName.split('.').pop();
    }

    function convertImageToBase64(file) {
        if (file !== undefined) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
        
                reader.onload = () => {
                    const base64 = reader.result.split(',')[1];
                    const extension = getExtensionFromFileName(file.name);
                    resolve({ base64, extension });
                };
        
                reader.onerror = (error) => {
                    reject(error);
                };
        
                reader.readAsDataURL(file);
            });
        } else {
            return { base64: '', extension: '' }
        }
    }
    
    async function registerProduct() {
        let durationConvert 

        if(Number(durationProduct)){
            durationConvert = Number(durationProduct) * 60
        }

        const imageFile = document.querySelector('input[type="file"]').files[0];
        const { base64, extension } = await convertImageToBase64(imageFile);

        const body = {
            name: nameProduct,
            description: descriptionProduct,
            duration: durationProduct ? durationConvert : durationProductMinutes,
            amount: 0,
            amountSale: 0,
            salePrice: Number(valueProduct),
            organizationId: organizationId,
            imageBase64: imageFile ? base64 : '',
            imageExt: imageFile ? extension : ''
        }

        await createProduct(body, token)
            .then((res) => {
                getProductsOrganization()
                clearInputs()
                toast.success('Procedimento cadastrado com sucesso ', {icon: '❤'})
            })
            .catch((err) => {
                console.log('erro ao registrar procedimento: ', err)
                toast.error('Erro ao registrar procedimento ')
            })
    }

    
    async function updateInputs(product) {
        setNameProduct(product.name)
        setDescriptionProduct(product.description)
        setValueProduct(product.salePrice)
        // setDurationProduct(product.duration)
        setTime(product.duration)
        // setDurationProductMinutes(product.duration)
        setEditOn(true)
        setId(product._id)
        setShow(true)
    } 

    async function productUpdate() {
        let durationConvert 

        if(Number(durationProduct)){
            durationConvert = Number(durationProduct) * 60
        }

        const body = {
            name: nameProduct,
            description: descriptionProduct,
            duration: durationProduct ? durationConvert : durationProductMinutes,
            amount: 0,
            amountSale: 0,
            salePrice: valueProduct
        }

        await updateProduct(token, id, body)
            .then((res) => {
                clearInputs()
                setEditOn(false)
                toast.success('Procedimento atualizado com sucesso ')
                getProductsOrganization()
            })
            .catch((err) => {
                console.log('erro ao registrar procedimento: ', err)
                toast.error('Erro ao atualizar procedimento ')
            })

    }

    async function productDelete(productId) {

        await deleteProduct(token, productId)
            .then((res) => {
                getProductsOrganization()
                toast.success('Procedimento deletado com sucesso ')
            })
            .catch((err) => {
                console.log('erro ao registrar procedimento: ', err)
                toast.error('Erro ao deletar procedimento')
            })
    }

    const onChangeDurationMinutes = (value) => {
        if(durationProduct) {
            toast.error('Você só pode escolher minutos ou horas. Não pode selecionar os 2')
        } else {
            setDurationProductMinutes(value)
        }
    }

    const onChangeDurationHours = (value) => {
        if(durationProductMinutes) {
            setDurationProductMinutes('')
            toast.error('Você só pode escolher minutos ou horas. Não pode selecionar os 2')
        }else {
            setDurationProductMinutes('')
            setDurationProduct(value)
        }
    }

    const handleFileChange = (event) => {
        const fileInput = event.target;
        if (fileInput.files.length > 0) {
        const fileName = fileInput.files[0].name;
        setFileName(fileName);
        } else {
        setFileName('Nenhum arquivo selecionado');
        }
    };

  return (
    <div className='container-register'>
        <Toaster position="bottom-center" />
        <div className='content-register'>
            <div className='container-button-show'>
                <button 
                    onClick={() => setShow(true)} 
                    className='button-show-style-register'
                    id='add-scheduling'
                > 
                    + Cadastrar Procedimento
                </button>
            </div>

            <div>
                <div className='titles'>
                    <p>Lista de procedimentos</p>
                </div>
                <div className='container-search'>
                    <input onChange={(e) => search(e)} type='text' placeholder='Pesquisar por nome do procedimento' className='input-style-register' />
                </div>       
            </div>
            <div className='container-product-list'>
            {loading ? (
                <>
                    <div className='container-loading'>
                        <Loader />
                    </div>
                </>
            ) :(
                <>
                    {products.length <= 0 ? (
                        <div className='container-not-sales'>
                            <p className='text-not-sales'>Não existe nenhum procedimento registrado. <br/>
                            Aproveite e registre um agora e comece suas vendas 😎
                            </p>
                        </div>
                    ):(
                        <div className='table-container'>
                            <Table striped>
                                <thead>
                                    <tr>
                                        <th>Procedimento</th>
                                        <th>Descrição</th>
                                        <th>Duração</th>
                                        <th>Valor</th>
                                        <th>Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {products.map((product) => {
                                        return(
                                            <>
                                                <tr className={editOn && product._id === id ? 'container-inputs-table-responsive' : ''}>
                                                    <td>
                                                        { product.name }
                                                    </td>
                                                    <td>
                                                        { product.description }
                                                    </td>
                                                    <td className='container-duration'>
                                                        { 
                                                            product.duration < 60 ? moment.duration(product.duration, 'minutes').minutes() + ' Min' 
                                                                : 
                                                            moment.duration(product.duration, 'minutes').hours() + ' Horas' 
                                                        }
                                                    </td>
                                                    
                                                    <td>
                                                        <div className='container-update-items'>
                                                            { 
                                                                product.salePrice.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                                                            }
                                                        </div>
                                                        
                                                    </td>
                                                    <td>
                                                        <div className='container-icons-table-product'>
                                                            <div onClick={() => updateInputs(product)}>
                                                                <FontAwesomeIcon 
                                                                    icon={faPenToSquare} 
                                                                    style={{ 
                                                                        color: 'rgba(249,141,1, 0.9)',
                                                                        display: editOn && product._id === id ? 'none': ''
                                                                    }} />
                                                            </div>
                                                            <div onClick={() => productDelete(product._id)}>
                                                                <FontAwesomeIcon  
                                                                    icon={faTrash} 
                                                                    style={{ 
                                                                        color: '#f76459',
                                                                        display: editOn && product._id === id ? 'none': ''
                                                                    }} />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    )}
                </>
            )}
           </div>
        </div>
        <Offcanvas show={show} placement='end' onHide={() =>  clearInputs()}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Registrar Procedimento</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
            <div className='container-content'>
                <div className=''>
                    <p>Nome do Procedimento*</p>
                    <input 
                        onChange={(e) => setNameProduct(e.target.value)} 
                        value={editOn? nameProduct : nameProduct}
                        type='text' 
                        placeholder='Ex: Design com Henna' 
                        className='input-style-register' 
                    />
                    <p>Valor de venda*</p>
                    <input 
                        onChange={(e) => setValueProduct(e.target.value)} 
                        value={editOn ? valueProduct : valueProduct}
                        type='number' 
                        placeholder='Ex: 200' 
                        className='input-style-register' 
                    />

                    <p>Descrição*</p>
                    <input 
                        onChange={(e) => setDescriptionProduct(e.target.value)} 
                        value={editOn ? descriptionProduct : descriptionProduct}
                        type='text' 
                        placeholder='Ex: Design de sobrancelha simples sem aplicação de henna' 
                        className='input-style-register' 
                    />

                    <div>
                        <p>Duração* 
                        <p className='info-hours-and-minutes'>(Se a duração for em minutos, digite no primeiro campo, se for em horas digite no segundo campo)</p></p>
                        <input 
                            onChange={(e) => onChangeDurationMinutes(e.target.value)} 
                            defaultValue={editOn && time < 60 ? time : durationProductMinutes}
                            type='number' 
                            placeholder='Se a duração for em minutos, digite aqui. Ex: 30 (30 minutos)'
                            className='input-style-register' 
                        />

                        <input 
                            onChange={(e) => onChangeDurationHours(e.target.value)} 
                            defaultValue={editOn && time >= 60 ? time / 60 : durationProduct}
                            type='number' 
                            placeholder='Se a duração for em horas, digite aqui. Ex: 2 (2 horas)'
                            className='input-style-register' 
                        />
                    </div>
                    <div>
                        <p>Imagem do produto</p>
                        <div class="custom-file-input">
                            <label className='label-file-input' for="file-input">Escolher Imagem</label>
                            <span className='icon-file-upload'>
                                <FontAwesomeIcon icon={faCloudArrowUp}  />
                            </span>
                            <input onChange={handleFileChange} type="file" id="file-input" name="file-input" />
                            <span class="file-name">{fileName}</span>
                        </div>
                    </div>
                    <div className='container-button-register'>
                        <button onClick={editOn ? productUpdate : registerProduct} className='button-style-register'>{editOn ? 'Atualizar' : 'Cadastrar'}</button>
                    </div>
                </div>
                
            </div>
            </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default Product;