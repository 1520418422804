import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

function Loader({ props, size }) {
  
    return (
      <div>
        <Spinner size={size} style={{ color: '#2D1674', }} Spinner animation="border" />
      </div>
    );
  }
  
  export default Loader;