import axios from 'axios'

export async function createScheduling(body, token) {
  
    const data = await axios.post(`${process.env.REACT_APP_API_LOCAL}/scheduling`, body, {
      headers: {
        'x-api-key': process.env.REACT_APP_XAPIKEY,
        Authorization: 'Bearer ' + token,
        'content-Type': 'application/json'
      }
    })
    .catch(err => {
        console.error('createScheduling:', err.message)
        throw new Error("Erro ao criar Agendamento");
    })

    return data
}

export async function getScheduling(token, setState) {
  
  const data = await axios.get(`${process.env.REACT_APP_API_LOCAL}/scheduling/user`, {
    headers: {
      'x-api-key': process.env.REACT_APP_XAPIKEY,
      Authorization: 'Bearer ' + token,
    }
  })
  .then((res) => {
    setState(res.data.scheduling[0])
    return res.data
  })    
  .catch(err => {
      console.error('createScheduling:', err.message)
      throw new Error("Erro ao criar Agendamento");
  })

  return data
}

export async function getSchedulingOrganization(token, organizationId, setState) {
  
  const data = await axios.get(`${process.env.REACT_APP_API_LOCAL}/scheduling/organization/${organizationId}`, {
    headers: {
      'x-api-key': process.env.REACT_APP_XAPIKEY,
      Authorization: 'Bearer ' + token,
    }
  })
  .then((res) => {
      setState(res.data.scheduling[0])
      return res.data
  })
  .catch(err => {
      console.error('getSchedulingOrganization:', err.message)
      throw new Error("Erro ao listar Agendamento");
  })

  return data
}

export async function getScheduleOrganization(token, organizationId, setState) {
  
  const data = await axios.get(`${process.env.REACT_APP_API_LOCAL}/schedule/${organizationId}`, {
    headers: {
      'x-api-key': process.env.REACT_APP_XAPIKEY,
      Authorization: 'Bearer ' + token,
    }
  })
  .then((res) => {
      setState(res.data.result)
      return res.data
  })
  .catch(err => {
      console.error('getSchedulingOrganization:', err.message)
      throw new Error("Erro ao listar Agendamento");
  })

  return data
}

export async function updateScheduling(token, scheduleId, body) {
  
  const data = await axios.put(`${process.env.REACT_APP_API_LOCAL}/scheduling/${scheduleId}`, body, {
    headers: {
      'x-api-key': process.env.REACT_APP_XAPIKEY,
      Authorization: 'Bearer ' + token,
      'content-Type': 'application/json'
    }
  })
  .catch(err => {
      console.error('updateScheduling:', err.message)
      throw new Error("Erro ao atualizar agendamento");
  })

  return data
}

export async function blockScheduling(token, body) {
  
  const data = await axios.post(`${process.env.REACT_APP_API_LOCAL}/schedule/block`, body, {
    headers: {
      'x-api-key': process.env.REACT_APP_XAPIKEY,
      Authorization: 'Bearer ' + token,
      'content-Type': 'application/json'
    }
  })
  .catch(err => {
      console.error('blockScheduling:', err.message)
      throw new Error("Erro ao bloquear agendamento");
  })

  return data
}

export async function unlockScheduling(token, body) {
  
  const data = await axios.post(`${process.env.REACT_APP_API_LOCAL}/schedule/unlock`, body, {
    headers: {
      'x-api-key': process.env.REACT_APP_XAPIKEY,
      Authorization: 'Bearer ' + token,
      'content-Type': 'application/json'
    }
  })
  .catch(err => {
      console.error('blockScheduling:', err.message)
      throw new Error("Erro ao bloquear agendamento");
  })

  return data
}

export async function getScheduleUnlock(token, organizationId, setState) {
  
  const data = await axios.get(`${process.env.REACT_APP_API_LOCAL}/schedule/unlock/${organizationId}`, {
    headers: {
      'x-api-key': process.env.REACT_APP_XAPIKEY,
      Authorization: 'Bearer ' + token,
    }
  })
  .then((res) => {
      setState(res.data)
      return res.data
  })
  .catch(err => {
      console.error('getSchedulingOrganization:', err.message)
      throw new Error("Erro ao listar Agendamento");
  })

  return data
}