import React from "react";
import { Routes, Route, BrowserRouter, Navigate  } from "react-router-dom";
import Login from '../Pages/Login'
import Signup from '../Pages/Signup'
import Home from '../Pages/Home'
import Product from '../Pages/Products';
import Sale from '../Pages/Sales'
import Cart from '../Pages/Cart'
import Controllers from "../Pages/Controllers/Index";
import Entrance from "../Pages/Entrances";
import Exits from "../Pages/Exits";
import Details from "../Pages/Details";
import Reset from "../Pages/Reset";
import ProductsAnalysis from "../Pages/ProductsAnalysis"
import SalesAnalysis from "../Pages/SalesAnalysis";
import Calendar from "../Pages/Calendar"
import Scheduling from "../Pages/Scheduling"
import Payment from "../Pages/Payment";
import Plan from "../Pages/Plan";
import Profile from "../Pages/Profile";
import Personalize from "../Pages/Personalize";
import Marketing from "../Pages/Marketing";


const planController = window.localStorage.getItem('plan')
export default function Router() {
    return (
        <BrowserRouter>
          <Routes>
              <Route exact path="/" element={<Login />} />
              <Route exact path="/signup" element={<Signup />} />
              <Route exact path="/reset" element={<Reset />} />
              <Route exact path="/home" element={<Home />} />
              <Route exact path="/register" element={<Product />} />
              <Route exact path="/sales" element={<Sale />} />
              <Route exact path="/cart" element={<Cart />} />
              <Route exact path="/controllers" element={<Controllers />} />
              <Route exact path="/entrance" element={<Entrance />} />
              <Route exact path="/exits" element={<Exits />} />
              <Route exact path="/details" element={<Details />} />
              <Route exact path="/analysis" element={<ProductsAnalysis />} />
              <Route exact path="/analysis-month" element={<SalesAnalysis />} />
              <Route exact path="/calendar" element={planController === process.env.REACT_APP_PLAN_FINANCE ? <Navigate to='/home' /> : <Calendar />} />
              <Route exact path="/agendamento" element={<Scheduling />} />
              <Route exact path="/payment" element={<Payment />} />
              <Route exact path="/plan" element={<Plan />} />
              <Route exact path="/profile" element={<Profile />} />
              <Route exact path="/personalize" element={<Personalize />} />
              <Route exact path="/marketing" element={<Marketing />} />
          </Routes>
        </BrowserRouter>
    );
  }