import React, { useEffect } from 'react'
import './controllers.css'
import Card from '../../Components/Cards';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDollarSign, faSackXmark, faMoneyBillTrendUp } from '@fortawesome/free-solid-svg-icons';
import { parseCookies } from 'nookies'
import { redirectLogin } from '../../Functions';

function Controllers() {
    const cookies = parseCookies()
    const token = cookies.token

    useEffect(() => {
        if(token === undefined){
            redirectLogin()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    const goToEntrance = () => {
        window.location.href = '/entrance'
    }

    const goToExits = () => {
        window.location.href = '/exits'
    }

    const goToDetails = () => {
        window.location.href = '/details'
    }

  return (
    <div className='container-controllers'>
        <div className='container-card-controllers'>
            <Card
                text='Entrada' 
                style={{ 
                background: 'linear-gradient(163deg, rgba(89,251,76,1) 0%, rgba(91,255,247,1) 100%)', 
                fontSize: '20px',
                }} 
                onClick={goToEntrance}
                icon={<FontAwesomeIcon icon={faDollarSign} />}
            />

            <Card
                text='Saida' 
                style={{ 
                background: 'linear-gradient(163deg, rgba(251,76,76,1) 0%, rgba(255,165,91,1) 100%)', 
                fontSize: '20px',
                }} 
                onClick={goToExits}
                icon={<FontAwesomeIcon icon={faSackXmark} />}
            />

            <Card
                text='Detalhes' 
                style={{ 
                background: 'linear-gradient(163deg, rgba(251,249,76,1) 0%, rgba(255,209,91,1) 100%)', 
                fontSize: '20px',
                }} 
                onClick={goToDetails}
                icon={<FontAwesomeIcon icon={faMoneyBillTrendUp} />}
            />
        </div>
    </div>
  );
}

export default Controllers;