import './App.css';
import Router from './Router'
import Menu from './Components/Menu';
import React from 'react';
import { ProSidebarProvider } from 'react-pro-sidebar';
import UserMenu from './Components/UserMenu';
import Notification from './Components/Notification';
import Tutorial from './Components/Tutorial';

function App() {
  const menuController = JSON.parse(window.localStorage.getItem('cmenu'));
  return (
    <ProSidebarProvider>
      <div style={{display: 'grid', gridTemplateColumns: menuController === true ? '20% 80%' : '100%'}}>
        <div>
          {menuController === true && (
            <>
              <Menu />
            </>
          )}
        </div>

        <div>
        {menuController === true && (
            <>
              <div className='container-menu-user-notification'>
                <Tutorial />
                <Notification />
                <UserMenu />
              </div>
            </>
          )}
            <Router />
        </div>
      </div>
    </ProSidebarProvider>

  );
}

export default App;
