import React, { useEffect } from 'react';
import Card from '../../Components/Cards';
import './home.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus, faBagShopping, faChartSimple, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { redirectLogin } from '../../Functions';
import { parseCookies } from 'nookies'

function Home() {
  const cookies = parseCookies()
  const token = cookies.token

    useEffect(() => {
      if(token === undefined) {
        redirectLogin()
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const goToRegister = () => {
        window.location.href = '/register'
    }

    const goToCart = () => {
      window.location.href = '/cart'
    }

  const goToSales = () => {
    window.location.href = '/sales'
  }

  const goToDetails = () => {
    window.location.href = '/details'
  }

  return (
    <>
      <div style={{ display: 'flex', width: '100%'}}>
        <div className='container-card-home'>
          <Card 
            text='Cadastrar Procedimento' 
            style={{ 
              background: 'linear-gradient(163deg, rgba(0,109,238,1) 0%, rgba(86,195,255,1) 100%', 
              fontSize: '18px',
            }} 
            onClick={goToRegister}
            icon={<FontAwesomeIcon icon={faCirclePlus} />}
          />
          <Card 
            text='Realizar Venda' 
            style={{background: 'linear-gradient(163deg, rgba(112,251,113,1) 0%, rgba(86,255,250,1) 100%)', fontSize: '18px'}} 
            onClick={goToCart}
            icon={<FontAwesomeIcon icon={faBagShopping} />}
          />
          <Card 
            text='Vendas' 
            style={{background: 'linear-gradient(163deg, rgba(243,76,251,1) 0%, rgba(151,86,255,1) 100%)', fontSize: '18px'}} 
            onClick={goToSales}
            icon={<FontAwesomeIcon icon={faDollarSign} />}
          />
          <Card 
            text='Detalhes' 
            style={{background: 'linear-gradient(163deg, rgba(251,155,76,1) 0%, rgba(255,242,91,1) 100%)', fontSize: '18px'}} 
            onClick={goToDetails}
            icon={<FontAwesomeIcon icon={faChartSimple} />}
          />
        </div>
      </div>
    </>
  );
}

export default Home;