import React, { useState, useEffect } from 'react'
import './sale.css'
import {  getSalesByOrganization, deleteSale } from '../../Controllers/sale'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { parseCookies } from 'nookies'
import Moment from 'moment'
import { sum } from 'mathjs'
import Loader  from '../../Components/Loader'
import { redirectLogin } from '../../Functions'
// import { CSVLink } from "react-csv";
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Tooltip from 'react-bootstrap/Tooltip';
import toast, { Toaster } from 'react-hot-toast';
import { Table } from 'react-bootstrap';
import ButtonMore  from '../../Components/ButtonMore'

export function convertDateToISOFormat(date, hour) {
	const time = date + ' ' + hour;
	return new Date(time.replace(/\s/g, 'T') + 'Z').toISOString();
}

function Sale() {
    const [sales, setSales] = useState()
    // const [allSales, setAllSales] = useState()
    const [startDate, setStartDate] = useState(Moment().subtract('7', 'days').format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(Moment().format('YYYY-MM-DD'))
    const initialTime = '00:00'
	const finalTime = '23:59'
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1)
    const [loadingButton, setLoadingButton] = useState(false)
    const [resume, setResume] = useState()

    const cookies = parseCookies()
    const token = cookies.token
    const organizationId = window.localStorage.getItem('organization')

    async function getSalesOrganization() {
        const start = convertDateToISOFormat(startDate, initialTime)
        const end = convertDateToISOFormat(endDate, finalTime)
        await getSalesByOrganization(token, organizationId, start, end, setSales, setResume, 0)
          .then((res) => {
            setLoading(false)
          })
          .catch((err) => {
            console.log('erro ao se registrar', err)
          })
    }

    async function getMoreSales() {
        setLoadingButton(true)
        const start = convertDateToISOFormat(startDate, initialTime)
        const end = convertDateToISOFormat(endDate, finalTime)
        const data = await getSalesByOrganization(token, organizationId, start, end, () => { }, () => { }, page)
          .catch((err) => {
            console.log('erro ao se registrar', err)
          })
        
        if(!data) {
        } else {
            setSales([...sales, ...data.sales])
            setPage(page + 1)
            setLoadingButton(false)
        }
    }

    async function getSalesFilter() {
        const start = convertDateToISOFormat(startDate, initialTime)
        const end = convertDateToISOFormat(endDate, finalTime)
        setLoading(true)
        await getSalesByOrganization(token, organizationId, start, end, setSales, setResume, 0)
          .then((res) => {
            setLoading(false)
          })
          .catch((err) => {
            console.log('erro ao se registrar', err)
          })
    }

    async function getSalesFilterDate(day) {

        const startFilterSelect = Moment().subtract(day, 'days').format('YYYY-MM-DD')
        const endFilterSelect = Moment().format('YYYY-MM-DD')
        setStartDate(startFilterSelect)
        setEndDate(endFilterSelect)
        const start = convertDateToISOFormat(startFilterSelect, initialTime)
        const end = convertDateToISOFormat(endFilterSelect, finalTime)
        setLoading(true)
        await getSalesByOrganization(token, organizationId, start, end, setSales, setResume, 0)
          .then((res) => {
            setLoading(false)
          })
          .catch((err) => {
            console.log('erro ao se registrar', err)
          })
    }

    // async function downloadHistorySales() {
    //     await getSalesByOrganization(token, organizationId, '', '', setSales)
    //       .then((res) => {
            
    //       })
    //       .catch((err) => {
    //         console.log('erro ao se registrar', err)
    //       })
    // }

    const saleDelete = async(saleId) => {
        await deleteSale(token, saleId)
          .then((res) => {
            getSalesOrganization()
            toast.success('Venda deletada com sucesso ')
          })
          .catch((err) => {
            console.log('erro ao se registrar', err)
          })
    }


    useEffect(() => {
        if(token === undefined) {
            redirectLogin()
        }
        getSalesOrganization()
        // downloadHistorySales()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    // const dataSale = allSales?.map((e) => { 
    //     return(
    //         {
    //             createdAt: Moment(e.createdAt).format('DD/MM/YY - HH:mm'),
    //             descount: e.descount,
    //             products: e.products.map((e) =>  e.name.toString()),
    //             total: e.total
    //         }
    //     )
    // })

    // const headersSales = [
    //     { label: 'Data', key: 'createdAt' },
    //     { label: 'Procedimentos', key: 'products' },
    //     { label: 'Desconto', key: 'descount' },
    //     { label: 'Total', key: 'total' }
    // ]

    const goToCart = () => {
        window.location.href = '/cart'
    }

  return (
    <div className='container-sales'>
        <Toaster position="bottom-center" />
        <div className='content-sales'>
            <div className='container-total-value'>
                <p className='titles'>Vendas</p>
                <div className='container-total-value-sales'>
                    <div>
                        <p className='total-sales-page'>Vendas: { resume?.total ? resume?.total : 0}</p>
                    </div>
                    <div>
                        <p className='total-values'>Total: {sum(resume?.totalValue).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                    </div>
                    {/* <div onClick={downloadHistorySales}>
                        <OverlayTrigger placement='bottom' overlay={<Tooltip id="">Clique para baixar todo histórico de vendas</Tooltip>}>
                            <CSVLink 
                                data={dataSale ? dataSale : ''} 
                                filename={'vendas.csv'} 
                                headers={headersSales} 
                                separator={';'}
                            >
                                <div className='container-icon-download'>
                                    <FontAwesomeIcon style={{ color: 'white'}} icon={faFileArrowDown} />
                                </div>
                            </CSVLink>
                        </OverlayTrigger>
                    </div> */}
                </div>
            </div>

            <div className='container-search-sales'>
                <div className='container-inputs-sales-page'>
                    <select 
                        className='input-style-sales'
                        onChange={(e) => getSalesFilterDate(e.target.value)}
                        defaultValue='7'
                    >
                        <option value='7'>1 semana</option>
                        <option value='30'>30 dias</option>
                        <option value='60'>60 dias</option>
                    </select>
                </div>
                
                <div className='container-inputs-sales-page'>
                    <input 
                        type='date' 
                        placeholder='Ex: Design com Henna' 
                        className='input-style-sales'
                        onChange={(e) => setStartDate(e.target.value)}
                        max={Moment().format('YYYY-MM-DD')}
                        min={Moment().subtract(90, 'days').format('YYYY-MM-DD')}
                    />
                </div>

                <div className='container-inputs-sales-page'>
                    <input 
                        type='date' 
                        placeholder='Ex: Design com Henna' 
                        className='input-style-sales' 
                        onChange={(e) => setEndDate(e.target.value)}
                        max={Moment().format('YYYY-MM-DD')}
                        min={Moment().subtract(90, 'days').format('YYYY-MM-DD')}
                    />
                </div>

                <div className='container-button-sales'>
                    <button onClick={getSalesFilter} className='button-style-sales'>Pesquisar</button>
                </div>
            </div>

            {loading ? (
                <>
                    <div className='container-loading-sale'>
                        <Loader />
                    </div>
                </>
            ):(
                <>  
                    {sales.length <= 0 ? (
                        <>
                            <div className='container-not-sales'>
                                <p className='text-not-sales'>Ainda não existem vendas realizadas nesse período 😔. <br/>
                                Não fique triste, faça uma venda <span onClick={goToCart} className='text-link-cart'>aqui</span>
                                </p>
                            </div>
                        </>
                    ):(
                        <>
                            <div className='container-table-sales'>
                                <Table striped>
                                    <thead>
                                        <tr>
                                            <th>Data</th>
                                            <th>Valor</th>
                                            <th>Procedimentos</th>
                                            <th>Pagamento</th>
                                            <th>Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody className='container-tbody'>
                                        {sales.map((sale) => (
                                            <tr>
                                                <td>{Moment(sale?.createdAt).add(3, 'hours').format('DD/MM/YY - HH:mm')}</td>
                                            
                                                <td>
                                                    <p className='table-total-sale'>
                                                        {sale.total?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                                                    </p>
                                                </td>
                                                <td>
                                                    {sale.products.map((product) => (
                                                        <>
                                                            {product.name + ', '}
                                                        </>
                                                    ))}
                                                </td>
                                                <td>
                                                    <div className='container-payment'>
                                                    <p className='container-paymentMethod'>{sale.paymentMethod}</p>
                                                    </div>
                                                </td>
                                                <td onClick={() => saleDelete(sale._id)}>
                                                    <FontAwesomeIcon style={{ color: 'red', fontSize: '12px', cursor: 'pointer'}} icon={faTrashCan} />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                {page < resume?.maxpage && (
                                    <>
                                        <ButtonMore 
                                            onClick={getMoreSales} 
                                            title={loadingButton ? <Loader size='sm' /> : 'Carregar Mais +'}
                                        />
                                    </>
                                )}
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    </div>
  );
}

export default Sale;