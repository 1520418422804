import React, { useState, useEffect } from 'react'
import './scheduling.css'
import { 
  createCalendar, 
  createClient,
  getScheduleOrg,
  getScheduleOrganization
} from '../../Controllers/external'
import { organizationById } from '../../Controllers/organization'
import { getPersonalize } from '../../Controllers/personalize'
import { parseCookies } from 'nookies'
import moment from 'moment'
import { sum } from 'mathjs'
import toast, { Toaster } from 'react-hot-toast';
import Header from '../../Components/Header'
import Loader  from '../../Components/Loader'
import { Logout } from '../../Functions';

export function convertDateToISOFormat(date, hour) {
	const time = date + ' ' + hour;
	return new Date(time.replace(/\s/g, 'T') + 'Z').toISOString();
}

function Scheduling() {
  const [scheduleOrganization, setScheduleOrganization] = React.useState([]);
  const [schedule, setSchedule] = React.useState([]);
  const [selectSchedule, setSelectSchedule] = React.useState([]);
  const [clickedSchedule, setClickedSchedule] = React.useState([]);
  const [selectHour, setSelectHour] = React.useState([]);
  const [selectProducts, setSelectProducts] = React.useState([]);
  const [nameUser, setNameUser] = useState('')
  const [phoneUser, setPhoneUser] = useState('')
  const [emailUser, setEmailUser] = useState('')
  const [selectedItems, setSelectedItems] = useState([]);

  const [renderHours, setRenderHours] = useState(false)
  const [renderProducts, setRenderProducts] = useState(false)
  const [renderButton, setRenderButton] = useState(false)
  const [renderResume, setRenderResume] = useState(false)
  const [colorProducts, setColorProducts] = useState(false)
  const [congrats, setCongrats] = useState(false)
  const [conclused, setConclused] = useState(false)
  const [selectedDate, setSelectedDate] = useState(null);
  const [customizer, setCustomizer] = useState([])

  const [loader, setLoader] = useState(false)
  const [blockedFinish, setBlockedFinish] = useState(false)
  const [loaderButton, setLoaderButton] = useState(false)
  const [organization, setOrganization] = useState([])
  const [activeOrg, setActiveOrg] = useState(false)

  const  userId  = window.location.search.split("=")[1].split('&')[0]

  const cookies = parseCookies()
  const token = cookies.token

  const filteredDate = Object.keys(schedule).find((date) => {
    if(date === selectedDate) {
      return schedule
    }
  })

  const calcTotalSale = selectProducts.map((sale) => sale.salePrice)
  const totalSale = sum(calcTotalSale)

  async function getOrganizationById() {
    await organizationById(userId, setOrganization)
    .then((data) => {
      if(data.active === false) {
        setActiveOrg(true)
        toast.error('Tivemos um problema! Entre em contato com o estabelecimento')
      }
    })
    .catch((err) => {
      console.log('erro retornar organização', err)
    })
  }

  

  async function personalize() {
      await getPersonalize(token, userId)
      .then((res) => {
        setCustomizer(res.customize[0])
      })
    }

  async function updateStyles() {
    if(customizer !== undefined) {
      document.documentElement.style.setProperty('--main-color', customizer.main);
      document.documentElement.style.setProperty('--title-color', customizer.title);
      document.documentElement.style.setProperty('--time-color', customizer.duration);
      document.documentElement.style.setProperty('--button-color', customizer.button);
    } else {
      document.documentElement.style.setProperty('--main-color', '#2d1674');
      document.documentElement.style.setProperty('--title-color', '#ffffff');
      document.documentElement.style.setProperty('--time-color', '#2d1674');
      document.documentElement.style.setProperty('--button-color', '#794cff');
    }
  }

  async function getScheduleOrga() {
    // setLoader(true) 
    await getScheduleOrg(userId)
      .then((res) => {
        setScheduleOrganization(res)
        // setTimeout(() => {setLoader(false)}, 3000)
      })
      .catch((err) => {
        console.log('erro ao se registrar', err)
      })
  }

  async function getScheduleTeste() {
    setLoader(true)
    await getScheduleOrganization(userId, setSchedule)
      .then(() => {
        setTimeout(() => {setLoader(false)}, 1000)
      })
      .catch((err) => {
        console.log('erro ao se registrar', err)
      })
  }

  useEffect(() => {
    window.localStorage.setItem("cmenu", false)
    getScheduleOrga()
    getScheduleTeste()
    getOrganizationById();
    // personalize()
    // setTimeout(() => {updateStyles()}, 3000)
  },[customizer])

  async function clickSchedule(data, schedule) {
    setSelectedDate(data)
    setSelectSchedule(schedule)
    setRenderHours(true)
  }
  
  async function clickHours(time, data, select) { 
    setClickedSchedule(select)
    setSelectHour(time)
    setRenderProducts(true)
    setColorProducts(false)
    setRenderButton(false)
    setSelectedItems([])
  }

  const registerClient = async() => {
    const body = {
      name: nameUser,
      cellPhone: phoneUser,
      email: emailUser,
      userId: userId,
      organizationId: userId
    } 
    await createClient(token,body)
    .catch((err) => {
        console.log('erro ao atualizar scheduling: ', err)
    })
  }

  
  function handleSelectItem(data) {
    const alreadySelected = selectedItems.findIndex(item => item._id === data._id);
    if (alreadySelected >= 0) {
      const filteredItems = selectedItems.filter(item => item._id !== data._id);
      setSelectedItems(filteredItems);
      setSelectProducts(filteredItems)
      if(filteredItems.length <= 0) {
        setRenderButton(false)
      }
    } else {
      const newSelectedItems = [...selectedItems, data];
      setSelectedItems(newSelectedItems);
      setSelectProducts(newSelectedItems)
      setRenderButton(true)
      setColorProducts(true)
      if(newSelectedItems.length <= 0) {
        setRenderButton(false)
      }
    }
  }

  async function verifiedHours() {
    const durationProduct = selectProducts.map((d) => d.duration)
    const formatDate = moment(filteredDate).format('YYYY-MM-DD')
    const start = convertDateToISOFormat(formatDate, selectHour)
    const end = moment(start).add(sum(durationProduct), 'minutes').toISOString() 
    const formatEndHours = moment.utc(end).format('HH:mm')
    const formatStartHours = moment.utc(start).format('HH:mm')
    
      const startLunch = scheduleOrganization?.scheduling[0]?.initialLunch
      const endLunch = scheduleOrganization?.scheduling[0]?.finalLunch
      let arrayTrue = false
      let lunch = false
      scheduleOrganization?.calendar?.forEach((date) => {
        if(moment.utc(date.start).format('YYYY-MM-DD') === filteredDate 
            && moment.utc(date.start).format('HH:mm')  < formatEndHours  
              && moment.utc(date.end).format('HH:mm') > formatStartHours
            ){
            arrayTrue = true
        }
      })

      if(startLunch < formatEndHours && endLunch > formatStartHours){
        lunch = true
      }

      let hasBlockedHours = false;
      for (const hour of selectSchedule) {
        if (hour.time >= formatStartHours && hour.time < formatStartHours && hour.blocked === false && hour.scheduling === false) {
          hasBlockedHours = true;
          break;
        }
      }
    const nameProducts = selectedItems.map((d) => d.name)
    let encontrado = false;

    nameProducts.forEach(item => {
      if (item.toLowerCase().includes('curso') && !encontrado) {
        setRenderResume(true)
      } else {
        encontrado = true;
        if(hasBlockedHours) {
          setRenderButton(false)
          setColorProducts(false)
          setRenderResume(false)
          setSelectedItems([])
          return(toast.error(`Não é possível realizar o agendamento. 
          Estaremos fora no intervalo de duração. Agende um outro horario`, 
            { 
              duration: 15000, 
              position: 'bottom-center',
              style: { 
                backgroundColor: 'red', 
                color: 'white',
                fontWeight: '600',
              },
            }
          ))
          } else {
          setRenderResume(true)
          }
  
          if(lunch) {
          setRenderButton(false)
          setColorProducts(false)
          setRenderResume(false)
          setSelectedItems([])
          return(toast.error(`Não é possível realizar o agendamento. 
          Estaremos em almoço das ${startLunch} até ${endLunch}. 
          Escolha um horário depois do almoço ou um horário mais cedo.`, 
            { 
              duration: 15000, 
              position: 'bottom-center',
              style: { 
                backgroundColor: 'red', 
                color: 'white',
                fontWeight: '600',
              },
            }
          ))
          } else {
            setRenderResume(true)
          }
  
          if(arrayTrue) {
          setRenderButton(false)
          setColorProducts(false)
          setRenderResume(false)
          setSelectedItems([])
          return(toast.error(`Não é possível realizar o agendamento. 
          Já temos pessoas agendada no intervalo de duração. Escolha um outro horário `, 
            { 
              duration: 10000, 
              position: 'bottom-center',
              style: { 
                backgroundColor: 'red', 
                color: 'white',
                fontWeight: '600',
              },
            }
          ))
          } else {
            setRenderResume(true)
          }

          if(selectedItems.length <= 0) {
          setRenderButton(false)
          setColorProducts(false)
          setRenderResume(false)
          setSelectedItems([])
          return(toast.error(`Escolha um procedimento para continuar`, 
            { 
              duration: 5000, 
              position: 'bottom-center',
              style: { 
                backgroundColor: 'red', 
                color: 'white',
                fontWeight: '600',
                height: '60px'
              },
            }
          ))
          } else {
            setRenderResume(true)
          }
      }
    });
  }

  async function realoderPage() {
    window.location.reload(true);
  }


  async function finishScheduling() {
    // setConclused(true)
    setBlockedFinish(true)
    setLoaderButton(true)
    const nameProduct = selectProducts.map((product) => product.name)
    const durationProduct = selectProducts.map((d) => d.duration)

    const formatDate = moment(clickedSchedule.day).format('YYYY-MM-DD')
    
    const start = convertDateToISOFormat(formatDate, selectHour)
    const end = moment(start).add(sum(durationProduct), 'minutes').toISOString() 
    const intervaloProduct = selectedItems.map((d) => d.duration)
    var somaIntervalos = intervaloProduct.reduce(function(soma, i) {
      return soma + i;
    });
    const calendar = {
      title: nameUser + ' - ' + phoneUser + ' - ' + String(nameProduct),
      start: start,
      end: end,
      userId: userId,
      organizationId: userId
    }

    const notification  = {
      title: "Agendamento 💜",
      message: `${nameUser} agendou ${String(nameProduct)} as ${selectHour + 'hrs'} no dia ${moment(clickedSchedule.day).format('DD/MM/YYYY')}`,
      reader: false,
      organizationId: userId
    }

    clickedSchedule.scheduling = true
    const scheduleFinal = {
      calendar,
      notification,
      intervalo: somaIntervalos,
      initialTime: clickedSchedule.time,
      clickedSchedule,
      organizationId: userId,
      clientName: nameUser,
      phone: scheduleOrganization?.scheduling[0]?.phone,
      clientPhone: phoneUser
    }

    if(nameUser === ''){
      toast.error(`Digite seu nome para continuar`, 
        { 
          duration: 5000, 
          position: 'bottom-center',
          style: { 
            backgroundColor: 'red', 
            color: 'white',
            fontWeight: '600',
            height: '60px'
          },
        }
      )
    } else if(phoneUser === '') {
      toast.error(`Digite seu número de celular para continuar`, 
        { 
          duration: 5000, 
          position: 'bottom-center',
          style: { 
            backgroundColor: 'red', 
            color: 'white',
            fontWeight: '600',
            height: '60px'
          },
        }
      )
    } else {
      await createCalendar(scheduleFinal, token)
      .then((res) => {
        setConclused(false)
        setCongrats(true)
        setBlockedFinish(false)
        setLoaderButton(false)
      })
      .catch((err) => {
          console.log('erro ao registrar agendamento: ', err)
          toast.error('Erro ao finalizar agendamento ')
      })
      setTimeout(() => {realoderPage()} , 20000)
    }
  }

  const notHours = selectSchedule?.every((a) => a.blocked === true || a.scheduling === true)

  return (
    <>
    <Toaster position="bottom-center" duration='4000' />
    {activeOrg ? (
      <>
        <div className='container-loading-calendar'>
          <Loader />
        </div>
      </>
    ):(
      <>
        {loader ? (
          <>
            <div className='container-loading-calendar'>
              <Loader />
            </div>
          </>
        ):(
          <>
            <Header user={scheduleOrganization.user} />
            <div className='container-schedule'>
              <>
                {congrats ? (
                <>
                  <div className='container-congrats'>
                    <p className='title-congrats'>Agendamento realizado com sucesso 💜</p>
                    <p className=''>Aqui está o resumo do seu atendimento</p>
                    <div className='card-products-schedule-finish'>
                      <div>
                        <div className='container-total-resume'>
                          <span className='titles-resume'>TOTAL: </span>
                          <span className='title-price-product'>{totalSale?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
                        </div>
                        <hr />
                        <div>
                          <p className='titles-resume'>Serviços: </p>
                          {selectProducts?.map((product) => {
                            return(
                              <>
                                <span className='title-products-resume'>{product?.name}, </span>
                              </>
                            )
                          })}
                          <hr />
                        </div>

                        <div>
                          <p className='titles-resume'>Data e Hora: </p>
                          <p className='title-products-resume'>
                            { moment(selectedDate).format('ddd') + 
                              ', ' + moment(selectedDate).format('DD/MM/YYYY') + 
                                ' ás ' + selectHour + ' hrs'
                            }
                          </p>
                        </div>
                        <hr />

                        <div>
                          <p className='titles-resume'>Endereço: </p>
                          <p className='title-products-resume'>
                            {scheduleOrganization?.scheduling[0]?.address}
                          </p>
                        </div>
                      </div>
                    </div>
                    <p className='title-emprevisto'>Em caso de imprevistos, avisar com 15 minutos de antecedência *</p>
                    <div className='container-cellphone'>
                      <a target='_blank' href={`https://wa.me/${scheduleOrganization?.scheduling[0]?.phone}?text=Ol%C3%A1%2C+gostaria+de+tirar+uma+d%C3%BAvida+referente+ao+agendamento%21`}>
                        <p className='text-cellphone'>Para entrar em contato, clique aqui</p>
                      </a>
                    </div>
                  </div>
                </>
              ):(
                <>
                  {!renderResume ? (
                    <>
                      <p className='titles'>Agendamento</p>
                      <div className='container-card-days'>
                          {Object.keys(schedule).map((date) => {
                            return(
                              <>
                                {moment(date).format('YYYY/MM/DD') < moment().format('YYYY/MM/DD') ? (
                                  <></>
                                ):(
                                  <>
                                    <div onClick={() => clickSchedule(date, schedule[date])} className={filteredDate !== date ? 'card-days' : 'card-days-selected'} >
                                      <p 
                                          className={filteredDate !== date ? 'title-day-name notranslate' : 'title-day-name-selected notranslate'}
                                      >
                                          {moment(date).format('ddd')}
                                      </p>
                                      <p 
                                          className={filteredDate !== date ? 'title-day-number' : 'title-day-number-selected'}
                                      >
                                          {moment(date).format('DD')}
                                      </p>
                                      <p 
                                          className={filteredDate !== date ? 'title-year-month' : 'title-year-month-selected'}
                                      >
                                          {moment(date).format('MMMM/YY')}
                                      </p>
                                    </div>
                                  </>
                                )}
                              </>
                            )
                        })}
                      </div>
                      
                      <div>
                        {notHours && renderHours ? (
                          <div className='container-not-hours'>
                            <p className='text-not-hour-1'>Desculpe a agenda está cheia 😪</p>
                            <p className='text-not-hour-2'>não fique triste, escolha outro dia e agende seu horario</p>
                          </div>
                        ):(
                          <>
                            <div className='container-hours'>
                              <p className='titles'>Horarios</p>
                                {renderHours === false && (
                                  <>
                                    <div className='container-message-info'>
                                      <p className='title-message-info'>Escolha uma data, para ver os horários disponíveis</p>
                                    </div>
                                  </>
                                )}
                                <div className='container-hours-card'>
                                  {selectedDate && (
                                    <>
                                      {schedule[selectedDate].map((hour) => {
                                        return(
                                          <>
                                            {hour.blocked !== true && hour.scheduling !== true && renderHours === true && (
                                              <>
                                                <div onClick={() => clickHours(hour.time, schedule[selectedDate], hour)} className={selectHour !== hour.time  ? 'card-hours' : 'card-hours-selected'}>
                                                  <p>{hour?.time}</p>
                                                </div>
                                              </>
                                            )}
                                          </>
                                        )
                                      })}
                                    </>
                                  )}
                                </div>
                            </div>

                            {'selectSchedule.hours?.length' <= 0 ? (
                                <></>
                              ):(
                                <>
                                  <div className='container-products'>
                                    <p className='titles'>Procedimentos</p>
                                    {renderProducts === false && (
                                      <>
                                        <div className='container-message-info'>
                                          <p className='title-message-info'>Escolha um horário, para ver os procedimentos disponíveis</p>
                                        </div>
                                      </>
                                    )}

                                    {renderProducts === true && (
                                      <>
                                        <div className=''>
                                          {scheduleOrganization?.products?.map((product, index) => {
                                            return(
                                              <>
                                                <div 
                                                  key={product._id}
                                                  onClick={() => handleSelectItem(product)} 
                                                  className={selectedItems.includes(product) && colorProducts ? 'card-products-schedule-selected' : 'card-products-schedule'}
                                                >
                                                  <div>
                                                    <img className='container-image-product' src={product.image ? product.image : 'https://triunfo.pe.gov.br/pm_tr430/wp-content/uploads/2018/03/sem-foto.jpg'} />
                                                  </div>
                                                  <div className='container-product-saleprice'>
                                                    <div className='container-price-name '>
                                                      <p className={selectedItems.includes(product) && colorProducts ? 'title-name-product-selected' : 'title-name-product'}> {product.name}</p>
                                                      <p className='title-price-product'>{product.salePrice.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                                                    </div>
                                                    <p className={selectedItems.includes(product) && colorProducts ? 'title-description' : ''}>{product.description}</p>
                                                    <p className={selectedItems.includes(product) && colorProducts ? 'title-duration-selected': 'title-duration'}>{"Duração: " + moment.utc(moment.duration(product.duration, 'minutes').asMilliseconds()).format('HH:mm')}</p>
                                                  </div>
                                                  
                                                </div>
                                              </>
                                            )
                                          })}
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </>
                              )}

                            <div className='container-button-scheduling'>
                              <button 
                                onClick={() => verifiedHours() } 
                                className={renderButton ? 'button-scheduling' : 'button-scheduling-blocked'}
                              >
                                Avançar
                              </button>     
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  ):(
                    <>
                      <div>
                        <div>
                          <p className='titles'>Digite seus dados pessoais</p>
                          <div className='container-data-person'>
                            <div className='box-data-person'>
                              <p className='title-data-person'>Nome Completo*</p>
                              <input 
                                  onChange={(e) => setNameUser(e.target.value)} 
                                  value={nameUser}
                                  type='text' 
                                  placeholder='Ex: Maria da silva ' 
                                  className='input-style-sche' 
                              />
                            </div>
                            <div className='box-data-person'>
                              <p className='title-data-person'>Celular*</p>
                              <input 
                                  onChange={(e) => setPhoneUser(e.target.value)} 
                                  value={phoneUser}
                                  type='number' 
                                  placeholder='Ex: (11)9 5555-5555' 
                                  className='input-style-sche' 
                              />
                            </div>

                            <div>
                              <p className='title-data-person'>E-mail</p>
                              <input 
                                  onChange={(e) => setEmailUser(e.target.value)} 
                                  value={emailUser}
                                  type='text' 
                                  placeholder='Ex: maria@gmail.com' 
                                  className='input-style-sche' 
                              />
                            </div>
                          </div>
                        </div>
                        {/* <p className='titles'>Resumo</p> */}
                        <div>
                          {/* <div className='card-products-schedule'>
                            <div >
                              <div className='container-total-resume'>
                                <span className='titles-resume'>TOTAL: </span>
                                <span className='title-price-product'>{totalSale?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
                              </div>
                              <hr />
                              <div>
                                <p className='titles-resume'>Serviços: </p>
                                {selectProducts?.map((product) => {
                                  return(
                                    <>
                                      <span className='title-products-resume'>{product?.name}, </span>
                                    </>
                                  )
                                })}
                                <hr />
                              </div>

                              <div>
                                <p className='titles-resume'>Data e Hora: </p>
                                <p className='title-products-resume'>
                                  { moment(selectSchedule.day).format('ddd') + 
                                    ', ' + moment(selectSchedule?.day).format('DD/MM/YYYY') + 
                                      ' ás ' + selectHour + ' hrs'
                                  }
                                </p>
                              </div>
                            </div>
                          </div> */}

                          {/* <p className='title-emprevisto'>Em caso de emprevistos, avisar com 15 minutos de antecedência *</p> */}
                          
                            {conclused ? (
                              <>
                                <div className='container-loading-calendar'>
                                  <Loader />
                                </div>
                              </>
                            ):(
                              <>
                                  <div className='container-button-finish'>
                                    <button 
                                      onClick={finishScheduling} disabled={blockedFinish} className={nameUser !== '' && phoneUser !== '' ? 'button-finish' : 'button-finish-blocked'}
                                    >
                                      {loaderButton ? <Loader size='sm' /> : 'Concluir Agendamento'}
                                      
                                    </button>  
                                  </div>
                                  <div>
                                    <button onClick={() => setRenderResume(false)} className='button-scheduling-return'>
                                      Voltar
                                    </button>
                                  </div>
                              
                              </>
                            )}
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
              </>
        </div>
          </>
        )}
      </>
    )}
    </>
  );
}

export default Scheduling;