import React, { useEffect, useState } from 'react';
import './calendar.css'
import { redirectLogin } from '../../Functions';
import { parseCookies } from 'nookies'
import { 
  createScheduling, 
  getScheduling, 
  getSchedulingOrganization, 
  updateScheduling,
  getScheduleOrganization,
  blockScheduling,
  unlockScheduling,
  getScheduleUnlock
} from '../../Controllers/scheduling'
import { getCalendar, getCalendarOrganization, deleteEvents } from '../../Controllers/calendar'
import { organizationById } from '../../Controllers/organization'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear, faCirclePlay, faPause } from '@fortawesome/free-solid-svg-icons';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import FullCalendar, { } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import allLocales from '@fullcalendar/core/locales-all'
import moment from 'moment'
import 'moment/locale/pt-br'
import Offcanvas from 'react-bootstrap/Offcanvas';
import toast, { Toaster } from 'react-hot-toast';
import Loader  from '../../Components/Loader'
import Modal from 'react-bootstrap/Modal';
import { TimePicker, Select, Switch, Input } from 'antd';
import Joyride from 'react-joyride';
import { StepsCalendar } from '../../Steps';
import { Logout } from '../../Functions';

export function convertDateToISOFormat(date, hour) {
	const time = date + ' ' + hour;
	return new Date(time.replace(/\s/g, 'T') + 'Z').toISOString();
}

moment.locale('pt-BR')

function CalendarPage() {
    const cookies = parseCookies()
    const token = cookies.token
    const organizationId = window.localStorage.getItem('organization')

    const [show, setShow] = useState(false);
    const [schedule, setSchedule] = useState([]);
    const [listSchedule, setListSchedule] = useState();
    const [unlock, setUnlock] = useState();
    const [datesCalendar, setDatesCalendar] = useState([]);
    let [startHour, setStartHour] = useState(0)
    let [startAttendence, setStartAttendence] = useState(0)
    let [endAttendence, setEndAttendence] = useState(0)
    const [endHour, setEndtHour] = useState(0)
    let [startHourLunch, setStartHourLunch] = useState(0)
    const [endHourLunch, setEndtHourLunch] = useState(0)
    const [days, setDays] = useState([])
    const [month, setMonth] = useState()
    const [loading, setLoading] = useState(true)
    const [open, setOpen] = React.useState(false);
    const [openConfig, setOpenConfig] = React.useState(false);
    const [blockedHour, setBlockedHour] = React.useState(true);
    const [eventId, setEventId] = React.useState();
    const [selectedHours, setSelectedHours] = useState([]);
    let scheduleCopy = schedule
    const [step, setStep] = useState(0)
    const [isZero, setIsZero] = useState(true)
    const [startTutoria, setStartTutorial] = useState(false)
    const [cellPhone, setCellPhone] = useState('')
    const [address, setAddress] = useState('')
    const [arrayBlock, setArrayBlock] = useState([])
    const [organization, setOrganization] = useState([])

    const nextStep = () => {
        if(isZero) {
            setIsZero(false)
        } else {
            setStep(step + 1)
        }

        if(step === 2){ 
          setStartTutorial(false)
          setStep(0)
          setIsZero(true)
        }
    }

    async function getOrganizationById() {
      console.log('passei na chamada ')
      await organizationById(organizationId, setOrganization)
      .then((data) => {
        console.log('data: ', data)
        if(data.active === false) {
          Logout()
        }
      })
      .catch((err) => {
        console.log('erro retornar organização', err)
      })
    }

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
      setOpen(false);
      scheduleCopy = schedule
    } 
      
    const handleCloseCanva = () => setShow(false);

    const handleCloseConfig = () => setOpenConfig(false);
    const [selectSchedule, setSelectSchedule] = React.useState([]);

    const dataAtual = moment();
    
    const filteredDate = listSchedule && Object.keys(listSchedule).find((date) => {
      return date === selectSchedule;
    });

    const filteredDateUnlock = unlock && Object.keys(unlock.schedule).find((date) => {
      return date === selectSchedule;
    });

    const startOfWeek = dataAtual.startOf(dataAtual);
    let dates = [];

    async function calendarGet() {
      setLoading(true)
      await getCalendarOrganization(token, organizationId, setDatesCalendar)
        .then((res) => {
          setLoading(false)
        })
        .catch((err) => {
          console.log('erro ao se registraar', err)
        })
    }


    useEffect(() => {
      getOrganizationById()
      calendarGet()
    },[])



const addDays = async() => {
  for (let i = 0; i < month; i++) {
    moment.locale('pt-br')
    dates.push(startOfWeek.clone().add(i, 'days').format('YYYY/MM/DD'));
  }
}


const createdDates = async() => {
  await addDays()
}

// ----------------------------------------------------------------------------------------

async function getSchedule() {
  await getSchedulingOrganization(token, organizationId, setSchedule)
    .catch((err) => {
      console.log('erro ao se registrar', err)
    })
}

async function listScheduleOrganization() {
  await getScheduleOrganization(token, organizationId, setListSchedule)
    .catch((err) => {
      console.log('erro ao se registrar', err)
    })
}

async function listUnlock() {
  await getScheduleUnlock(token, organizationId, setUnlock)
    .catch((err) => {
      console.log('erro ao se registrar', err)
    })
}

useEffect(() => {
  getSchedule()
  listUnlock()
  listScheduleOrganization()
},[])

  const data = [
    'segunda-feira',
    'terça-feira',
    'quarta-feira',
    'quinta-feira',
    'sexta-feira',
    'sábado',
    'domingo'
  ].map(item => ({ label: item, value: item }));
    

    useEffect(() => {
      if(token === undefined) {
        redirectLogin()
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const onChangeDays = (value, e) => {
      setDays(value)
    }

    const onChangeHoursInitial = (value, e) => {
      setStartHour(Number(moment(value?.$d).format('HH')))
      setStartAttendence(Number(moment(value?.$d).format('HH')))
    }

    const onChangeHoursFinal = (value, e) => {
      setEndtHour(Number(moment(value?.$d).format('HH')))
      setEndAttendence(Number(moment(value?.$d).format('HH')))
    }

    const onChangeHoursLunchInitial = (value, e) => {
      setStartHourLunch(Number(moment(value?.$d).format('HH')))
    }

    const onChangeHoursLunchFinal = (value, e) => {
      setEndtHourLunch(Number(moment(value?.$d).format('HH')))
    }

    async function realoderPage() {
      window.location.reload(true);
    }

    async function registerScheduling() {
      const finalScheduling = {
        days: days,
        startDate: dates[0],
        endDate: dates[dates.length - 1],
        start: startAttendence ? startAttendence.toString() + ':00' : '',
        end: endAttendence ? endAttendence.toString() + ':00' : '',
        initialLunch: startHourLunch ? startHourLunch.toString() + ':00' : '',
        finalLunch: endHourLunch ? endHourLunch.toString() + ':00' : '',
        phone: cellPhone,
        address: address,
        organizationId: organizationId
      }

      await createScheduling(finalScheduling, token)
          .then((res) => {
              toast.success('Agenda Atualizada com Sucesso ')
          })
          .catch((err) => {
              console.log('erro ao atualizar agenda: ', err)
              toast.error('Erro ao atualizar agenda ')
          })
    }

    const schedlingCreated = async() => {
      await createdDates()
      await registerScheduling()
    }

    const updateSchedule = async() => {
      const finalScheduling = {
        days: days,
        startDate: dates[0],
        endDate: dates[dates.length - 1],
        start: startAttendence ? startAttendence.toString() + ':00' : '',
        end: endAttendence ? endAttendence.toString() + ':00' : '',
        initialLunch: startHourLunch ? startHourLunch.toString() + ':00' : '',
        finalLunch: endHourLunch ? endHourLunch.toString() + ':00' : '',
        phone: cellPhone,
        address: address,
        organizationId: organizationId
      }
      
      await updateScheduling(token, schedule._id, finalScheduling)
        .then((res) => {
            toast.success('Agenda Atualizada com sucesso ')
        })
        .catch((err) => {
            console.log('erro ao atualizar scheduling: ', err)
      })

    }

    const verifiedScheduling = () => {
      if(days.length === 0 ){
        toast.error('Você precisa preencher os dias da semana para continuar')
        return false
      } else if(!month) {
        toast.error('Você precisa escolher a quantidade de meses da sua agenda para continuar')
        return false
      } else if (startHour === 0 && endHour === 0) {
        toast.error('Você precisa escolher os horarios para continuar')
        return false
      } else if (cellPhone === '' || cellPhone.length < 11){
        toast.error('Você precisa preencher seu telefone para continuar')
        return false
      } else {
        return true
      }
    }

    const schedlingUpdate = async() => {
      const validate = verifiedScheduling()
      if(validate) {
        await createdDates()
        await updateSchedule()
        // await setTimeout(() => {realoderPage()}, 5000) 
      }
    }

    async function deleteEvent() {
      await deleteEvents(token, eventId)
      .then(() => {
        toast.success('Agendamento Excluido com Sucesso')
        calendarGet()
        handleClose()
      })
      .catch((err) => {
          console.log('erro ao Deletar Evento : ', err)
          toast.error('Erro ao deletar Evento ')
      })
    }
    
    const callEvents = async(info) => {
      const calendarId = info.event.extendedProps._id
      setEventId(calendarId)
      handleOpen()
    }

    async function clickSchedule(data) {
      setSelectSchedule(data)
    }

    function handleSelectItem(hour, data) {
      const objectIndex = arrayBlock.findIndex((item) => item.day === data.day && item.time === data.time); // Substitua "id" pela propriedade única do objeto

      if (objectIndex !== -1) {
        const newArrayBlock = arrayBlock.slice(); // Criar uma cópia do array
        newArrayBlock.splice(objectIndex, 1); // Remover o objeto no índice encontrado
        setArrayBlock(newArrayBlock);
      } else {
        setArrayBlock([...arrayBlock, data]);
      }

      if (selectedHours.includes(hour)) {
        setSelectedHours(selectedHours.filter((h) => h !== hour));
      } else {
        setSelectedHours([...selectedHours, hour]);
      }
    }

    async function blockedHours(){
      await blockScheduling(token, arrayBlock)
      .then(() => {
        toast.success('Horários bloqueados com sucesso ')
        setTimeout(() => {realoderPage()}, 2000)
      })
      .catch((err) => {
        toast.error('Erro ao bloquear horários ')
      })
    }

    async function desblockedHours(){

      await unlockScheduling(token, arrayBlock)
      .then(() => {
        toast.success('Horários desbloqueados com sucesso ')
        setTimeout(() => {realoderPage()}, 2000)
      })
      .catch((err) => {
        toast.error('Erro ao desbloquear horários ')
      })
    }

    async function blockedDay() {
      const blocked = listSchedule[filteredDate]
      await blockScheduling(token, blocked)
      .then(() => {
        toast.success('Horarios do dia bloqueados com sucesso')
        setTimeout(() => {realoderPage()}, 2000)
      })
      .catch((err) => {
        toast.error('Erro ao bloquear horários ')
      })
    }

    async function desblockedDay() {
      const desblocked = unlock?.schedule[filteredDateUnlock]
      console.log('desblocked: ', desblocked)
      await unlockScheduling(token, desblocked)
      .then(() => {
        toast.success('Dia desbloqueados com sucesso')
        setTimeout(() => {realoderPage()}, 2000)
      })
      .catch((err) => {
        toast.error('Erro ao bloquear horários ')
      })
    }

  return (
    <>
      <span onClick={nextStep}>
        <Joyride
          steps={StepsCalendar}
          run={startTutoria}
          continuous
          stepIndex={step}
          showProgress
          locale={{next: 'Próximo', back: '', last: 'Final'}}
          debug={true}
        />
      </span>
      <Toaster position="bottom-center" />
      <div className='container-new-scheduling'>
        <div className='container-button-register-calendar'>
            <div>
              {!startTutoria ? (
                  <OverlayTrigger placement='bottom' overlay={<Tooltip id="">Começar tutorial</Tooltip>}>
                      <FontAwesomeIcon 
                        style={{ 
                          color: 'green', 
                          fontSize: '25px', 
                          marginRight: '10px',
                          cursor: 'pointer',
                          marginTop: '5px'
                        }}  
                        onClick={() => setStartTutorial(true)} 
                        id='' 
                        icon={faCirclePlay} 
                      />
                  </OverlayTrigger>

              ):(
                  <OverlayTrigger placement='bottom' overlay={<Tooltip id="">Pausar tutorial</Tooltip>}>
                      <FontAwesomeIcon 
                        style={{ 
                          color: 'red',
                          fontSize: '25px', 
                          marginRight: '10px',
                          cursor: 'pointer' 
                        }} 
                        onClick={() => setStartTutorial(false)} 
                        id='' 
                        icon={faPause} 
                      />
                  </OverlayTrigger>
              )}
            </div>
            <button 
              onClick={() => setShow(true)} 
              className='button-style-register-sche'
              id='add-scheduling'
            > 
              {schedule && Object.keys(schedule).length > 0 ? '+ Atualizar Agendamento' : '+ Adicionar Agendamento'}
            </button>
            <FontAwesomeIcon 
              style={{ fontSize: '20px', marginLeft: '10px', cursor: 'pointer' }} 
              icon={faGear} 
              onClick={() => setOpenConfig(true)}
              id='config-scheduling'
            />
        </div>
        

        <Offcanvas show={show} placement='end' onHide={handleCloseCanva}>
               
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Agendamento</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
            <div>
          <div id="day-week">
              <p className='title-hours'>Dias da semana</p>
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%', border: '1px solid #2D1674', borderRadius: '6px', fontWeight: '600' }} 
                placeholder="Escolha os dias da semana"
                onChange={(value, e) => onChangeDays(value, e)}
                options={data}
                bordered={false}
              />
          </div>
          <div className='container-select-hours'>
              <p className='title-hours'>Quantidade de meses</p>
              <Select
                style={{ width: '100%', border: '1px solid #2D1674', borderRadius: '6px', fontWeight: '600' }} 
                placeholder="Escolha quantos meses irá durar sua agenda"
                onChange={(value, e) => setMonth(value)}
                options={[
                  { value: 30, label: '1 mês' },
                  { value: 60, label: '2 meses' },
                  { value: 90, label: '3 meses' },
                  { value: 120, label: '4 meses'},
                  { value: 150, label: '5 meses'},
                  { value: 180, label: '6 meses'},
                  { value: 210, label: '7 meses'},
                  { value: 240, label: '8 meses'},
                  { value: 270, label: '9 meses'},
                  { value: 300, label: '10 meses'},
                  { value: 330, label: '11 meses'},
                  { value: 360, label: '12 meses'},
                ]}
                bordered={false}
              />
          </div>
          <div className='container-select-hours'>
            <div>
              <p className='title-hours'>Atendimento - Horário inicial</p>
              <TimePicker 
                onChange={(value, e) => onChangeHoursInitial(value, e)} 
                style={{ width: '100%', border: '1px solid #2D1674', borderRadius: '6px', fontWeight: '600' }}   
                format='HH:mm' 
                placeholder='Escolha um horário'
                showNow={false}
              />
            </div>

            <div className='container-hours-calendar'>
              <p className='title-hours'>Atendimento - Horário Final</p>
              <TimePicker 
                onChange={(value, e) => onChangeHoursFinal(value, e)} 
                style={{ width: '100%', border: '1px solid #2D1674' }}  
                format='HH:mm' 
                placeholder='Escolha um horário'
                showNow={false}
              />
            </div>
          </div>
          <hr />
          <div className='container-select-hours'>
            <div>
              <p className='title-hours'>Almoço - Horário inicial</p>
              <TimePicker 
                onChange={(value, e) => onChangeHoursLunchInitial(value, e)} 
                style={{ width: '100%', border: '1px solid #2D1674' }}  
                format='HH:mm' 
                placeholder='Escolha um horário'
                showNow={false}
              />
            </div>

            <div className='container-hours-calendar'>
              <p className='title-hours'>Almoço - Horário Final</p>
              <TimePicker 
                onChange={(value, e) => onChangeHoursLunchFinal(value, e)} 
                style={{ width: '100%', border: '1px solid #2D1674' }}  
                format='HH:mm' 
                placeholder='Escolha um horário'
                showNow={false}
              />
            </div>
            
            <hr />
            <div className='container-select-hours'>
              <div>
                <p className='title-hours'>Celular (contato no agendamento)*</p>
                <Input 
                  onChange={(e) => setCellPhone(e.target.value)} 
                  style={{ width: '100%', border: '1px solid #2D1674', borderRadius: '6px', fontWeight: '600' }}   
                  placeholder='11912345678 - número com DD'
                  bordered={false}
                />
              </div>
            </div>

            <hr />
            <div className='container-select-hours'>
              <div>
                <p className='title-hours'>Endereço*</p>
                <Input 
                  onChange={(e) => setAddress(e.target.value)} 
                  style={{ width: '100%', border: '1px solid #2D1674', borderRadius: '6px', fontWeight: '600' }}   
                  placeholder='Rua silvana, 84A'
                  bordered={false}
                />
              </div>
            </div>
          </div>
          {schedule && Object.keys(schedule).length > 0 && (
            <>
             <div className='container-resume-dates'>
                <p className='title-hours'>Resumo do seus horários</p>
                <div className='card-resume-dates'>
                  <p><b>Dias da semana:</b> {String(schedule?.days) } </p>
                  <p><b className='color-title-resume'>Agendamento - Horário Inicial</b>: {schedule?.start}</p>
                  <p><b className='color-title-resume'>Agendamento - Horário Final:</b> {schedule?.end}</p>
                  <p><b>Almoço - Horário Inicial:</b> {schedule?.initialLunch}</p>
                  <p><b>Almoço - Horário Final:</b> {schedule?.finalLunch}</p>
                </div>
              </div>
            </>
          )}

          {schedule && Object.keys(schedule).length > 0 && (
            <>
             <div className='container-resume-dates' style={{ marginTop: '4%'}}>
                <p className='title-hours'>Link para suas clientes </p>
                <div className='card-resume-dates'>
                  <p><b>URL:</b> {`www.gerenciei-app.com/agendamento?id=${organizationId}`} </p>
                </div>
              </div>
            </>
          )}

          <div className='container-footer-schedule'>
            {schedule && Object.keys(schedule).length > 0  ? (
              <>
               <div className='container-button-cart'>
                  <button 
                    onClick={schedlingUpdate} 
                    className='button-style-calendar'
                  >
                    Atualizar
                  </button>
                </div>
              
                <div className='container-button-cart' >
                  <button onClick={handleCloseCanva}  className='button-cart-cancel'>Cancelar</button>
                </div>
              </>
            ):(
              <>
                <div className='container-button-cart'>
                  <button onClick={schedlingCreated} className='button-style-calendar'>Registrar</button>
                </div>
                <div className='container-button-cart' >
                  <button onClick={handleCloseCanva}  className='button-cart-cancel'>Cancelar</button>
                </div>
              </>
            )}
        </div>
        </div>
      </Offcanvas.Body>
            
      </Offcanvas>
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={openConfig}
          onHide={() => setOpenConfig(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  <div className='container-switch-blocked'>
                    <span className='title-config'>Configuração de Agendamento</span>
                    <Switch 
                      className='switch'
                      onChange={() => setBlockedHour(!blockedHour)} 
                      checkedChildren="Desbloquear" 
                      unCheckedChildren="Bloquear" 
                      defaultChecked 
                    />
                  </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {blockedHour ? (
              <>
              <p className='titles'>Bloqueio de horarios</p>
              <div>
                {listSchedule === undefined ? (
                  <>
                    <p className='text-dont-hours-block '>Ainda não existe horários e dias disponíveis.</p>
                  </>
                ): (
                  <>
                    <p className='titles-config'>Escolha o dia</p>
                    <div className='container-card-calendar-days'>
          
                      {Object.keys(listSchedule).map((date) => {
                          return(
                            <>
                              {date < moment().format('YYYY-MM-DD') ? (<></>):(
                                <div>
                                  <div onClick={() => clickSchedule(date)} className={filteredDate !== date ? 'card-days-calendar' : 'card-days-calendar-selected'} >
                                      <p 
                                          className={filteredDate !== date ? 'title-day-name-calendar' : 'title-day-name-selected-calendar'}
                                      >
                                          {moment(date).format('ddd')}
                                      </p>
                                      <p 
                                          className={filteredDate !== date ? 'title-day-number-calendar' : 'title-day-number-selected-calendar'}
                                      >
                                          {moment(date).format('DD')}
                                      </p>
                                      <p 
                                          className={filteredDate !== date ? 'title-year-month' : 'title-year-month-selected'}
                                      >
                                          {moment(date).format('MMMM/YY')}
                                      </p>
                                  </div>
                                </div>
                              )}
                              
                            </>
                          )
                      })}
                    </div>

                    <p className='titles-config'>Escolha os Horarios</p>
                    <div className='container-hours-calendar-card'>
                      {listSchedule[filteredDate]?.map((hour) => {
                        return(
                          <>
                            {hour.blocked !== true && hour.scheduling !== true && (
                              <div
                                key={hour?.time}
                                onClick={() => handleSelectItem(hour?.time, hour)} 
                                className={!selectedHours.includes(hour?.time) ? 'card-hours-calendar' : 'card-hours-calendar-selected'}
                              >
                                <p>{hour.time}</p>
                              </div>
                            )}
                          
                          </>
                        )
                      })}
                    </div>
                  </>
                )}
              </div>
              </>
            ):(
              <>
              <p className='title-desblocked'>Desbloqueio de horarios</p>
              <div>
                {schedule === undefined ? (
                  <>
                    <p className='text-dont-hours-block '>Ainda não existe horários e dias disponíveis.</p>
                  </>
                ): (
                  <>
                    <p className='titles-config'>Escolha o dia</p>
                    <div className='container-card-calendar-days'>
          
                      {Object.keys(unlock.schedule).map((date) => {
                          return(
                            <>
                              {moment(date).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') ? (<></>):(
                                <div>
                                  <div onClick={() => clickSchedule(date)} className={filteredDateUnlock !== date ? 'card-days-calendar' : 'card-days-calendar-selected'} >
                                      <p 
                                          className={filteredDateUnlock !== date ? 'title-day-name-calendar' : 'title-day-name-selected-calendar'}
                                      >
                                        {moment(date).format('ddd')}
                                      </p>
                                      <p 
                                          className={filteredDateUnlock !== date ? 'title-day-number-calendar' : 'title-day-number-selected-calendar'}
                                      >
                                          {moment(date).format('DD')}
                                      </p>
                                      <p 
                                          className={filteredDateUnlock !== date ? 'title-year-month' : 'title-year-month-selected'}
                                      >
                                          {moment(date).format('MMMM/YY')}
                                      </p>
                                  </div>
                                </div>
                              )}
                            </>
                          )
                      })}
                    </div>

                    <p className='titles-config'>Escolha os Horarios</p>
                    <div className='container-hours-calendar-card'>
                      {unlock?.schedule[filteredDateUnlock]?.map((hour) => {
                        return(
                          <>
                            {hour.blocked === true && (
                              <div
                                key={hour?.time}
                                onClick={() => handleSelectItem(hour?.time, hour)} 
                                className={!selectedHours.includes(hour?.time) ? 'card-hours-calendar-selected ' : 'card-hours-calendar'}
                              >
                                <p>{hour.time}</p>
                              </div>
                            )}
                          </>
                        )
                      })}
                    </div>
                  </>
                )}
              </div>
              </>
            )}
           
            </Modal.Body>
            <Modal.Footer>
              {schedule ? (
                <>
                 <button 
                    onClick={blockedHour? blockedDay : desblockedDay} 
                    className='button-style-block-confirm'
                  >
                    {blockedHour ? 'Bloquear dia' : 'Desbloquear dia'}
                  </button>
                 <button 
                    onClick={blockedHour? blockedHours : desblockedHours} 
                    className='button-style-block-confirm'
                  >
                    {blockedHour ? 'Bloquear' : 'Desbloquear'}
                  </button>

                  <button 
                    onClick={handleCloseConfig} 
                    className='button-style-block-cancel'
                  >
                    Cancelar
                  </button>
                </>
              ):(
                <>
                 <button 
                    onClick={handleCloseConfig} 
                    className='button-style-block-cancel'
                  >
                    Cancelar
                  </button>
                </>
              )}
             
            </Modal.Footer>
        </Modal>

        <Modal size='xs' show={open} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>Excluir Horário</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Tem certeza que deseja excluir esse agendamento ?</p>
          </Modal.Body>
          <Modal.Footer>
            <button 
                onClick={deleteEvent} 
                className='button-style-delete-confirm'
              >
                Confirmar
              </button>
              <button 
                onClick={handleClose} 
                className='button-style-delete-cancel'
              >
                Cancelar
            </button>
          </Modal.Footer>
        </Modal>

        {/* ------------------------------ MODAL CONFIGURAÇÕES --------------------------------- */}
       
      </div>

        {loading ? (
          <div className='container-loading-calendar'>
            <Loader />
          </div>
        ):(
          <div className='container-responsive-calendar'>
            <div
              style={{
                  height: '580px',
                  marginTop: '3%',
                  marginRight: '2%',
                  fontSize: '11px',
                  position: 'static',
                  zIndex: '-4'
              }}
            >

            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]} 
              headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,listMonth'
              }}
              initialView='listMonth'
              editable={true}
              selectable={true}
              selectMirror={true}
              dayMaxEvents={true}
              locales={allLocales}
              locale='pt-br'
              timeZone='UTC'
              events={datesCalendar}
              eventColor='#2D1674'
              style={{ color: 'black' }}
              eventClick={callEvents}
            />
            </div>
          </div>
        )}
    </>
  );
}

export default CalendarPage;