import axios from 'axios'

export async function createNotification(body, token) {
  
    const data = await axios.post(`${process.env.REACT_APP_API_LOCAL}/notification`, body, {
      headers: {
        'x-api-key': process.env.REACT_APP_XAPIKEY,
        Authorization: 'Bearer ' + token,
        'content-Type': 'application/json'
      }
    })
    .catch(err => {
        console.error('createNotification:', err.message)
        throw new Error("Erro ao criar notificação");
    })

    return data
}

export async function getNotificationOrganization(token, organizationId, setState) {
    const data = await axios.get(`${process.env.REACT_APP_API_LOCAL}/notification/organization/${organizationId}`, {
      headers: {
        'x-api-key': process.env.REACT_APP_XAPIKEY,
        Authorization: 'Bearer ' + token,
      }
    })
    .then((res) => {
        setState(res.data.notification)
        return res.data
    })
    .catch(err => {
        console.error('getNotificationOrganization:', err.message)
        throw new Error("Erro ao listar notificação");
    })
    return data
  }

  export async function updateNotification(body, notificationId, token) {
  
    const data = await axios.put(`${process.env.REACT_APP_API_LOCAL}/notification/${notificationId}`, body, {
      headers: {
        'x-api-key': process.env.REACT_APP_XAPIKEY,
        Authorization: 'Bearer ' + token,
        'content-Type': 'application/json'
      }
    })
    .catch(err => {
        console.error('updateNotification:', err.message)
        throw new Error("Erro ao criar notificação");
    })

    return data
}