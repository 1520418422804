import './marketing.css';
import React, { useEffect, useState } from 'react';
import { parseCookies } from 'nookies'
import toast, { Toaster } from 'react-hot-toast';
import Loader  from '../../Components/Loader'
import { generateContent } from '../../Controllers/marketing'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import { Button, Steps, Radio, Space } from 'antd';


const steps = [
  {
    title: 'Especializadade',
    content: 'First',
  },
  {
    title: 'O que deseja ?',
    content: 'Second',
  },
  {
    title: 'Finalizar',
    content: 'Last',
  },
];

function Marketing() {
  const [loading, setLoading] = useState(true)
  const [loaderImage, setLoaderImage] = useState(false)
  const [loaderContent, setLoaderContent] = useState(false)
  const [showContent, setShowContent] = useState(false)
  const [cards, setCards] = useState(true)
  const [logo, setLogo] = useState(false)
  const [basic, setBasic] = useState(false)
  const [post, setPost] = useState(false)
  const [current, setCurrent] = useState(0);
  const [expertise, setExpertise] = useState('')
  const [goal, setGoal] = useState('')
  const [showButton, setShowButton] = useState(true)
  const [content, setContent] = useState()

  const organizationId = window.localStorage.getItem('organization')
  const cookies = parseCookies()
  const token = cookies.token

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
 

  const dataCards = [
    {
      title: 'Crie Conteúdos',
      subTitle: 'Crie um cronograma semanal de conteúdos para as redes sociais',
      image: 'https://produtos-gerenciei.s3.amazonaws.com/posts.png',
      onclick: () => renderPost()
    },
    {
      title: 'Conceitos Básicos',
      subTitle: 'Aprenda sobre funis de vendas, estratégias digitais, como crescer no instagram.',
      image: 'https://produtos-gerenciei.s3.amazonaws.com/basicos.png',
      soon: true,
      onclick: () => renderBasic()
    },
  ]

  const handleChange = (setState) => (event) => {
    setState(event.target.value)
  }

  const renderBasic = () => {
    setBasic(true)
    setCards(false)
  }

  const renderPost = () => {
    setPost(true)
    setCards(false)
  }

  const returnCards = () => {
    setCards(true)
    setBasic(false)
    setLogo(false)
    setPost(false)
  }

  async function generateImage() {
    setLoading(false)
    setLoaderImage(true)
    setTimeout(() => {setLoaderImage(false)}, 5000)
  }

  async function generate() {
    const body2 = {
      content: `Quero que você atue como um especialista em conteúdo de mídia social. Você pode gerar centenas de 
      ideias de conteúdo com poucas informações. Suas ideias de conteúdo podem ser traduzidas em Instagram Reels, 
      YouTube Shorts, vídeos longos do YouTube e tudo mais. Você também sabe como lançar, 
      aumentar e nutrir públicos de maneira adequada nas redes sociais.

      Preciso que você crie um calendário de conteúdo semanal para seu cliente
      A missão do cliente é {levar autoestima para as pessoas através da área da beleza}. 
      Em seu relatório, faça uma tabela que inclua os dias da semana, tópico geral, um resumo do conteúdo a ser postado, 
      o tipo de conteúdo (vídeo ou estático) e a legenda da postagem, incluindo toda e qualquer hashtag relevante. 
      Atualmente, seu cliente usa seus serviços para 4 postagens de vídeo curtas por semana e 3 postagens estáticas 
      por semana.
      
      é uma  {empresa da área da beleza}. Recentemente, o conteúdo que eles têm postado gira em torno 
      de ${expertise}, então o conteúdo também deve ser 
      criado como uma ferramenta de geração de leads. Crie a tabela com algumas ideias e quaisquer outras 
      coisas relevantes que você possa imaginar. O retorno deve ser somente a tabela com o titulo {Calendário de Conteúdo Semanal} e os conteúdos e em formato HTML. 
      Além disso formate a tabela com as seguintes cores: o cabeçalho precisa ser dessa cor #2D1674. As letras do cabeçalho em branco. 
      Coloque linhas intercalando em branco e cinza, e de um espaçamento entre os textos e as linhas e colunas.`
    }

    if(expertise === '' || goal === '') {
      toast.error('Preencha todos os campos')
    } else {
      setLoaderContent(true)
      await generateContent(token, body2)
      .then((res) => {
        setContent(res.data?.response)
        setLoaderContent(false)
        setShowContent(true)
        setShowButton(false)
      })
    }
  }

  return (
    <div className="container-marketing">
      <Toaster position="bottom-center" />
      {cards && (
        <>
          {dataCards.map((data) => (
            <div 
              className={data.soon ? 'card-marketing-soon':'card-marketing'} 
              onClick={data.soon ? '' : data.onclick}>
              <div>
                <p>
                  <img className='image-card' src={data.image} />
                </p>
              </div>
              <div className='container-title-card-marketing'>
                <div className='container-title-soon-card-marketing'>
                  <p className='title-card-marketing'>{data.title}</p>
                  {data.soon && (
                    <span className='text-soon'>Em breve</span>
                  )}
                </div>
                <p className='sub-title-card-marketing'>{data.subTitle}</p>
              </div>
              <div className='container-icon-card-marketing'>
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
            </div>
          ))}
        </>
      )}

      {basic && (
        <div className='container-return-menu-marketing'>
          <div className='content-return-menu-marketing'>
            <FontAwesomeIcon 
              className='icon-return-marketing' 
              onClick={returnCards} 
              icon={faArrowLeft} 
            />
            <span className='title-create-logo'>Conceitos básicos</span>
          </div>
        </div>
      )}

      {logo && (
        <div className='container-return-menu-marketing'>
          <div className='content-return-menu-marketing'>
            <FontAwesomeIcon 
              className='icon-return-marketing' 
              onClick={returnCards} 
              icon={faArrowLeft} 
            />
            <span className='title-create-logo'>Criar logo</span>
          </div>
          <div>
            <h5>Escreva como deseja seu logo</h5>
            <p>Digite com o máximo de detalhe possível como deseja seu logo</p>

            {loading ? (
              <div className='container-render-images'>
                <div className='content-render-images'>
                  <p className='change-color-and-move '>Esperando voce criar um Logo.....</p>
                </div>
              </div>
            ):(
              <>
                {loaderImage === true ? (
                  <>
                    <Player
                      autoplay
                      loop
                      src="https://assets1.lottiefiles.com/packages/lf20_iS3ojumBx1.json"
                      style={{ height: '150px', width: '150px' }}
                    >
                      <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
                    </Player>
                  </>
                ):(
                <div className='container-images-generate'>
                  <img className='images-size' src='https://img.freepik.com/fotos-premium/tela-de-linho-branca-preta-escura-a-textura-da-imagem-de-fundo_263512-3248.jpg' />
                  <img className='images-size' src='https://img.freepik.com/fotos-premium/tela-de-linho-branca-preta-escura-a-textura-da-imagem-de-fundo_263512-3248.jpg' />
                </div>
                )}
              </>
            )}

            <div className='container-digit-logo'>
              <textarea 
                type='textArea'
                placeholder='Eu quero um logo com o fundo rosa, escrito o nome do meu salão "Corte Perfeito" em branco, com detalhes que lembram um salão de beleza'
                className='input-style-marketing ' 
              />
              <span onClick={generateImage} className='button-generate-logo'>Gerar Logo</span>
            </div>
          </div>
        </div>
      )}

      {post && (
        <div className='container-return-menu-marketing'>
          <div className='content-return-menu-marketing'>
            <FontAwesomeIcon 
              className='icon-return-marketing' 
              onClick={returnCards} 
              icon={faArrowLeft} 
            />
            <span className='title-create-logo'>Criar Conteúdo</span>
          </div>
          <Steps current={current} items={items} />
            <div>
              {steps[current]?.content === 'First' && (
                <div className='container-steps'>
                  <p className='title-steps'>Escolha a Especializade do seu salão</p>
                  <Radio.Group onChange={handleChange(setExpertise)} value={expertise}>
                    <Space direction="vertical">
                      <Radio value='sobrancelha'>Sobrancelha</Radio>
                      <Radio value='unha'>Unha</Radio>
                      <Radio value='cabelo'>Cabelo</Radio>
                      <Radio value='salão'>Todos</Radio>
                    </Space>
                  </Radio.Group>
                </div>
              )}

              {steps[current]?.content === 'Second' && (
                <div className='container-steps'>
                  <p className='title-steps'>Escolha qual objetivo deseja alcançar</p>
                  <Radio.Group onChange={handleChange(setGoal)} value={goal}>
                    <Space direction="vertical">
                      <Radio value='Crescer o número de seguidores no instagram'>Crescer o número de seguidores no instagram</Radio>
                      <Radio value='Aumentar número de vendas'>Aumentar número de vendas</Radio>
                      <Radio value='Ser mais relevante nas redes sociais'>Ser mais relevante nas redes sociais</Radio>
                    </Space>
                  </Radio.Group>
                </div>
              )}

              {steps[current]?.content === 'Last' && (
                <div>
                  
                    {loaderContent ? (
                      <>
                          <Player
                            autoplay
                            loop
                            src="https://assets1.lottiefiles.com/packages/lf20_iS3ojumBx1.json"
                            style={{ height: '150px', width: '150px' }}
                          >
                            <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
                          </Player>
                        </>
                    ):(
                      <>
                      {showContent && (
                        <div className='content-conteudos'>
                          <div dangerouslySetInnerHTML={{ __html: content }} />
                        </div>
                      )}
                      </>
                    )}
                    <div className='container-steps-button'>
                      {showButton && (
                        <span onClick={generate} className='button-generate-logo'>Gerar Conteúdo</span>
                      )}
                    </div>
                </div>
                
              )}
            </div>
            <div
              style={{
                marginTop: 24,
              }}
              className='container-steps-button'
            >
              {current > 0 && (
                <Button
                  style={{
                    margin: '0 8px',
                  }}
                  onClick={() => prev()}
                >
                  Voltar
                </Button>
              )}
              {current < steps?.length - 1 && (
                <Button className='button-next-steps' onClick={() => next()}>
                  Próximo
                </Button>
              )}
            </div>
        </div>
      )}
    </div>
  );
}

export default Marketing;