import axios from 'axios'

export async function createExits(body, token) {
  
    const data = await axios.post(`${process.env.REACT_APP_API_LOCAL}/exit`, body, {
      headers: {
        'x-api-key': process.env.REACT_APP_XAPIKEY,
        Authorization: 'Bearer ' + token,
        'content-Type': 'application/json'
      }
    })
    .catch(err => {
        console.error('createExits:', err.message)
        throw new Error("Erro ao criar saída");
    })

    return data
}

export async function getExitsByUser(token, startDate, endDate, setState) {
  
    const data = await axios.get(`${process.env.REACT_APP_API_LOCAL}/exit/user?startDate=${startDate}&finalDate=${endDate}`, {
      headers: {
        'x-api-key': process.env.REACT_APP_XAPIKEY,
        Authorization: 'Bearer ' + token,
      }
    })
    .then((res) => {
        setState(res.data.exits)
        return res.data
    })
    .catch(err => {
        console.error('getExitsByUser:', err.message)
        throw new Error("Erro ao listar saídas");
    })

    return data
}

export async function getExitsOrganization(token, organizationId, startDate, endDate, setState, resume, page) {
  
  const data = await axios.get(`${process.env.REACT_APP_API_LOCAL}/exit/organization/${organizationId}?startDate=${startDate}&finalDate=${endDate}&page=${page}`, {
    headers: {
      'x-api-key': process.env.REACT_APP_XAPIKEY,
      Authorization: 'Bearer ' + token,
    }
  })
  .then((res) => {
      setState(res.data.exits)
      resume(res.data.resume)
      return res.data
  })
  .catch(err => {
      console.error('getCalendarOrganization:', err.message)
      throw new Error("Erro ao listar Agendamento");
  })

  return data
}

export async function deleteExit(token, exitId) {
  
    const data = await axios.delete(`${process.env.REACT_APP_API_LOCAL}/exit/${exitId}`, {
      headers: {
        'x-api-key': process.env.REACT_APP_XAPIKEY,
        Authorization: 'Bearer ' + token,
        'content-Type': 'application/json'
      }
    })
    .catch(err => {
        console.error('deleteExit:', err.message)
        throw new Error("Erro ao editar saída");
    })

    return data
}

export async function updateExit(token, exitId, body) {
  
    const data = await axios.put(`${process.env.REACT_APP_API_LOCAL}/exit/${exitId}`, body, {
      headers: {
        'x-api-key': process.env.REACT_APP_XAPIKEY,
        Authorization: 'Bearer ' + token,
        'content-Type': 'application/json'
      }
    })
    .catch(err => {
        console.error('updateExit:', err.message)
        throw new Error("Erro ao atualizar saida");
    })

    return data
}