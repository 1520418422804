import axios from 'axios'

export async function createProduct(body, token) {
  
    const data = await axios.post(`${process.env.REACT_APP_API_LOCAL}/product`, body, {
      headers: {
        'x-api-key': process.env.REACT_APP_XAPIKEY,
        Authorization: 'Bearer ' + token,
        'content-Type': 'application/json'
      }
    })
    .catch(err => {
        console.error('createProduct:', err.message)
        throw new Error("Erro ao criar produto");
    })

    return data
}

export async function getProductByUser(token, setState) {
  
    const data = await axios.get(`${process.env.REACT_APP_API_LOCAL}/product/user?name=&code=`, {
      headers: {
        'x-api-key': process.env.REACT_APP_XAPIKEY,
        Authorization: 'Bearer ' + token,
        // 'content-Type': 'application/json'
      }
    })
    .then((res) => {
        setState(res.data.products)
        return res.data
    })
    .catch(err => {
        console.error('getProductsByUser:', err.message)
        throw new Error("Erro ao listar produtos");
    })

    return data
}

export async function getProductOrganization(token, organizationId, setState) {
  const data = await axios.get(`${process.env.REACT_APP_API_LOCAL}/product/organization/${organizationId}?name=&code=`, {
    headers: {
      'x-api-key': process.env.REACT_APP_XAPIKEY,
      Authorization: 'Bearer ' + token,
    }
  })
  .then((res) => {
      setState(res.data.products)
      return res.data
  })
  .catch(err => {
      console.error('getProductOrganization:', err.message)
      throw new Error("Erro ao listar produtos");
  })
  return data
}

export async function deleteProduct(token, productId) {
  
    const data = await axios.delete(`${process.env.REACT_APP_API_LOCAL}/product/${productId}`, {
      headers: {
        'x-api-key': process.env.REACT_APP_XAPIKEY,
        Authorization: 'Bearer ' + token,
        'content-Type': 'application/json'
      }
    })
    .catch(err => {
        console.error('deleteUser:', err.message)
        throw new Error("Erro ao deletar produto");
    })

    return data
}

export async function updateProduct(token, productId, body) {
  
    const data = await axios.put(`${process.env.REACT_APP_API_LOCAL}/product/${productId}`, body, {
      headers: {
        'x-api-key': process.env.REACT_APP_XAPIKEY,
        Authorization: 'Bearer ' + token,
        'content-Type': 'application/json'
      }
    })
    .catch(err => {
        console.error('deleteUser:', err.message)
        throw new Error("Erro ao deletar produto");
    })

    return data
}