import axios from 'axios'

export const fetcherOrganizationId = async (organizationId) => {
  const url = `${process.env.REACT_APP_API_LOCAL}/organization/id/${organizationId}/`;
  
  try {
    const response = await axios.get(url, {
      headers: {
        'x-api-key': process.env.REACT_APP_XAPIKEY,
      },
    });
    return response.data;
  } catch (error) {
    console.error('fetcher error:', error.message);
    throw new Error('Erro ao buscar os dados');
  }
};

export const fetcherNotification = async (organizationId, token) => {
    const url = `${process.env.REACT_APP_API_LOCAL}/notification/organization/${organizationId}`;
    
    try {
      const response = await axios.get(url, {
        headers: {
          'x-api-key': process.env.REACT_APP_XAPIKEY,
          Authorization: 'Bearer ' + token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('fetcher error:', error.message);
      throw new Error('Erro ao buscar os dados');
    }
  };

  export async function authenticate(body) {
  
    const data = await axios.post(`${process.env.REACT_APP_API_LOCAL}/auth/authenticate`, body, {
      headers: {
        'x-api-key': process.env.REACT_APP_XAPIKEY,
        // Authorization: 'Bearer ' + token,
        'content-Type': 'application/json'
      }
    })
    .catch(err => {
        console.error('Authenticate:', err.message)
        throw new Error("Não Autorizado, erro ao logar");
    })

    return data
  }

  export async function fetcherScheduling(organizationId) {
  
    const url = `${process.env.REACT_APP_API_LOCAL}/external/scheduling/organization/${organizationId}`;
    
    try {
      const response = await axios.get(url, {
        headers: {
          'x-api-key': process.env.REACT_APP_XAPIKEY,
          // 'content-Type': 'application/json'
          // Authorization: 'Bearer ' + token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('fetcher error:', error.message);
      throw new Error('Erro ao buscar os dados');
    }
  }

  export async function fetcherCalendar(organizationId) {
  
    const url = `${process.env.REACT_APP_API_LOCAL}/external/calendar/organization/${organizationId}`;
    
    try {
      const response = await axios.get(url, {
        headers: {
          'x-api-key': process.env.REACT_APP_XAPIKEY,
          // 'content-Type': 'application/json'
          // Authorization: 'Bearer ' + token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('fetcher error:', error.message);
      throw new Error('Erro ao buscar os dados');
    }
  }

  export async function fetcherProducts(organizationId) {
  
    const url = `${process.env.REACT_APP_API_LOCAL}/external/product/organization/${organizationId}`;
    
    try {
      const response = await axios.get(url, {
        headers: {
          'x-api-key': process.env.REACT_APP_XAPIKEY,
          // 'content-Type': 'application/json'
          // Authorization: 'Bearer ' + token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('fetcher error:', error.message);
      throw new Error('Erro ao buscar os dados');
    }
  }

  export async function fetcherExits(token, organizationId, startDate, endDate, page) {
  
    const url = `${process.env.REACT_APP_API_LOCAL}/exit/organization/${organizationId}?startDate=${startDate}&finalDate=${endDate}&page=${page}`;
    
    try {
      const response = await axios.get(url, {
        headers: {
          'x-api-key': process.env.REACT_APP_XAPIKEY,
          // 'content-Type': 'application/json'
          Authorization: 'Bearer ' + token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('fetcher error:', error.message);
      throw new Error('Erro ao buscar os dados');
    }
  }