import axios from 'axios'

export async function organizationById(organizationId, setState) {
  
  const data = await axios.get(`${process.env.REACT_APP_API_LOCAL}/organization/id/${organizationId}/`,{
    headers: {
      'x-api-key': process.env.REACT_APP_XAPIKEY,
    //   Authorization: 'Bearer ' +
    }
  })
  .then((res) => {
    setState(res.data)
    return res.data
})
  .catch(err => {
      console.error('createSubscription:', err.message)
      throw new Error("Erro ao criar agendamento");
  })

  return data
}