import React from 'react';
import './cards.css'

function Card({ props, text, style, styleContent, styleTitle, styleIcon, icon, onClick }) {

  
    return (
      <div className="container-card" onClick={onClick}>
        <div 
            className='card-home' 
            style={style}
        >
            <div className='content-container' style={styleContent}>
                <span style={styleTitle}>{text}</span>
                <span className='icon-style' style={styleIcon}>{icon}</span>
            </div>
        </div>
      </div>
    );
  }
  
  export default Card;