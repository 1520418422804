import axios from 'axios'

export async function getPersonalize(token, organizationId) {
    const data = await axios.get(`${process.env.REACT_APP_API_LOCAL}/external/customizer/${organizationId}`,{
      headers: {
        // Authorization: 'Bearer ' + token,
        'x-api-key': process.env.REACT_APP_XAPIKEY,
      }
    })
    .then((res) => {
      return res.data
    })
    .catch(err => {
        console.error('getPersonalize:', err.message)
        throw new Error("Erro ao customizar cliente");
    })
  
    return data
}

export async function createPersonalize(token, body) {
  
  const data = await axios.post(`${process.env.REACT_APP_API_LOCAL}/external/customizer`, body, {
    headers: {
      Authorization: 'Bearer ' + token,
      'x-api-key': process.env.REACT_APP_XAPIKEY,
    }
  })
  .then((res) => {
    return res.data
  })
  .catch(err => {
      console.error('createPersonalize:', err.message)
      throw new Error("Erro ao personalizar cliente");
  })

  return data
}

export async function updatePersonalize(token, body, customizeId) {
  
  const data = await axios.put(`${process.env.REACT_APP_API_LOCAL}/external/customizer/${customizeId}`, body, {
    headers: {
      Authorization: 'Bearer ' + token,
      'x-api-key': process.env.REACT_APP_XAPIKEY,
    }
  })
  .then((res) => {
    return res.data
  })
  .catch(err => {
      console.error('createPersonalize:', err.message)
      throw new Error("Erro ao personalizar cliente");
  })

  return data
}