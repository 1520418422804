import React, {useState, useEffect} from 'react';
import { resetPass } from '../../Controllers/user'
import toast, { Toaster } from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faLock, faEnvelope, faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import Loader  from '../../Components/Loader'
import './reset.css';
function Reset() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [showPass, setShowPass] = useState(false)
  const [showConfirmPass, setShowConfirmPass] = useState(false)
  const [loaderButton, setLoaderButton] = useState(false)

 async function resetPassword() {
  setLoaderButton(true)
  const data = {
    email: email,
    password: confirmPassword
  }

  if(password !== confirmPassword)
    toast.error('As senhas não são iguais!!')

  await resetPass(data)
    .then((res) => {
      setLoaderButton(false)
      toast.success('Senha Atualizada com sucesso!! ', {icon: '🎉🥳'})
      setTimeout(() => {window.location.href = '/'}, 3000)
    })
    .catch((err) => {
      console.log('erro ao se registrar', err)
      toast.error('Ops!! Algo deu errado ao atualizar a senha')
    })
 }

 useEffect(() => {
  window.localStorage.setItem("cmenu", false)
},[])

  return (

    <div className="container-login">
      <Toaster position="bottom-center" />
      <div class="container-card-login">
        <div class="screen">
          <div class="screen__content">
            <div class="login-signup">
              <p className='title-card-form '>Nova Senha</p>
              <div class="login__field-signup">
                <FontAwesomeIcon className='login__icon fas fa-lock' icon={faEnvelope} />
                <input 
                  onChange={(e) => setEmail(e.target.value)} 
                  type='text' 
                  placeholder='E-mail*' 
                  className='login__input' 
                />
              </div>
              <div class="login__field-signup">
                <FontAwesomeIcon className='login__icon fas fa-lock' icon={faLock} />
                <input 
                  onChange={(e) => setPassword(e.target.value)} 
                  type={showPass ? 'text' : 'password'}  
                  placeholder='Nova Senha*' 
                  className='login__input' 
                />
                 <FontAwesomeIcon 
                    onClick={() =>  setShowPass(!showPass)} 
                    className='login__icon_eye' icon={showPass? faEyeSlash : faEye} />
              </div>
              <div class="login__field-signup">
                <FontAwesomeIcon className='login__icon fas fa-user' icon={faLock} />
                <input 
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  type={showConfirmPass ? 'text' : 'password'} 
                  placeholder='Confirme sua nova senha*' 
                  className='login__input' 
                />
                 <FontAwesomeIcon 
                    onClick={() =>  setShowConfirmPass(!showConfirmPass)} 
                    className='login__icon_eye' icon={showConfirmPass? faEyeSlash : faEye} />
              </div>
          
              <div className='container-inputs-button'>
                <button onClick={resetPassword} className='button login__submit'>
                  {loaderButton ? 
                    <Loader size='sm' /> 
                  : 'Atualizar'}
                </button>
              </div>
              
            </div>
          </div>
          <div class="screen__background">
            <span class="screen__background__shape screen__background__shape1"></span>
          </div>		
        </div>
      </div>
      <div className='container-data-enterprise'>
        <span className='text-developed'>Desenvolvido por</span>
        <span className='text-name-company'>Gerenciei</span>
      </div>
    </div>
    // <div className="container">
    //   <Toaster position="bottom-center" />
    //   <div className="container-login">
    //     <div className="card-login">
    //         <div className='text-login'>
    //             <p>Nova Senha</p>
    //         </div>
    //         <div className='container-inputs-button'>
    //             <input onChange={(e) => setEmail(e.target.value)} type='email' placeholder='E-mail' className='inputs-style-signup' />
    //         </div>
    //         <div className='container-inputs-button'>
    //             <input onChange={(e) => setPassword(e.target.value)} type='password' placeholder='Senha' className='inputs-style-signup' />
    //         </div>
    //         <div className='container-inputs-button'>
    //             <input onChange={(e) => setConfirmPassword(e.target.value)} type='password' placeholder='Confirme sua senha' className='inputs-style-signup' />
    //         </div>
    //         <div className='container-inputs-button' onClick={resetPassword}>
    //             <button className='button-style-signup'>Registrar</button>
    //         </div>
    //     </div>
    //   </div>
    // </div>
  );
}

export default Reset;