import axios from 'axios'


export async function getScheduling(userId, setState) {
  
    const data = await axios.get(`${process.env.REACT_APP_API_LOCAL}/external/scheduling/${userId}`, {
      headers: {
        'x-api-key': process.env.REACT_APP_XAPIKEY,
        // Authorization: 'Bearer ' + token,
      }
    })
    .then((res) => {
      setState(res.data.scheduling[0])
      return res.data
    })    
    .catch(err => {
        console.error('createScheduling:', err.message)
        throw new Error("Erro ao criar Agendamento");
    })
  
    return data
  }

  export async function getSchedulingOrganization(organizationId, setState) {
  
    const data = await axios.get(`${process.env.REACT_APP_API_LOCAL}/external/scheduling/organization/${organizationId}`, {
      headers: {
        'x-api-key': process.env.REACT_APP_XAPIKEY,
        // Authorization: 'Bearer ' + token,
      }
    })
    .then((res) => {
        setState(res.data.scheduling[0])
        return res.data
    })
    .catch(err => {
        console.error('getCalendarOrganization:', err.message)
        throw new Error("Erro ao listar Agendamento");
    })
  
    return data
  }

  export async function updateScheduling(token, scheduleId, body) {
  
    const data = await axios.put(`${process.env.REACT_APP_API_LOCAL}/external/scheduling/${scheduleId}`, body, {
      headers: {
        'x-api-key': process.env.REACT_APP_XAPIKEY,
        // Authorization: 'Bearer ' + token,
        'content-Type': 'application/json'
      }
    })
    .catch(err => {
        console.error('updateScheduling:', err.message)
        throw new Error("Erro ao atualizar agendamento");
    })
  
    return data
  }

//   CALENDAR ----------------------------------------------------------------------------

export async function getCalendar(userId, setState) {
  
    const data = await axios.get(`${process.env.REACT_APP_API_LOCAL}/external/calendar/${userId}`, {
      headers: {
        'x-api-key': process.env.REACT_APP_XAPIKEY,
        // Authorization: 'Bearer ' + token,
      }
    })
    .then((res) => {
      setState(res.data.calendar)
      return res.data
    })    
    .catch(err => {
        console.error('createScheduling:', err.message)
        throw new Error("Erro ao criar Agendamento");
    })
  
    return data
}

export async function getCalendarOrganization(organizationId, setState) {
  
  const data = await axios.get(`${process.env.REACT_APP_API_LOCAL}/external/calendar/organization/${organizationId}`, {
    headers: {
      'x-api-key': process.env.REACT_APP_XAPIKEY,
      // Authorization: 'Bearer ' + token,
    }
  })
  .then((res) => {
      setState(res.data.calendar)
      return res.data
  })
  .catch(err => {
      console.error('getCalendarOrganization:', err.message)
      throw new Error("Erro ao listar Agendamento");
  })

  return data
}

export async function createCalendar(body, token) {
  
    const data = await axios.post(`${process.env.REACT_APP_API_LOCAL}/external/calendar`, body, {
      headers: {
        'x-api-key': process.env.REACT_APP_XAPIKEY,
        // Authorization: 'Bearer ' + token,
        'content-Type': 'application/json'
      }
    })
    .catch(err => {
        console.error('createCalendar:', err.message)
        throw new Error("Erro ao criar agendamento");
    })

    return data
}

//   CLIENT ----------------------------------------------------------------------------

export async function createClient(token, body) {
    
    const data = await axios.post(`${process.env.REACT_APP_API_LOCAL}/external/client`, body, {
      headers: {
        'x-api-key': process.env.REACT_APP_XAPIKEY,
        // Authorization: 'Bearer ' + token,
        'content-Type': 'application/json'
      }
    })
    .catch(err => {
        console.error('createClient:', err.message)
        throw new Error("Erro ao salvar cliente");
    })

    return data
}

//   PRODUCTS ----------------------------------------------------------------------------

export async function getProductByUser(userId, setState) {
  
    const data = await axios.get(`${process.env.REACT_APP_API_LOCAL}/external/product/${userId}`, {
      headers: {
        'x-api-key': process.env.REACT_APP_XAPIKEY,
        // Authorization: 'Bearer ' + token,
        // 'content-Type': 'application/json'
      }
    })
    .then((res) => {
        setState(res.data.products)
        return res.data
    })
    .catch(err => {
        console.error('getProductsByUser:', err.message)
        throw new Error("Erro ao listar produtos");
    })

    return data
}

export async function getProductOrganization(organizationId, setState) {
  
  const data = await axios.get(`${process.env.REACT_APP_API_LOCAL}/external/product/organization/${organizationId}`, {
    headers: {
      'x-api-key': process.env.REACT_APP_XAPIKEY,
      // Authorization: 'Bearer ' + token,
    }
  })
  .then((res) => {
      setState(res.data.products)
      return res.data
  })
  .catch(err => {
      console.error('getCalendarOrganization:', err.message)
      throw new Error("Erro ao listar Agendamento");
  })

  return data
}

//   USER ----------------------------------------------------------------------------

export async function getUser(userId, setState) { 
  const data = await axios.get(`${process.env.REACT_APP_API_LOCAL}/external/user/${userId}`, {
    headers: {
      'x-api-key': process.env.REACT_APP_XAPIKEY,
      // Authorization: 'Bearer ' + token,
    }
  })
  .then((res) => {
    setState(res.data.user[0])
    return res.data
  })    
  .catch(err => {
      console.error('createScheduling:', err.message)
      throw new Error("Erro ao criar Agendamento");
  })

  return data
}

export async function getUserOrganization(organizationId, setState) {
  
  const data = await axios.get(`${process.env.REACT_APP_API_LOCAL}/external/user/organization/${organizationId}`, {
    headers: {
      'x-api-key': process.env.REACT_APP_XAPIKEY,
      // Authorization: 'Bearer ' + token,
    }
  })
  .then((res) => {
      setState(res.data.user[0])
      return res.data
  })
  .catch(err => {
      console.error('getCalendarOrganization:', err.message)
      throw new Error("Erro ao listar Agendamento");
  })

  return data
}

//   NOTIFICATION ----------------------------------------------------------------------------

export async function createNotification(body) {
    
  const data = await axios.post(`${process.env.REACT_APP_API_LOCAL}/external/notification`, body, {
    headers: {
      'x-api-key': process.env.REACT_APP_XAPIKEY,
      // Authorization: 'Bearer ' + token,
      'content-Type': 'application/json'
    }
  })
  .catch(err => {
      console.error('createClient:', err.message)
      throw new Error("Erro ao salvar cliente");
  })

  return data
}

export async function getScheduleOrg(organizationId) {
  const url = `${process.env.REACT_APP_API_LOCAL}/external/schedule/organization/${organizationId}`;
  const headers = { 'x-api-key': process.env.REACT_APP_XAPIKEY };
  const response = await axios.get(url, { headers });
  return response.data;
}

export async function getScheduleOrganization(organizationId, setState) {
  
  const data = await axios.get(`${process.env.REACT_APP_API_LOCAL}/external/schedule/${organizationId}`, {
    headers: {
      'x-api-key': process.env.REACT_APP_XAPIKEY,
      // Authorization: 'Bearer ' + token,
    }
  })
  .then((res) => {
      setState(res.data.result)
      return res.data
  })
  .catch(err => {
      console.error('getSchedulingOrganization:', err.message)
      throw new Error("Erro ao listar Agendamento");
  })

  return data
}
