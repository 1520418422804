import React, { useState, useEffect } from 'react'
import './details.css'
import Card from '../../Components/Cards';
import { getExitsByUser, getExitsOrganization } from '../../Controllers/exits'
import { getEntrancesByUser, getEntranceOrganization } from '../../Controllers/entrance'
import { parseCookies } from 'nookies'
import { sum, subtract } from 'mathjs'
import { redirectLogin } from '../../Functions';
import Moment from 'moment'

export function convertDateToISOFormat(date, hour) {
	const time = date + ' ' + hour;
	return new Date(time.replace(/\s/g, 'T') + 'Z').toISOString();
}


function Details() {
    const [entrances, setEntrances] = useState([])
    const [exits, setExits] = useState([])
    const [startDate, setStartDate] = useState(Moment().subtract(7, 'days').format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(Moment().format('YYYY-MM-DD'))
    const [resume, setResume] = useState()
    const [resumeExit, setResumeExit] = useState()

    const initialTime = '00:00'
	const finalTime = '23:59'

    const organizationId = window.localStorage.getItem('organization')
    const cookies = parseCookies()
    const token = cookies.token

    async function details() {
        const start = convertDateToISOFormat(startDate, initialTime)
        const end = convertDateToISOFormat(endDate, finalTime)

        await getEntranceOrganization(token, organizationId, start, end, setEntrances, setResume, 0)
        await getExitsOrganization(token, organizationId, start, end, setExits, setResumeExit, 0)
    }

    async function getDetailsFilter() {
        const start = convertDateToISOFormat(startDate, initialTime)
        const end = convertDateToISOFormat(endDate, finalTime)
        await getEntranceOrganization(token, organizationId, start, end, setEntrances, setResume, 0)
        await getExitsOrganization(token, organizationId, start, end, setExits, setResumeExit, 0)
    }

    async function getSalesFilterDate(day) {

        const startFilterSelect = Moment().subtract(day, 'days').format('YYYY-MM-DD')
        const endFilterSelect = Moment().format('YYYY-MM-DD')

        const start = convertDateToISOFormat(startFilterSelect, initialTime)
        const end = convertDateToISOFormat(endFilterSelect, finalTime)
 
        await getEntranceOrganization(token, organizationId, start, end, setEntrances, setResume, 0)
        await getExitsOrganization(token, organizationId, start, end, setExits, setResumeExit, 0)
    }

    useEffect(() => {
        if(token === undefined){
            redirectLogin()
        }
        details()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

  return (
    <div className='container-details'>
        <div className='content-details'>
            <p className='titles'>Detalhes</p>

            <div className='container-search-detail'>
                <div className='container-inputs-exits'>
                    <select 
                        className='input-style-exit'
                        onChange={(e) => getSalesFilterDate(e.target.value)}
                        defaultValue='7'
                    >
                        <option value='7'>1 semana</option>
                        <option value='30'>30 dias</option>
                        <option value='60'>60 dias</option>
                    </select>
                </div>
                <div className='container-inputs-sales'>
                    <input 
                        type='date' 
                        className='input-style-details' 
                        onChange={(e) => setStartDate(e.target.value)}
                        max={Moment().format('YYYY-MM-DD')}
                        min={Moment().subtract(90, 'days').format('YYYY-MM-DD')}
                    />
                </div>

                <div className='container-inputs-sales'>
                    <input 
                        type='date' 
                        className='input-style-details' 
                        onChange={(e) => setEndDate(e.target.value)}
                        max={Moment().format('YYYY-MM-DD')}
                        min={Moment().subtract(90, 'days').format('YYYY-MM-DD')}
                    />
                </div>

                <div className='container-button-details'>
                    <button onClick={getDetailsFilter} className='button-style-details'>Pesquisar</button>
                </div>
            </div>

            <div className='container-card-details'>
            <Card
                text='Entrada' 
                style={{ 
                    background: 'linear-gradient(163deg, rgba(76,227,251,1) 0%, rgba(143,91,255,1) 100%)', 
                    fontSize: '20px',
                }} 
                styleContent={{ display: 'flex', flexDirection: 'column'}}
                icon={sum(resume?.totalValue).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                styleTitle={{ fontSize: '14px' }}
            />

            <Card
                text='Saída' 
                style={{ 
                    background: 'linear-gradient(163deg, rgba(251,76,76,1) 0%, rgba(255,165,91,1) 100%)', 
                    fontSize: '20px',
                }} 
                styleContent={{ display: 'flex', flexDirection: 'column'}}
                icon={sum(resumeExit?.totalValue).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                styleTitle={{ fontSize: '14px' }}
            />

            <Card
                text='Saldo' 
                style={{ 
                    background: 'linear-gradient(163deg, rgba(136,228,154,1) 0%, rgba(60,210,206,1) 100%)', 
                    fontSize: '20px',
                }} 
                styleContent={{ display: 'flex', flexDirection: 'column'}}
                icon={subtract(sum(resume?.totalValue), sum(resumeExit?.totalValue)).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                styleTitle={{ fontSize: '14px' }}
            />
        </div>

        </div>
    </div>
  );
}

export default Details;