
import useSWR, { mutate } from 'swr';
import axios from 'axios'
import { 
  fetcherOrganizationId,
  fetcherNotification,
  fetcherScheduling,
  fetcherCalendar,
  fetcherProducts,
  fetcherExits
} from '../Controllers/hooks';

export function useOrganizationData(organizationId) {
  const { data } = useSWR(`organization`, () => fetcherOrganizationId(organizationId));
    return {
        ...data,
    };
}

export function useNotificationData(organizationId, token) {
  const { data } = useSWR(`notification`, () => fetcherNotification(organizationId, token));
    return {
        ...data,
    };
}

export function useScedulingData(organizationId) {
  const { data } = useSWR(`scheduling`, () => fetcherScheduling(organizationId));
    return {
        ...data?.scheduling[0],
    };
}

export function useCalendarData(organizationId) {
  const { data } = useSWR(`calendar`, () => fetcherCalendar(organizationId));
    return data?.calendar
}

export function useProductsData(organizationId) {
  const { data } = useSWR(`products`, () => fetcherProducts(organizationId));
    return data?.products
}

export function useExitsData(token, organizationId, startDate, endDate, page) {
  const { data } = useSWR(`exits`, () => fetcherExits(token, organizationId, startDate, endDate, page));
  console.log('data exits: ', data)
    return data?.exits
}


