import React, {useState} from 'react';
import './signup.css';
import { createUser } from '../../Controllers/user'
import toast, { Toaster } from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faLock, faEnvelope, faBuilding } from '@fortawesome/free-solid-svg-icons';

function Signup() {
  const [name, setName] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

 async function signup() {
  
  const data = {
    name: name,
    email: email,
    password: password,
    companyData: [{
      companyName: companyName
    }]
  }

  await createUser(data)
    .then((res) => {
      console.log('conta criada com sucesso', res.data)
      toast.success('Conta Criada com sucesso ', {icon: '🎉🥳'})
      setTimeout(() => {window.location.href = '/'}, 1500)
    })
    .catch((err) => {
      console.log('erro ao se registrar', err)
      toast.error('Ops!! Algo deu errado ao criar sua conta')
    })
 }

  const goToLogin = () => {
    window.location.href = '/'
  }

  return (
    <div className="container-login">
      <Toaster position="bottom-center" />
      <div class="container-card-login">
              <div class="screen">
                <div class="screen__content">
                  <div class="login-signup">
                    <div class="login__field-signup">
                      <FontAwesomeIcon className='login__icon fas fa-lock' icon={faUser} />
                      <input 
                        onChange={(e) => setName(e.target.value)} 
                        type='text' 
                        placeholder='Nome Completo*' 
                        className='login__input' 
                      />
                    </div>
                    <div class="login__field-signup">
                      <FontAwesomeIcon className='login__icon fas fa-lock' icon={faBuilding} />
                      <input 
                        onChange={(e) => setCompanyName(e.target.value)} 
                        type='text' 
                        placeholder='Nome da empresa*' 
                        className='login__input' 
                      />
                    </div>
                    <div class="login__field-signup">
                      <FontAwesomeIcon className='login__icon fas fa-user' icon={faEnvelope} />
                      <input 
                        onChange={(e) => setEmail(e.target.value)}
                        type='email' 
                        placeholder='E-mail*' 
                        className='login__input' 
                        value={email} 
                      />
                    </div>
                    <div class="login__field-signup">
                      <FontAwesomeIcon className='login__icon fas fa-lock' icon={faLock} />
                      <input 
                        onChange={(e) => setPassword(e.target.value)} 
                        type='password' 
                        placeholder='Senha*' 
                        className='login__input' 
                      />
                    </div>
                  
                
                    <div className='container-inputs-button'>
                      <button onClick={signup} className='button login__submit'>Registrar</button>
                    </div>
                    <div >
                        <p className='text-or'>ou</p>
                    </div>
                    <div onClick={goToLogin} >
                        <p className='text-register-new'>Já Tenho uma conta</p>
                    </div>
                  </div>
                </div>
                <div class="screen__background">
                  <span class="screen__background__shape screen__background__shape1"></span>
                </div>		
              </div>
            </div>
      <div className='container-data-enterprise'>
        <span className='text-developed'>Desenvolvido por</span>
        <span className='text-name-company'>Gerenciei</span>
      </div>
    </div>
  );
}

export default Signup;