import axios from 'axios'

export async function getProfile(token, userId, planId) {
    const data = await axios.get(`${process.env.REACT_APP_API_LOCAL}/profile/${userId}/${planId}`,{
      headers: {
        Authorization: 'Bearer ' + token,
        'x-api-key': process.env.REACT_APP_XAPIKEY,
      }
    })
    .then((res) => {
      return res.data
    })
    .catch(err => {
        console.error('getCustomer:', err.message)
        throw new Error("Erro ao retornar cliente");
    })
  
    return data
}

export async function updateProfile(token, userId, body) {
  
  const data = await axios.put(`${process.env.REACT_APP_API_LOCAL}/profile/${userId}`, body, {
    headers: {
      Authorization: 'Bearer ' + token,
      'x-api-key': process.env.REACT_APP_XAPIKEY,
    }
  })
  .then((res) => {
    return res.data
  })
  .catch(err => {
      console.error('getCustomer:', err.message)
      throw new Error("Erro ao retornar cliente");
  })

  return data
}