import './plan.css';
import React, { useEffect, useState } from 'react';
import { parseCookies } from 'nookies'
import InputMask from 'react-input-mask';
import toast, { Toaster } from 'react-hot-toast';
import Loader  from '../../Components/Loader'
import Modal from 'react-bootstrap/Modal';
import { 
  updatePayment, 
  updateSginature, 
  cancelSginature,
  getPlan
} from '../../Controllers/payment'
import { organizationById } from '../../Controllers/organization'
import { redirectLogin } from '../../Functions';


function Plan() {
    const [organization, setOrganization] = useState([])
    const [plans, setPlans] = useState([])
    const [selectedPlan, setSelectedPlan] = useState()
    const [loading, setLoading] = useState(false)

    const [main, setMain] = useState(true)
    const [plan, setPlan] = useState(false)
    const [card, setCard] = useState(false)
    const [open, setOpen] = React.useState(false);
    const [openPlan, setOpenPlan] = React.useState(false);

    const [cardName, setCardName] = useState()
    const [cardNumber, setCardNumber] = useState()
    const [cardMonth, setCardMonth] = useState()
    const [cardYear, setCardYear] = useState()
    const [cardCVV, setCardCVV] = useState()
    
    const organizationId = window.localStorage.getItem('organization')

    const currentPlan = plans.find((plan) => plan.myId === organization?.plan?.id)
 
    async function getOrganizationById() {
      setLoading(true)
      await organizationById(organizationId, setOrganization)
      .then(() => {
        setLoading(false)
      })
      .catch((err) => {
        console.log('erro retornar organização', err)
      })
    }

    async function getPlans() {
      setLoading(true)
      await getPlan(setPlans)
      .then(() => {
        setLoading(false)
      })
      .catch((err) => {
        console.log('erro retornar organização', err)
      })
    }

    useEffect(() => {
      getOrganizationById()
      getPlans()
    }, [])

  
    const handleChange = (setState) => (event) => {
      setState(event.target.value)
    }

    const renderAlterPlan = () => {
      setMain(false)
      setPlan(true)
      setCard(false)
    }

    const renderAlterCard = () => {
      setMain(false)
      setPlan(false)
      setCard(true)
    }

    const finishAlterPlan = async() => {
      const subscriptionId = organization?.plan?.id
      const typeId = 'myId'
      const data = {
        planMyId: selectedPlan?.myId
      }

      await updateSginature(data, subscriptionId, typeId)
      .then(() => {
        setMain(true)
        setPlan(false)
        setCard(false)
        setOpenPlan(false)
        toast.success('Plano alterado com sucesso')
      })
      .catch((err) => {
        console.log('erro ao alterar plano: ', err)
        toast.error('Erro ao alterar plano. Entre em contato com o suporte')
      })

    }

    const finishAlterCard = async() => {
      const subscriptionId = organization?.plan?.id
      const typeId = 'myId'
      const data = {
        value: currentPlan?.PlanPrices[0]?.value,
        mainPaymentMethodId: 'creditcard',
        PaymentMethodCreditCard: {
          Card: {
            number: String(cardNumber),
            holder: String(cardName),
            expiresAt: String(cardYear) + '-' + String(cardMonth),
            cvv: String(cardCVV)
          }
        },
      }

      await updatePayment(data, subscriptionId, typeId)
      .then(() => {
        setMain(true)
        setPlan(false)
        setCard(false)
        toast.success('Cartão alterado com sucesso')
      })
      .catch((err) => {
        console.log('erro ao alterar forma de pagamento: ', err)
        toast.error('Erro ao alterar forma de pagamento. Entre em contato com o suporte')
      })

     
    }

    const finishCancelSignature = async() => {
      const data = {
        subscriptionId: organization?.subscription?.id,
        typeId: 'myId'
      }

      await cancelSginature(data)
      .then(() => {
        setMain(true)
        setPlan(false)
        setCard(false)
        setOpen(false)
        toast.success('Assinatura Cancelada com sucesso')
        setTimeout(() => {redirectLogin()}, 5000)
      })
      .catch((err) => {
        console.log('erro ao cancelar: ', err)
        toast.error('Erro ao cancelar assinatura. Entre em contato com o suporte')
      })
    }

    const backMain = () => {
      setMain(true)
      setPlan(false)
      setCard(false)
      setOpen(false)
    }

  return (
    <div className="container-plan">
      <Toaster position="bottom-center"  />
      <Modal size='xs' show={open} onHide={'handleClose'}>
        <Modal.Header>
          <Modal.Title>Cancelamento de Assinatura</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Tem certeza que deseja cancelar sua assinatura? <br /> você ficará sem o controle de suas finanças e agendamento</p>
        </Modal.Body>
        <Modal.Footer>
          <button 
              onClick={finishCancelSignature} 
              className='button-style-delete-confirm'
            >
              Confirmar
            </button>
            <button 
              onClick={() => setOpen(false)} 
              className='button-style-delete-cancel'
            >
              Cancelar
          </button>
        </Modal.Footer>
      </Modal>

      <Modal size='xs' show={openPlan} onHide={'handleClose'}>
        <Modal.Header>
          <Modal.Title>Alterar Plano</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Você está alterando seu plano atual: <b>{currentPlan?.name}</b></p>
          <p>para o plano: <b>{selectedPlan?.name}</b></p>
          <p>Valor que será cobrado na próxima fatura é de: <b>{(selectedPlan?.PlanPrices[0]?.value / 100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b></p>
        </Modal.Body>
        <Modal.Footer>
          <button 
              onClick={finishAlterPlan} 
              className='button-style-delete-confirm'
            >
              Confirmar
            </button>
            <button 
              onClick={() => setOpenPlan(false)} 
              className='button-style-delete-cancel'
            >
              Cancelar
          </button>
        </Modal.Footer>
      </Modal>
      <p className='title-plan'>Gerenciar Plano</p>
        {main === true && (
          <div>
            <div className='container-title-sections'>
              <p><b>Informações de Cobrança</b></p>
            </div>
            <hr />
            <div className='container-card-plan'>
              <div className='container-info-plan'>
                <span>Plano Atual: <b>{currentPlan?.name}</b></span>
                <span onClick={renderAlterPlan} className='button-action'>Alterar Plano</span>
              </div>

              <div className='container-info-plan'>
                <span>Forma de Pag. : Cartão com final <b>
                  {organization?.billings && organization.billings.length > 0
                    ? organization.billings[0]?.Card?.number?.slice(-4)
                    : ""} 
                </b></span>
                <span onClick={renderAlterCard} className='button-action'>Alterar</span>
              </div>

              <div className='container-info-plan'>
                <span>Preço: <b>
                  {currentPlan?.PlanPrices?.length > 0 ? 
                    (currentPlan?.PlanPrices[0]?.value / 100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : '0'
                  }
                  {/* {} */}
                </b></span>
                <span onClick={() => setOpen(true)} className='button-action-cancel'>Cancelar Assinatura</span>
              </div>
            </div>
          </div>
        )}

      {plan === true && (
        <div>
          <div className='container-title-sections'>
            <p><b>Alterar Plano</b></p>
            <p onClick={backMain} className='button-back-main'>Voltar</p>
          </div>
          
          <hr />
          <div className='container-card-plan'>
            {plans.map((plan) => (
              <>
                <div 
                  className={plan.name !== 'Business' ?'container-alter-plan':'container-alter-plan-embreve'}
                  onClick={() => setSelectedPlan(plan)}
                >
                  <div className='container-info-plan'>
                    <span> {plan.myId === organization?.plan?.id ? 'Plano Atual: ' : 'Plano: '} <b>{plan.name}</b></span>
                    {plan.myId === organization?.plan?.id && (
                      <span className=''>✅</span>
                    )}

                    {plan.myId !== organization?.plan?.id && plan.name !== 'Business' && (
                      <span onClick={() => setOpenPlan(true)} className='button-action'>Alterar</span>
                    )}

                    {plan.name === 'Business' && (
                      <span onClick={() => setOpenPlan(true)} className='button-action-embreve'>Em Breve</span>
                    )}
                  </div>
                    {plan.name === 'Financas' && (
                    <p className='description-plan'>Controle as finanças do seu negócio, tenha relatórios e insights</p>
                    )}

                    {plan.name === 'Controle Total' && (
                    <p className='description-plan'>Além do controle financeiro aqui você terá agendamento 
                    e uma área de marketing com dicas e estratégias para crescer seu negócio</p>
                    )}

                    {plan.name === 'Business' && (
                    <p className='description-plan'>Plano completão. Além do controle financeiro, agendamento e marketing, 
                    voce conta com a possibilidade de gerenciar funcionarios e outras unidades do seu negócio</p>
                  )}
                </div>
              </>
            ))}
          </div>

         
        </div>
      )}
      
      {card === true && (
        <div>
           <div className='container-title-sections'>
            <p><b>Alterar Cartão</b></p>
            <p onClick={backMain} className='button-back-main'>Voltar</p>
          </div>
          <hr />
          <div className='container-card-plan'>

            <div>
              <InputMask 
                // style={{ border: '1px solid white'}}
                className='inputs-alter-payment-data' 
                placeholder='Nome como no cartão *' 
                onChange={handleChange(setCardName)}
              />
            </div>
             <div>
                <InputMask
                  maskChar={null}
                  mask="9999 9999 9999 9999" 
                  // style={{ border: '1px solid white'}}
                  className='inputs-alter-payment-data ' 
                  placeholder='Número do cartão*' 
                  onChange={handleChange(setCardNumber)}    
                />
              </div>

              <div className='container-expiration-date'>
                <div>
                  <InputMask 
                      // style={{ border: '1px solid white'}}
                      className='inputs-alter-payment-data' 
                      type='number'
                      placeholder='Mês*' 
                      onChange={handleChange(setCardMonth)}  
                    />
                </div>

                <div>
                <InputMask 
                      // style={{ border: '1px solid white'}}
                      className='inputs-alter-payment-data' 
                      type='number'
                      placeholder='Ano*' 
                      onChange={handleChange(setCardYear)}  
                    />
                </div>

                <div>
                <InputMask 
                      // style={{ border: '1px solid white'}}
                      className='inputs-alter-payment-data' 
                      type='number'
                      placeholder='CVV*' 
                      onChange={handleChange(setCardCVV)}  
                    />
                </div>

                <div className='payment-button-container'>
                  <button onClick={finishAlterCard} className='alter-payment-button'>
                    Alterar Cartão
                  </button>
                </div>

              </div>
            {/* <span>Preço: R$ 39,90/mês</span> */}
          </div>
        </div>
      )}
    </div>
  );
}

export default Plan;