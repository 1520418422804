import axios from 'axios'

export async function generateContent(token, body) {
  
    const data = await axios.post(`${process.env.REACT_APP_API_LOCAL}/mkt`, body, {
      headers: {
        'x-api-key': process.env.REACT_APP_XAPIKEY,
        Authorization: 'Bearer ' + token,
        'content-Type': 'application/json'
      }
    })
    .catch(err => {
        console.error('generateContent:', err.message)
        throw new Error("Erro ao criar conteudo na tela de marketing");
    })

    return data
}
